var localUrl = "";
var E_BASE_URL = {
  PRD: {
    _URL: "https://aj.phfund.com.cn/",
    apiURL: "https://aj.phfund.com.cn/pcweb/",
    phURL: "https://aj.phfund.com.cn/",
    baseH5URL: "https://aj.phfund.com.cn/appweb/",
    baseGetURLNew: "https://aj.phfund.com.cn/aplusweb/",
    baseGatewayURL: "https://aj.phfund.com.cn/gateway/",
    baseGatewayURLNew: 'https://aj.phfund.com.cn/prdgateway/',
    appid: "IDA4QrKI",
    version: "1.0.0",
  },
  PRE: {
    _URL: "https://aj.phfund.com.cn/",
    apiURL: "https://aj.phfund.com.cn/preweb/",
    phURL: "https://aj.phfund.com.cn/ph_html/",
    baseH5URL: "https://aj.phfund.com.cn/preweb/",
    baseGetURLNew: "https://aj.phfund.com.cn/pre_aplusweb/",
    baseGatewayURL: "https://aj.phfund.com.cn/pregateway/",
    baseGatewayURLNew: 'https://aj.phfund.com.cn/pregateway/',
    appid: "IDA4QrKI",
    version: "1.0.0",
  },
  SIT: {
    _URL: "https://ajstg1.phfund.com.cn/",
    apiURL: "https://ajstg1.phfund.com.cn/corpwebnewsit/",
    phURL: "https://ajstg1.phfund.com.cn/sit_html/",
    baseH5URL: "https://ajstg1.phfund.com.cn/h5web/",
    baseGetURLNew: "https://ajstg1.phfund.com.cn/apluswebnewsit/",
    baseGatewayURL: "https://ajstg1.phfund.com.cn/gatewaynewsit/",
    baseGatewayURLNew: 'https://ajstg1.phfund.com.cn/gatewaysit/',
    appid: "TIDAL3Pg",
    version: "1.0.0",
  },
  CG: {
    _URL: "https://ajstg1.phfund.com.cn/",
    apiURL: "https://ajstg1.phfund.com.cn/corpwebcg/",
    phURL: "https://ajstg1.phfund.com.cn/cg_html/",
    baseH5URL: "https://ajstg1.phfund.com.cn/h5web/",
    baseGetURLNew: "https://ajstg1.phfund.com.cn/apluswebcg/",
    baseGatewayURL: "https://ajstg1.phfund.com.cn/gateway/",
    baseGatewayURLNew: "https://ajstg1.phfund.com.cn/gateway/",
    appid: "IDA83quV",
    version: "1.0.0",
  },
  SF: {
    _URL: "https://ajstg1.phfund.com.cn/",
    apiURL: "https://ajstg1.phfund.com.cn/corpwebsf/",
    phURL: "https://ajstg1.phfund.com.cn/sf_html/",
    baseH5URL: "https://ajstg1.phfund.com.cn/h5web/",
    baseGetURLNew: "https://ajstg1.phfund.com.cn/apluswebsf/",
    appid: "TIDAL3Pg",
    version: "1.0.0",
  },
  QS: {
    _URL: "https://ajstg1.phfund.com.cn/",
    apiURL: "https://ajstg1.phfund.com.cn/corpwebqs/",
    phURL: "https://ajstg1.phfund.com.cn/qs_html/",
    baseH5URL: "https://ajstg1.phfund.com.cn/h5web/",
    baseGetURLNew: "https://ajstg1.phfund.com.cn/apluswebqs/",
    baseGatewayURL: "https://ajstg1.phfund.com.cn/gatewayqs/",
    baseGatewayURLNew: "https://ajstg1.phfund.com.cn/gatewayqs/",
    appid: "TIDAL3Pg",
    version: "1.0.0",
  },
  CI: {
    _URL: "https://ajci.phfund.com.cn/",
    apiURL: "https://ajci.phfund.com.cn/corewebci/",
    phURL: "https://ajci.phfund.com.cn/ci_html/",
    baseH5URL: "https://ajci.phfund.com.cn/h5web/",
    baseGetURLNew: "https://ajci.phfund.com.cn/apluswebci/",
    baseGatewayURL: "https://ajci.phfund.com.cn/gatewayci/",
    baseGatewayURLNew: "https://ajci.phfund.com.cn/gatewayci/",
    appid: "TIDAL3Pg",
    version: "1.0.0",
  },
  LOCAL: {
    _URL: localUrl,
    apiURL: localUrl + "corpwebcg/",
    phURL: localUrl + "/ph_html/", //aj-ots/app/ph_html/dist
    baseH5URL: localUrl + "h5web/",
    baseGetURLNew: localUrl + "apluswebcg/",
    baseGatewayURL: localUrl + "gateway/",
    baseGatewayURLNew: localUrl + "gateway/",
    appid: "IDA83quV",
    version: "1.0.0",
  },
  NEWSIT: {
    _URL: "https://ajstg1.phfund.com.cn/",
    apiURL: "https://ajstg1.phfund.com.cn/corpwebnewsit/",
    phURL: "https://ajstg1.phfund.com.cn/sit_html/",
    baseH5URL: "https://ajstg1.phfund.com.cn/h5web/",
    baseGetURLNew: "https://ajstg1.phfund.com.cn/apluswebnewsit/",
    baseGatewayURL: "https://ajstg1.phfund.com.cn/gatewaynewsit/",
    baseGatewayURLNew: "https://ajstg1.phfund.com.cn/gatewaynewsit/",
    appid: "IDA83quV",
    version: "1.0.0",
  },
};

var _URL,
  apiURL,
  phURL,
  baseH5URL,
  baseGetURLNew,
  baseGatewayURL,
  baseGatewayURLNew,
  appid,
  version;
var versionControl = "20240816"; //版本号20191030，资源号001开始
/**
 * 初始化URL请求路径
 * @param type
 */
function initEnvironment(type) {
  var envConf = E_BASE_URL[type.toUpperCase()];
  _URL = envConf._URL;
  apiURL = envConf.apiURL;
  phURL = envConf.phURL;
  baseH5URL = envConf.baseH5URL;
  baseGetURLNew = envConf.baseGetURLNew;
  baseGatewayURL = envConf.baseGatewayURL;
  baseGatewayURLNew = envConf.baseGatewayURLNew;
  appid = envConf.appid;
  version = envConf.version;
}

initEnvironment("PRD");

////适配ie8
if (window.location.href.split("/")[2] == "ishare.phfund.com.cn") {
  window.location.href = "https://aj.phfund.com.cn/404.html";
}
window.console =
  window.console ||
  (function () {
    var c = {};
    c.log =
      c.warn =
      c.debug =
      c.info =
      c.error =
      c.time =
      c.dir =
      c.profile =
      c.clear =
      c.exception =
      c.trace =
      c.assert =
        function () {};
    return c;
  })();

/**
 * Array对象方法兼容处理哦
 */
if (!Array.prototype.filter) {
  Array.prototype.filter = function (fun /*, thisArg */) {
    "use strict";
    if (this === void 0 || this === null) throw new TypeError();
    var t = Object(this);
    var len = t.length >>> 0;
    if (typeof fun !== "function") throw new TypeError();
    var res = [];
    var thisArg = arguments.length >= 2 ? arguments[1] : void 0;
    for (var i = 0; i < len; i++) {
      if (i in t) {
        var val = t[i];
        if (fun.call(thisArg, val, i, t)) res.push(val);
      }
    }
    return res;
  };
}
if (!Array.prototype.find) {
  Object.defineProperty(Array.prototype, "find", {
    value: function (predicate) {
      // 1. Let O be ? ToObject(this value).
      if (this == null) {
        throw new TypeError('"this" is null or not defined');
      }

      var o = Object(this);

      // 2. Let len be ? ToLength(? Get(O, "length")).
      var len = o.length >>> 0;

      // 3. If IsCallable(predicate) is false, throw a TypeError exception.
      if (typeof predicate !== "function") {
        throw new TypeError("predicate must be a function");
      }

      // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
      var thisArg = arguments[1];

      // 5. Let k be 0.
      var k = 0;

      // 6. Repeat, while k < len
      while (k < len) {
        // a. Let Pk be ! ToString(k).
        // b. Let kValue be ? Get(O, Pk).
        // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
        // d. If testResult is true, return kValue.
        var kValue = o[k];
        if (predicate.call(thisArg, kValue, k, o)) {
          return kValue;
        }
        // e. Increase k by 1.
        k++;
      }

      // 7. Return undefined.
      return undefined;
    },
    configurable: true,
    writable: true,
  });
}
if (!Array.prototype.forEach) {
  Array.prototype.forEach = function forEach(callback, thisArg) {
    var T, k;

    if (this == null) {
      throw new TypeError("this is null or not defined");
    }
    var O = Object(this);
    var len = O.length >>> 0;
    if (typeof callback !== "function") {
      throw new TypeError(callback + " is not a function");
    }
    if (arguments.length > 1) {
      T = thisArg;
    }
    k = 0;

    while (k < len) {
      var kValue;
      if (k in O) {
        kValue = O[k];
        callback.call(T, kValue, k, O);
      }
      k++;
    }
  };
}
if (!Array.prototype.includes) {
  Array.prototype.includes = function (searchElement /*, fromIndex*/) {
    "use strict";
    if (this == null) {
      throw new TypeError(
        "Array.prototype.includes called on null or undefined"
      );
    }

    var O = Object(this);
    var len = parseInt(O.length, 10) || 0;
    if (len === 0) {
      return false;
    }
    var n = parseInt(arguments[1], 10) || 0;
    var k;
    if (n >= 0) {
      k = n;
    } else {
      k = len + n;
      if (k < 0) {
        k = 0;
      }
    }
    var currentElement;
    while (k < len) {
      currentElement = O[k];
      if (
        searchElement === currentElement ||
        (searchElement !== searchElement && currentElement !== currentElement)
      ) {
        // NaN !== NaN
        return true;
      }
      k++;
    }
    return false;
  };
}
if (!Array.prototype.map) {
  Array.prototype.map = function (callback, thisArg) {
    var T, A, k;

    if (this == null) {
      throw new TypeError(" this is null or not defined");
    }

    // 1. Let O be the result of calling ToObject passing the |this| value as the argument.
    var O = Object(this);

    // 2. Let lenValue be the result of calling the Get internal method of O with the argument "length".
    // 3. Let len be ToUint32(lenValue).
    var len = O.length >>> 0;

    // 4. If IsCallable(callback) is false, throw a TypeError exception.
    // See: http://es5.github.com/#x9.11
    if (typeof callback !== "function") {
      throw new TypeError(callback + " is not a function");
    }

    // 5. If thisArg was supplied, let T be thisArg; else let T be undefined.
    if (thisArg) {
      T = thisArg;
    }

    // 6. Let A be a new array created as if by the expression new Array(len) where Array is
    // the standard built-in constructor with that name and len is the value of len.
    A = new Array(len);

    // 7. Let k be 0
    k = 0;

    // 8. Repeat, while k < len
    while (k < len) {
      var kValue, mappedValue;

      // a. Let Pk be ToString(k).
      //   This is implicit for LHS operands of the in operator
      // b. Let kPresent be the result of calling the HasProperty internal method of O with argument Pk.
      //   This step can be combined with c
      // c. If kPresent is true, then
      if (k in O) {
        // i. Let kValue be the result of calling the Get internal method of O with argument Pk.
        kValue = O[k];

        // ii. Let mappedValue be the result of calling the Call internal method of callback
        // with T as the this value and argument list containing kValue, k, and O.
        mappedValue = callback.call(T, kValue, k, O);

        // iii. Call the DefineOwnProperty internal method of A with arguments
        // Pk, Property Descriptor {Value: mappedValue, : true, Enumerable: true, Configurable: true},
        // and false.

        // In browsers that support Object.defineProperty, use the following:
        // Object.defineProperty(A, Pk, { value: mappedValue, writable: true, enumerable: true, configurable: true });

        // For best browser support, use the following:
        A[k] = mappedValue;
      }
      // d. Increase k by 1.
      k++;
    }

    // 9. return A
    return A;
  };
}
if (!Array.prototype.indexOf) {
  Array.prototype.indexOf = function (elt /*, from*/) {
    var len = this.length >>> 0;

    var from = Number(arguments[1]) || 0;
    from = from < 0 ? Math.ceil(from) : Math.floor(from);
    if (from < 0) from += len;

    for (; from < len; from++) {
      if (from in this && this[from] === elt) return from;
    }
    return -1;
  };
}
if (!Array.isArray) {
  Array.isArray = function (arg) {
    return Object.prototype.toString.call(arg) === "[object Array]";
  };
}

$(function () {
  if (!isRealName()) {
    $("#showModiTranspwd").addClass("none");
    $("#showFindTranspwd").addClass("none");
  }
  //link script add timestamp
  $("link,script").each(function () {
    if ($(this).attr("src")) {
      $(this).attr("src", $(this).attr("src") + "?v=" + versionControl);
    } else if ($(this).attr("href")) {
      $(this).attr("href", $(this).attr("href") + "?v=" + versionControl);
    }
  });
});

/**
 * 获取token
 * @returns {null|*}
 */
function getToken() {
  return localStorage.g_token;
}

/**
 * 获取用户名称
 * @returns {null|*}
 */
function getGUsername() {
  return localStorage.g_cuatna;
}

//随机生成uuid
function getUUID() {
  return S4() + S4() + S4() + S4() + S4() + S4() + S4() + S4();
}

function S4() {
  return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
}

/**
 * 获取用户手机号码
 * @returns {null|*}
 */
function getGMobile() {
  return localStorage.g_mobile;
}

/**
 * 获取用户头像路径
 * @returns {null}
 */
function getPhotoPath() {
  return localStorage.g_photoPath;
}

/**
 * 判断用户是否实名
 * @returns {boolean}
 */
function isRealName() {
  return "1" === localStorage.reigsterNew_transAcctStatus;
}

/**
 * 判断是否绑卡了，未开户
 * @returns reigsterNew_transAcctStatus == '0'
 */
function bindBanckCardAndNotOpenAccount() {
  return "0" === localStorage.reigsterNew_transAcctStatus;
}

/**
 * 获取用户风险等级描述
 * @returns {*}
 */
function getRiskLvDesc() {
  return localStorage.riskLevelDesc;
}

/**
 * 获取用户风险等级
 * @returns {Number}
 */
function getRiskLv() {
  return parseInt(localStorage.risklv) || -1;
}

/**
 * 获取个人钱包基金代码
 * @returns {*}
 */
function getPersonalFundCode() {
  return localStorage.personalFundCode;
}

/**
 * 风险等级状态 0-未测评  1-正常  2-已过期
 * @returns {*}
 */
function getRiskEvaluationState() {
  var expireDate = localStorage.expireDate;
  if (isNull(expireDate) || expireDate === "0") {
    return "0";
  } else {
    var nowTime = timeStampFun();
    if (Number(nowTime) < Number(expireDate)) {
      return "1";
    } else {
      return "2";
    }
  }
}

function getValue(id) {
  return $("#" + id).val();
}

/**
 * 判断用户是否登录
 */
function islogin() {
  var token = getToken();

  if (
    token == undefined ||
    token == null ||
    token == "" ||
    token == "null" ||
    token == "undefined"
  ) {
    //alert('请先登录');
    window.location.href = phURL + "login.html";
  }
}

//有的页面需要token在这里就把token变成tradetoken，：在这些页面上要进行token判断，否则位null
var tradeToken = (function () {
  var pageArr = [
    "recharge",
    "enchashment",
    "fundBuy",
    "redemption",
    "fundTrading",
    "addfundinvestment",
    "addanxin",
    "specialAppointmentMsg",
  ];
  var pageId = pageName().toString();
  for (var i = 0, len = pageArr.length; i < len; i++) {
    if (pageId === pageArr[i]) {
      return getToken();
    }
  }
  return null;
})();

var isLoadingCount = 0;

function toApi_toLogin(url, succFun, pageid, fundCode) {
  var errFun = function (error) {
    showErrorMsg(error.respMsg, error.respType);
  };
  if (arguments.length == 5) {
    errFun = arguments[4];
  } else if (arguments.length == 3) {
    errFun = arguments[2];
  }

  var _url = "";
  var data = null;
  if (typeof url == "string") {
    _url = url;
    data = {
      token: getToken(),
      deviceId: "shebeihaoshipc",
      sign: "971fa2ca1db70fa637aefcf330543157",
    };
  } else {
    _url = url.url;
    delete url["url"];
    data = url;
    data.token = getToken();
    data.deviceId = "shebeihaoshipc";
    data.sign = "971fa2ca1db70fa637aefcf330543157";
  }

  isLoadingCount++;
  if (isLoadingCount == 1) {
    $.fn.jqLoading({ height: 100, width: 240, text: "加载中，请稍候...." });
  }
  var sessionId = localStorage.sessionId;
  if (isNull(sessionId)) {
    sessionId = timeStampFun() + "abc";
    localStorage.sessionId = sessionId;
  }
  $.ajax({
    url: apiURL + _url,
    data: data,
    type: "post",
    timeout: 30000, //超时时间设置，单位毫秒
    headers: { branchCode: "01", "h-version": "1.0.0", sessionId: sessionId }, //这里设置header
    success: function (data) {
      log(data);

      // isLoadingCount--;
      // if(isLoadingCount<=0){
      //     isLoadingCount=0;
      //     $.fn.jqLoading("destroy");
      // }

      try {
        if (data.length > 5) {
          var json = eval("(" + data + ")");
          if (json.respCode == "000000") {
            succFun(json);
          } else if (
            json.respCode == "100000" ||
            json.respCode == "100006" ||
            json.respCode == "100021" ||
            json.respCode == "0820010091"
          ) {
            window.location.href = phURL + "login.html";
          } else {
            if (isNull(fundCode)) {
              fundCode = "";
            }
            behavioralStatistics(
              pageName(),
              json.transCode,
              json.respCode,
              json.respMsg,
              fundCode,
              json.bizSeqno
            );
            errFun(json);
          }
        }
      } catch (err) {
      } finally {
        isLoadingCount--;
        if (isLoadingCount <= 0) {
          isLoadingCount = 0;
          $.fn.jqLoading("destroy");
        }
      }
    },
    error: function (error) {
      isLoadingCount--;
      if (isLoadingCount <= 0) {
        isLoadingCount = 0;
        $.fn.jqLoading("destroy");
      }
      if (error.statusText == "timeout") {
        showErrorMsg("系统繁忙，请稍后再试!!!!");
      }
    },
  });
}

function toApi(url, succFun, pageid, fundCode) {
  if (tradeToken !== null) {
    if (tradeToken !== getToken()) {
      window.location.href = phURL + "login.html";
      return;
    }
  }

  var errFun = function (error) {
    showErrorMsg(error.respMsg, error.respType);
  };
  if (arguments.length == 5) {
    errFun = arguments[4];
  } else if (arguments.length == 3) {
    errFun = arguments[2];
  }

  var _url = "";
  var data = null;
  if (typeof url == "string") {
    _url = url;
    data = {
      token: getToken(),
      deviceId: "shebeihaoshipc",
      sign: "971fa2ca1db70fa637aefcf330543157",
    };
  } else {
    _url = url.url;
    delete url["url"];
    data = url;
    data.token = getToken();
    data.deviceId = "shebeihaoshipc";
    data.sign = "971fa2ca1db70fa637aefcf330543157";
  }

  isLoadingCount++;
  if (isLoadingCount == 1) {
    $.fn.jqLoading({ height: 100, width: 240, text: "加载中，请稍候...." });
  }

  var sessionId = localStorage.sessionId;
  if (isNull(sessionId)) {
    sessionId = timeStampFun() + "abc";
    localStorage.sessionId = sessionId;
  }

  $.ajax({
    url: apiURL + _url,
    data: data,
    type: "post",
    timeout: 30000, //超时时间设置，单位毫秒
    headers: { branchCode: "01", "h-version": "1.0.0", sessionId: sessionId }, //这里设置header

    success: function (data) {
      log(data);

      // isLoadingCount--;
      // if(isLoadingCount<=0){
      //     isLoadingCount=0;
      //     $.fn.jqLoading("destroy");
      // }

      try {
        if (data.length > 5) {
          var json = JSON.parse(data);
          if (json.respCode == "000000") {
            succFun(json);
          } else if (
            json.respCode == "100000" ||
            json.respCode == "100006" ||
            json.respCode == "100021" ||
            json.respCode == "0820010091"
          ) {
            window.location.href = phURL + "login.html";
          } else if (json.respCode == "0848000169") {
            confirm(
              "根据监管要求,请先补充个税声明信息",
              "暂不",
              "立即补充",
              "",
              function () {
                window.location.href = window.phURL + "personal/personal.html";
              }
            );
          } else {
            if (isNull(fundCode)) {
              fundCode = "";
            }
            behavioralStatistics(
              pageName(),
              json.transCode,
              json.respCode,
              json.respMsg,
              fundCode,
              json.bizSeqno
            );
            errFun(json);
          }
        }
      } catch (err) {
      } finally {
        isLoadingCount--;
        if (isLoadingCount <= 0) {
          isLoadingCount = 0;
          $.fn.jqLoading("destroy");
        }
      }
    },
    error: function (error) {
      isLoadingCount--;
      if (isLoadingCount <= 0) {
        isLoadingCount = 0;
        $.fn.jqLoading("destroy");
      }
      if (error.statusText == "timeout") {
        showErrorMsg("系统繁忙，请稍后再试!!!!");
      }
    },
  });
}

function toApi_post(url, params, succFun, pageid, fundCode) {
  if (tradeToken !== null) {
    if (tradeToken !== getToken()) {
      window.location.href = phURL + "login.html";
      return;
    }
  }

  var errFun = function (error) {
    showErrorMsg(error.respMsg, error.respType);
  };
  if (arguments.length == 6) {
    errFun = arguments[5];
  } else if (arguments.length == 4) {
    errFun = arguments[3];
  }

  isLoadingCount++;
  if (isLoadingCount == 1) {
    $.fn.jqLoading({ height: 100, width: 240, text: "加载中，请稍候...." });
  }

  var sessionId = localStorage.sessionId;
  if (isNull(sessionId)) {
    sessionId = timeStampFun() + "abc";
    localStorage.sessionId = sessionId;
  }

  var _url = typeof url == "string" ? url : url.url;
  params.token = getToken();
  params.deviceId = "shebeihaoshipc";
  params.channelCode = "002001";
  params.branchCode = "01";
  $.ajax({
    url: apiURL + _url,
    data: params,
    type: "post",
    timeout: 30000, //超时时间设置，单位毫秒
    headers: { branchCode: "01", "h-version": "1.0.0", sessionId: sessionId }, //这里设置header
    success: function (data) {
      log(data);
      try {
        if (data.length > 5) {
          var json = JSON.parse(data);
          if (json.respCode == "000000") {
            succFun(json);
          } else if (
            json.respCode == "100000" ||
            json.respCode == "100006" ||
            json.respCode == "100021" ||
            json.respCode == "0820010091"
          ) {
            window.location.href = phURL + "login.html";
          } else if (json.respCode == "0848000169") {
            confirm(
              "根据监管要求,请先补充个税声明信息",
              "暂不",
              "立即补充",
              "",
              function () {
                window.location.href = window.phURL + "personal/personal.html";
              }
            );
          } else {
            if (isNull(fundCode)) {
              fundCode = "";
            }
            behavioralStatistics(
              pageName(),
              json.transCode,
              json.respCode,
              json.respMsg,
              fundCode,
              json.bizSeqno
            );
            errFun(json);
          }
        }
      } catch (err) {
      } finally {
        isLoadingCount--;
        if (isLoadingCount <= 0) {
          isLoadingCount = 0;
          $.fn.jqLoading("destroy");
        }
      }
    },
    error: function (error) {
      isLoadingCount--;
      if (isLoadingCount <= 0) {
        isLoadingCount = 0;
        $.fn.jqLoading("destroy");
      }
      if (error.statusText == "timeout") {
        showErrorMsg("系统繁忙，请稍后再试!!!!");
      }
    },
  });
}

function toApi_toAc(url, params, succFun, pageid, fundCode, errFunction) {
  if (tradeToken !== null) {
    if (tradeToken !== getToken()) {
      window.location.href = phURL + "login.html";
      return;
    }
  }

  var errFun = function (error) {
    showErrorMsg(error.respMsg, error.respType);
  };
  if (arguments.length == 6) {
    errFun = arguments[5];
  } else if (arguments.length == 4) {
    errFun = arguments[3];
  }

  isLoadingCount++;
  if (isLoadingCount == 1) {
    $.fn.jqLoading({ height: 100, width: 240, text: "加载中，请稍候...." });
  }

  var sessionId = localStorage.sessionId;
  if (isNull(sessionId)) {
    sessionId = timeStampFun() + "abc";
    localStorage.sessionId = sessionId;
  }

  var _url = typeof url == "string" ? url : url.url;
  params.token = getToken();
  params.deviceId = "shebeihaoshipc";
  params.channelCode = "002001";
  params.branchCode = "01";

  var reqData = {
    channelCode: "002001",
    branchCode: "01",
    reqData: params,
  };

  $.ajax({
    url: baseGetURLNew + _url,
    data: "reqData=" + encodeURIComponent(JSON.stringify(reqData)),
    type: "post",
    timeout: 30000, //超时时间设置，单位毫秒
    headers: { branchCode: "01", "h-version": "1.0.0", sessionId: sessionId }, //这里设置header

    success: function (data) {
      try {
        var json = data;
        if (json.respCode == "0000") {
          succFun(json.respData);
        } else if (
          json.respCode == "100000" ||
          json.respCode == "100006" ||
          json.respCode == "100021" ||
          json.respCode == "0820010091"
        ) {
          window.location.href = phURL + "login.html";
        } else {
          if (isNull(fundCode)) {
            fundCode = "";
          }
          behavioralStatistics(
            pageName(),
            json.transCode,
            json.respCode,
            json.respMsg,
            fundCode,
            json.bizSeqno
          );
          errFun(json);
        }
      } catch (err) {
      } finally {
        isLoadingCount--;
        if (isLoadingCount <= 0) {
          isLoadingCount = 0;
          $.fn.jqLoading("destroy");
        }
      }
    },
    error: function (error) {
      isLoadingCount--;
      if (isLoadingCount <= 0) {
        isLoadingCount = 0;
        $.fn.jqLoading("destroy");
      }
      if (error.statusText == "timeout") {
        showErrorMsg("系统繁忙，请稍后再试!!!!");
      }
      if (!isNull(errFun)) {
        errFunction(error);
      }
    },
  });
}
function toApi_toAcCg(
  url,
  params,
  succFun,
  pageid,
  fundCode,
  errFunction,
  isPrefer,
  newFlag
) {
  if (tradeToken !== null) {
    if (tradeToken !== getToken()) {
      window.location.href = phURL + "login.html";
      return;
    }
  }

  var errFun = function (error) {
    showErrorMsg(error.respMsg, error.respType);
  };
  if (arguments.length == 6) {
    errFun = arguments[5];
  } else if (arguments.length == 4) {
    errFun = arguments[3];
  }

  isLoadingCount++;
  if (isLoadingCount == 1) {
    $.fn.jqLoading({ height: 100, width: 240, text: "加载中，请稍候...." });
  }

  var sessionId = localStorage.sessionId;
  if (isNull(sessionId)) {
    sessionId = timeStampFun() + "abc";
    localStorage.sessionId = sessionId;
  }

  var _url = typeof url == "string" ? url : url.url;
  // params.token = '36ec7b9f77054206a1e2c464148ccc5c';
  params.deviceId = "shebeihaoshipc";
  params.channelCode = Object.keys(params).includes("channelCode")
    ? params.channelCode
    : "002001";
  params.branchCode = "01";

  var reqData = {
    channelCode: "002001",
    branchCode: "01",
    reqData: params,
  };

  var baseUrl = baseGatewayURL;
  if (newFlag) {
    baseUrl = baseGatewayURLNew;
  }

  $.ajax({
    url: baseUrl + _url,
    data: "reqData=" + encodeURIComponent(JSON.stringify(reqData)),
    type: "post",
    timeout: 30000, //超时时间设置，单位毫秒
    headers: {
      branchCode: "01",
      "h-version": "1.0.0",
      deviceID: "shebeihaoshipc",
      sessionId: sessionId,
    }, //这里设置header

    success: function (data, textStatus, xhr) {
      var responseHeaders = xhr.getResponseHeader("enc-tag");
      try {
        var json = data;
        if (!isPrefer) {
          if (json.respCode == "0000") {
            if (
              json.respData &&
              ["100000", "200000"].includes(json.respData.respCode)
            ) {
              alertMsg(json.respData.respMsg);
              setTimeout(function () {
                window.location.href = phURL + "login.html";
              }, 2000);
              return;
            }
            if (responseHeaders == "1") {
              json.respData = RSADecrypt(json.respData);
            }
            succFun(json.respData);
          } else if (
            json.respCode == "100000" ||
            json.respCode == "100006" ||
            json.respCode == "100021" ||
            json.respCode == "0820010091"
          ) {
            window.location.href = phURL + "login.html";
          } else {
            if (isNull(fundCode)) {
              fundCode = "";
            }
            behavioralStatistics(
              pageName(),
              json.transCode,
              json.respCode,
              json.respMsg,
              fundCode,
              json.bizSeqno
            );
            errFun(json);
          }
        } else {
          succFun(json, responseHeaders);
        }
      } catch (err) {
      } finally {
        isLoadingCount--;
        if (isLoadingCount <= 0) {
          isLoadingCount = 0;
          $.fn.jqLoading("destroy");
        }
      }
    },
    error: function (error) {
      isLoadingCount--;
      if (isLoadingCount <= 0) {
        isLoadingCount = 0;
        $.fn.jqLoading("destroy");
      }
      if (error.statusText == "timeout") {
        showErrorMsg("系统繁忙，请稍后再试!!!!");
      }
      if (!isNull(errFun)) {
        errFunction(error);
      }
    },
  });
}
function toApiBrCode2(url, succFun, pageid, fundCode) {
  var errFun = function (error) {
    showErrorMsg(error.respMsg, error.respType);
  };
  if (arguments.length == 5) {
    errFun = arguments[4];
  } else if (arguments.length == 3) {
    errFun = arguments[2];
  }

  var _url = "";
  var data = null;
  if (typeof url == "string") {
    _url = url;
    data = {
      token: getToken(),
      deviceId: "shebeihaoshipc",
      sign: "971fa2ca1db70fa637aefcf330543157",
    };
  } else {
    _url = url.url;
    delete url["url"];
    data = url;
    data.token = getToken();
    data.deviceId = "shebeihaoshipc";
    data.sign = "971fa2ca1db70fa637aefcf330543157";
  }

  isLoadingCount++;
  if (isLoadingCount == 1) {
    $.fn.jqLoading({ height: 100, width: 240, text: "加载中，请稍候...." });
  }

  var sessionId = localStorage.sessionId;
  if (isNull(sessionId)) {
    sessionId = timeStampFun() + "abc";
    localStorage.sessionId = sessionId;
  }

  $.ajax({
    url: apiURL + _url,
    data: data,
    type: "post",
    async: false,
    headers: { branchCode: "01", "h-version": "1.0.0", sessionId: sessionId }, //这里设置header

    success: function (data) {
      log(data);

      // isLoadingCount--;
      // if(isLoadingCount<=0){
      //     isLoadingCount=0;
      //     $.fn.jqLoading("destroy");
      // }

      try {
        if (data.length > 5) {
          var json = eval("(" + data + ")");
          if (json.respCode == "000000") {
            succFun(json);
          } else if (
            json.respCode == "100000" ||
            json.respCode == "100006" ||
            json.respCode == "100021" ||
            json.respCode == "0820010091"
          ) {
            window.location.href = phURL + "login.html";
          } else {
            if (isNull(fundCode)) {
              fundCode = "";
            }
            behavioralStatistics(
              pageName(),
              json.transCode,
              json.respCode,
              json.respMsg,
              fundCode,
              json.bizSeqno
            );
            errFun(json);
          }
        }
      } catch (err) {
      } finally {
        isLoadingCount--;
        if (isLoadingCount <= 0) {
          isLoadingCount = 0;
          $.fn.jqLoading("destroy");
        }
      }
    },
    error: function (error) {
      isLoadingCount--;
      if (isLoadingCount <= 0) {
        isLoadingCount = 0;
        $.fn.jqLoading("destroy");
      }
      if (error.statusText == "timeout") {
        showErrorMsg("系统繁忙，请稍后再试!!!!");
      }
    },
  });
}

/**
 * 用户登出
 */
function logout() {
  localStorage.g_token = null;
  localStorage.g_mobile = null;
  localStorage.g_cuatna = null;
  localStorage.g_photoPath = null;
  localStorage.g_imagePhotoPath = null;
  toApi("logout.tml", function (data) {
    window.location = phURL + "login.html";
  });
}

Array.prototype.Exists = function (curURL) {
  var b = false;
  for (var i = 0; i < this.length; i++) {
    if (curURL.indexOf(this[i]) > 0) {
      b = true;
      break;
    }
  }
  return b;
};

var exceptionURL = [
  "login.html",
  "register.html",
  "feedback.html",
  "forget-loginpwd.html",
  "registerSucc.html",
  "oldSysVerify.html",
  "oldSysLogin.html",
  "oldSysCardVerify.html",
  "oldTelVerify.html",
  "oldUpdateSuc.html",
  "oldSetLoginPwd.html",
  "oldSetTransPwd.html",
];
var activeURL = ["login.html", "older-active.html"];
$(document).ready(function () {
  var mobile = getGMobile();
  var thisURL = window.location.href;
  if (
    mobile == undefined ||
    mobile == null ||
    mobile == "" ||
    mobile == "null" ||
    mobile == "undefined"
  ) {
    if (exceptionURL.Exists(thisURL)) {
      //非登录URL,不处理
    } else {
      //登录URL，必须去登录
      if (localStorage.MustActiveOlder == "Y") {
        if (activeURL.Exists(thisURL)) {
          //激活URL,不处理
        } else window.location.href = phURL + "older-active.html";
      } else {
        localStorage.redirectURL = thisURL;
        window.location.href = phURL + "login.html";
      }
    }
  } else {
    if (mobile.length > 4) {
      mobile =
        mobile.substring(0, 3) +
        "****" +
        mobile.substring(mobile.length - 4, mobile.length);
    }

    var thisURL = window.location.href;
    if (localStorage.MustActiveOlder == "Y") {
      if (activeURL.Exists(thisURL)) {
        //激活URL,不处理
      } else window.location.href = phURL + "older-active.html";
    } else {
      // if(thisURL.indexOf("login.html")>0){
      //
      // }else
      // 	window.location.href = phURL + "login.html";
    }
  }
  var loginHeader =
    "" +
    '<div class="login-header">' +
    '    <div class="top-bar">' +
    '        <div class="login-wrap">' +
    '            <div class="link-box">' +
    '                <a href="http://www.phfund.com.cn/" class="btn-text" onclick="" target="_blank">鹏华基金官网</a>' +
    "            </div>" +
    '            <div class="two-code-box"><i></i>手机客户端' +
    '                <div class="two-code-img">' +
    '                    <img src="images/download-A+.png" alt=""/>' +
    "                    <p>扫一扫 下载 A加钱包</p>" +
    "                </div>" +
    "            </div>" +
    "        </div>" +
    "    </div>" +
    '    <div class="login-wrap">' +
    '        <div class="font-ri">心为基石, 价值之源' +
    "        </div>" +
    '        <h1 class="login-logo"><img src="images/logo-reg.jpg" alt="鹏华基金"/>' +
    "        </h1>" +
    "    </div>" +
    "</div>";
  var ologinHeader =
    ' <div class="top-bar">' +
    ' <div class="ologin-wrap">' +
    ' <div class="link-box"> <a href="http://www.phfund.com.cn/" class="btn-text">鹏华基金官网</a>' +
    // ' <a href="https://ehome.phfund.com.cn/etrading" class="f1 btn-text" target="_blank">鹏华基金老网上交易系统</a> ' +
    "</div>" +
    ' <div class="two-code-box"><i></i>手机客户端' +
    ' <div class="two-code-img">' +
    ' <img src="images/download-A+.png" alt=""/>' +
    " <p>扫一扫 下载 A+钱包</p>" +
    " </div>" +
    " </div>" +
    " </div>" +
    " </div>" +
    ' <div class="ologin-wrap">' +
    ' <div class="font-ri">心为基石, 价值之源 </div>' +
    ' <h1 class="login-logo"><img src="' +
    phURL +
    'images/oldlogo.png" alt="鹏华基金"/> </h1>' +
    " </div>";

  var header =
    '<div class="header">' +
    '<div class="wrap">' +
    '<div class="header-top-bar">' +
    '<a href="' +
    phURL +
    'personal/personal.html" class="user"><i></i>' +
    mobile +
    "</a>" +
    '<a href="javascript:void(0)" onclick="logout()">退出</a>' +
    "</div>" +
    '<h1 class="logo" title="鹏华基金"><img src="' +
    phURL +
    'images/logo.jpg" alt="鹏华基金"/></h1>' +
    '<ul class="nav">' +
    '<li class="active"><a href="' +
    phURL +
    'index.html">首页</a></li>' +
    '<li><a href="' +
    phURL +
    'wallet/wallet.html">钱包</a></li>' +
    '<li><a href="' +
    phURL +
    'fund/manageFinances.html">理财</a></li>' +
    '<li><a href="' +
    phURL +
    'personal/personal.html">个人中心</a></li>' +
    "</ul>" +
    "</div>" +
    "</div>";

  var headerWallet =
    '<div class="header">' +
    '<div class="wrap">' +
    '<div class="header-top-bar">' +
    '<a href="' +
    phURL +
    'personal/personal.html" class="user"><i></i>' +
    mobile +
    "</a>" +
    '<a href="javascript:void(0)" onclick="logout()" >退出</a>' +
    "</div>" +
    '<h1 class="logo" title="鹏华基金"><img src="' +
    phURL +
    'images/logo.jpg" alt="鹏华基金"/></h1>' +
    '<ul class="nav">' +
    '<li><a href="' +
    phURL +
    'index.html">首页</a></li>' +
    '<li class="active"><a href="' +
    phURL +
    'wallet/wallet.html">钱包</a></li>' +
    '<li><a href="' +
    phURL +
    'fund/manageFinances.html">理财</a></li>' +
    '<li><a href="' +
    phURL +
    'personal/personal.html">个人中心</a></li>' +
    "</ul>" +
    "</div>" +
    "</div>";

  var headerPersonal =
    '<div class="header">' +
    '<div class="wrap">' +
    '<div class="header-top-bar">' +
    '<a href="' +
    phURL +
    'personal/personal.html" class="user"><i></i>' +
    mobile +
    "</a>" +
    '<a href="javascript:void(0)" onclick="logout()" >退出</a>' +
    "</div>" +
    '<h1 class="logo" title="鹏华基金"><img src="' +
    phURL +
    'images/logo.jpg" alt="鹏华基金"/></h1>' +
    '<ul class="nav">' +
    '<li><a href="' +
    phURL +
    'index.html">首页</a></li>' +
    '<li><a href="' +
    phURL +
    'wallet/wallet.html">钱包</a></li>' +
    '<li><a href="' +
    phURL +
    'fund/manageFinances.html">理财</a></li>' +
    '<li class="active"><a href="' +
    phURL +
    'personal/personal.html">个人中心</a></li>' +
    "</ul>" +
    "</div>" +
    "</div>";

  var headerFund =
    '<div class="header">' +
    '<div class="wrap">' +
    '<div class="header-top-bar">' +
    '<a href="' +
    phURL +
    'personal/personal.html" class="user"><i></i>' +
    mobile +
    "</a>" +
    '<a href="javascript:void(0)" onclick="logout()" >退出</a>' +
    "</div>" +
    '<h1 class="logo" title="鹏华基金"><img src="' +
    phURL +
    'images/logo.jpg" alt="鹏华基金"/></h1>' +
    '<ul class="nav">' +
    '<li><a href="' +
    phURL +
    'index.html">首页</a></li>' +
    '<li><a href="' +
    phURL +
    'wallet/wallet.html">钱包</a></li>' +
    '<li class="active"><a href="' +
    phURL +
    'fund/manageFinances.html">理财</a></li>' +
    '<li><a href="' +
    phURL +
    'personal/personal.html">个人中心</a></li>' +
    "</ul>" +
    "</div>" +
    "</div>";

  var footer =
    '<div class="bot-p"></div>' +
    '<div class="footer">' +
    '<div class="wrap">' +
    '<div class="service-box">' +
    '<a href="http://www.sobot.com/chat/pc/index.html?sysNum=4327cec577384d45873ac1bb3d1464fa" id="onlineCustomerService" target="_Blank">在线客服<i class="ol-service"></i></a>' +
    '<span class="hot">客服热线<em class="tel">400-6788-533</em></span>' +
    "</div>" +
    '<div class="link-box">' +
    '<a href="https://www.phfund.com.cn/main_new/ehomeqianyi/aqzt.html"  target="_Blank">安全专题</a>' +
    '<a href="https://www.phfund.com.cn/main_new/ehomeqianyi/fxjss.html"  target="_Blank">风险提示函</a>' +
    '<a href="https://www.phfund.com.cn/main_new/ehomeqianyi/qyxz.html"  target="_Blank">投资人权益须知</a>' +
    '<a href="https://www.phfund.com.cn/main_new/ehomeqianyi/jygz.html"  target="_Blank">交易规则</a>' +
    '<a href="http://www.phfund.com/web/aml/"  target="_Blank">反洗钱专栏</a>' +
    '<a href="http://www.phfund.com.cn/web/siteMap/"  target="_Blank">网站地图</a>' +
    '<a href="http://www.phfund.com.cn/generalinfo/siteLink"  target="_Blank">友情链接</a>' +
    "</div>" +
    "<p>鹏华基金管理有限公司版权所有 Copyright &copy; Penghua Fund All rights reserved 粤B2-20050630</p>" +
    "<p><span>治理商业贿赂：0755-82846227</span><span>非法证券活动举报：0755-82353668</span></p>" +
    "</div>" +
    "</div>";

  var sideNav =
    '<ul class="left-nav-list">' +
    '<li class="item open"><span class="item-tit">我的账户</span><i></i>' +
    '<ul class="list-box">' +
    '<li class="active"><a href="#">账户概况</a></li>' +
    '<li><a href="' +
    phURL +
    'account/position.html">我的持仓</a></li>' +
    '<li><a href="javascript:void(0);" onclick="isHaveFundinvestment();">我的定投</a></li>' +
    '<li><a href="javascript:void(0);" onclick="isHaveAnxin();">安薪计划</a></li>' +
    '<li><a href="' +
    phURL +
    'account/red.html">我的红包</a></li>' +
    "</ul>" +
    "</li>" +
    "</ul>" +
    '<div class="item-a">' +
    '<a href="' +
    phURL +
    'account/transaction.html">交易记录</a>' +
    "</div>" +
    '<div class="item-a">' +
    '<a href="' +
    phURL +
    'account/msg.html">我的消息</a>' +
    "</div>" +
    '<div class="item-a two-code-box">' +
    '<a href="#">手机客户端<i class="two-code"></i></a>' +
    '<div class="two-code-img">' +
    '<img src="' +
    phURL +
    'images/download-A+01.png" alt=""/>' +
    "<p>扫一扫 下载 A加钱包</p>" +
    "</div>" +
    "</div>";
  $("#loginHeader").html(loginHeader);
  $("#ologinHeader").html(ologinHeader);
  $("#header").html(header);
  $("#footer").html(footer);
  $("#side-nav").html(sideNav);
  $("#header-wallet").html(headerWallet);
  $("#header-personal").html(headerPersonal);
  $("#header-fund").html(headerFund);

  $(".left-nav").on("click", ".item-tit", function () {
    if ($(this).closest(".item").hasClass("open")) {
      $(this).closest(".item").removeClass("open");
      $(this).siblings(".list-box").slideUp();
    } else {
      $(this).closest(".item").addClass("open");
      var _this_list_box = this;
      $(this)
        .siblings(".list-box")
        .slideDown(function () {
          $(_this_list_box).siblings(".list-box").css("overflow", "visible");
        });
    }
  });

  $(".strapped-card-pup").on("click", ".pup-btn a", function () {
    $(this).closest(".strapped-card-pup").hide();
  });

  $(".notice-box").on("click", "i", function () {
    $(this).closest(".notice-box").remove();
  });

  $(".pup-card-bind").on("click", ".btn-box a", function () {
    $(this).closest(".pup-card-bind").hide();
  });

  $(".list-obj").toggle(
    function () {
      $(this).find(".down-list").slideDown("fast");
    },
    function () {
      $(this).find(".down-list").slideUp("fast");
    }
  );

  $(".feedback-pup").on("click", ".btn-box a", function () {
    $(this).closest(".feedback-pup").remove();
  });

  $(".pup-eject").on("click", function () {
    $(".pup-model").show();
  });

  $(".pup-model").on("click", ".btn-box a", function () {
    $(this).closest(".pup-model").hide();
  });

  /* var barRight = '<div class="bar-right"></div>'

     var barRightH = $(window).height() - 320;

     $('.main').append(barRight);

     $('.bar-right').height(barRightH);*/
});

$(function () {
  $("input").keyup(function () {
    $("p[id^='e_']").addClass("none");
  });
  $("#agree").change(function () {
    if ($("#agree").prop("checked")) {
      $("#e_agree").hide();
    } else {
      $("#e_agree").show();
    }
  });
  // isFitToPlaceholder();

  //IE10及其他以下版本完美支持 placeholder 特性
  isFitToPlaceholderOnEE();

  //IE9的一个BUG：propertychange和input事件在拖拽 / 剪切 / 删除时无法触发
  var myBrowser = BrowserType();
  console.info("myBrowser:" + myBrowser);
  if (myBrowser == "IE9") {
    $("input").each(function () {
      var self = $(this);
      var intervalName;
      self.focus(function () {
        intervalName = setInterval(function () {
          self.trigger("propertychange");
        }, 300);
      });
      self.blur(function () {
        clearInterval(intervalName);
      });
    });
  }
});

$(function () {
  $(".colorBox em").hover(function () {
    $(this).toggleClass("on").siblings(".colorBox em").removeClass("on");
  });

  $(".fund1 input").change(function () {
    $(":checked")
      .parents(".deal-list tr")
      .addClass("cur")
      .siblings()
      .removeClass("cur");
  });

  $(".fund1 input:checked").each(function () {
    $(this)
      .parents(".deal-list tr")
      .addClass("cur")
      .siblings()
      .removeClass("cur");
  });

  $(".radio-on").on("click", function () {
    $(this).addClass("on").siblings().removeClass("on");
  });
});

/*
 //换肤
 $(function () {
 var $li = $("#colorBox em");
 $li.click(function (){
 switchSkin(this.id);
 });
 // Save Cookie
 var cookie_skin = $.cookie("MyCssSkin");
 if (cookie_skin) {
 switchSkin(cookie_skin);
 }
 });
 function switchSkin(skinName) {
 $("#" + skinName).addClass("selected")
 .siblings().removeClass("selected");
 $("#cssfile").attr("href", "../style/skin/" + skinName + ".css");
 $.cookie("MyCssSkin", skinName, { path: '/', expires: 10 });
 }


 //cookie
 jQuery.cookie = function(name, value, options) {
 if (typeof value != 'undefined') {
 options = options || {};
 if (value === null) {
 value = '';
 options.expires = -1
 }
 var expires = '';
 if (options.expires && (typeof options.expires == 'number' || options.expires.toUTCString)) {
 var date;
 if (typeof options.expires == 'number') {
 date = new Date();
 date.setTime(date.getTime() + (options.expires * 24 * 60 * 60 * 1000))
 } else {
 date = options.expires
 }
 expires = '; expires=' + date.toUTCString()
 }
 var path = options.path ? '; path=' + (options.path) : '';
 var domain = options.domain ? '; domain=' + (options.domain) : '';
 var secure = options.secure ? '; secure': '';
 document.cookie = [name, '=', encodeURIComponent(value), expires, path, domain, secure].join('')
 } else {
 var cookieValue = null;
 if (document.cookie && document.cookie != '') {
 var cookies = document.cookie.split(';');
 for (var i = 0; i < cookies.length; i++) {
 var cookie = jQuery.trim(cookies[i]);
 if (cookie.substring(0, name.length + 1) == (name + '=')) {
 cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
 break
 }
 }
 }
 return cookieValue
 }
 };*/

/*排序*/
$(function () {
  $("table.sorting").each(function () {
    var $table = $(this); //将table存储为一个jquery对象
    $("th", $table).each(function (column) {
      var findSortKey;
      if ($(this).is(".sort-alpha")) {
        //按字母排序
        findSortKey = function ($cell) {
          return (
            $cell.find("sort-key").text().toUpperCase() +
            "" +
            $cell.text().toUpperCase()
          );
        };
      } else if ($(this).is(".sort-numeric")) {
        //按数字排序
        findSortKey = function ($cell) {
          var key = parseFloat($cell.text().replace(/^[^\d.]*/, ""));
          return isNaN(key) ? 0 : key;
        };
      } else if ($(this).is(".sort-date")) {
        //按日期排序
        findSortKey = function ($cell) {
          return Date.parse("1 " + $cell.text());
        };
      }

      if (findSortKey) {
        $(this)
          .addClass("clickable")
          .hover(
            function () {
              $(this).addClass("hover");
            },
            function () {
              $(this).removeClass("hover");
            }
          )
          .click(function () {
            //反向排序状态声明
            var newDirection = 1;
            if ($(this).is(".sorted-asc")) {
              newDirection = -1;
            }
            var rows = $table.find("tbody>tr").get(); //将数据行转换为数组
            $.each(rows, function (index, row) {
              row.sortKey = findSortKey($(row).children("td").eq(column));
            });
            rows.sort(function (a, b) {
              if (a.sortKey < b.sortKey) return -newDirection;
              if (a.sortKey > b.sortKey) return newDirection;
              return 0;
            });
            $.each(rows, function (index, row) {
              $table.children("tbody").append(row);
              row.sortKey = null;
            });

            $table
              .find("th")
              .removeClass("sorted-asc")
              .removeClass("sorted-desc");
            var $sortHead = $table
              .find("th")
              .filter(":nth-child(" + (column + 1) + ")");
            //实现反向排序
            if (newDirection == 1) {
              $sortHead.addClass("sorted-asc");
            } else {
              $sortHead.addClass("sorted-desc");
            }
          });
      }
    });
  });
});

/**
 * 时间戳方法
 * @returns {*}
 */
function timeStampFun() {
  var timeStamp = new Date().valueOf();
  return timeStamp;
}

/**
 * 日志打印
 * @param str
 */
function log(str) {
  // console.log(str);
}

/**
 * 判断数值是否为空
 * @param str    判断的值
 * @param inputId: 可选参数，    input框id，此处是为了处理对于不支持placeholder的input的框，判断为空时会将placeholder的值当做非空
 * @returns
 */
function isNull(str, inputId) {
  if (
    inputId === "null" ||
    inputId === null ||
    typeof inputId === "undefined" ||
    inputId === undefined ||
    inputId === ""
  ) {
    return (
      str === "null" ||
      str === null ||
      typeof str === "undefined" ||
      str === undefined ||
      str === ""
    );
  }
  return (
    str === "null" ||
    str === null ||
    typeof str === "undefined" ||
    str === undefined ||
    str === "" ||
    str === $("#" + inputId).attr("placeholder")
  );
}

/**
 * 获取当前日期
 */
function currentDate() {
  var nowdate = new Date();
  var seperator1 = "-";
  var seperator2 = ":";
  var month = nowdate.getMonth() + 1;
  var strDate = nowdate.getDate();
  if (month >= 1 && month <= 9) {
    month = "0" + month;
  }
  if (strDate >= 0 && strDate <= 9) {
    strDate = "0" + strDate;
  }
  return (
    nowdate.getFullYear() +
    seperator1 +
    month +
    seperator1 +
    strDate +
    " " +
    nowdate.getHours() +
    seperator2 +
    nowdate.getMinutes() +
    seperator2 +
    nowdate.getSeconds()
  );
}

/**
 * 千分位数字转化
 * @param num
 * @param cent
 * @returns {string}
 */
function toThousands(num, cent) {
  if (isNull(num)) {
    num = 0;
  }
  if (cent == 0) {
  } else if (isNull(cent)) {
    cent = 2;
  }
  num = parseFloat(num).toFixed(cent);
  var tempNum = num.toString();

  if (tempNum.indexOf(".") == -1) {
    return (num || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
  } else {
    var numArr = num.toString().split(".");
    return (
      (numArr[0] || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,") +
      "." +
      numArr[1]
    );
  }
}

/**
 * 计算加减 days-加减天数
 */
function addDate(date, days) {
  date = date.replace(/-/g, "/");
  var d = new Date(date);
  d.setDate(d.getDate() + days);
  var m = d.getMonth() + 1;
  var day = d.getDate();
  if (Number(m) < 10) {
    m = "0" + m;
  }
  if (Number(day) < 10) {
    day = "0" + day;
  }
  return d.getFullYear() + "" + m + "" + day;
}

/**
 * 计算加减 days-加减天数
 */
function addDate2(date, days) {
  date = date.replace(/-/g, "/");
  var d = new Date(date);
  d.setDate(d.getDate() + days);
  var m = d.getMonth() + 1;
  var day = d.getDate();
  if (Number(m) < 10) {
    m = "0" + m;
  }
  if (Number(day) < 10) {
    day = "0" + day;
  }
  return d.getFullYear() + "-" + m + "-" + day;
}

/**
 * 获取当前日期
 */
function getNowDate() {
  var d = new Date();
  var y = d.getFullYear();
  var m = d.getMonth() + 1;
  var dd = d.getDate();

  if (Number(m) < 10) {
    m = "0" + m;
  }
  if (Number(dd) < 10) {
    dd = "0" + dd;
  }

  return y + "" + m + "" + dd;
}

/**
 * 方法有问题，请勿使用
 * @param s
 * @param n
 * @returns {string}
 */
function fmoney(s, n) {
  n = n > 0 && n <= 20 ? n : 2;
  s = parseFloat((s + "").replace(/[^\d\.-]/g, "")).toFixed(n) + "";
  var l = s.split(".")[0].split("").reverse(),
    r = s.split(".")[1];
  t = "";
  for (i = 0; i < l.length; i++) {
    t += l[i] + ((i + 1) % 3 == 0 && i + 1 != l.length ? "," : "");
  }
  return t.split("").reverse().join("") + "." + r;
}

/**
 * 日期格式转换
 * @param date
 * @returns {*}
 */
function fdate(date) {
  if (date.length !== 8) {
    return date;
  }
  return (
    date.substr(0, 4) + "-" + date.substring(4, 6) + "-" + date.substr(6, 8)
  );
}

/**
 * 获取链接参数
 * @returns {*}
 */
function getParams() {
  var thisURL = window.location.href;
  var args = thisURL.split("?");
  var retval = [];
  if (args[0] == thisURL) {
    /*参数为空*/

    return retval;
    /*无需做任何处理*/
  } else {
    var str = args[1];
    args = str.split("&");
    for (var i = 0; i < args.length; i++) {
      str = args[i];
      var arg = str.split("=");
      if (arg.length <= 1) continue;
      retval.push(arg[1]);
    }
    return retval;
  }
}

/**
 *  //时间戳转换为yyyy-MM-dd
 * @param nS
 * @returns {string}
 */
function getLocalTime(nS) {
  var date = new Date(nS);
  Y = date.getFullYear() + "-";
  M =
    (date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1) + "-";
  D = date.getDate() + " ";
  h = (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
  m =
    (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
    ":";
  s = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
  return Y + M + D + h + m + s;
}

/**
 * 页面保存值
 * @param key
 * @param value
 * @param bFlag
 */
function saveValue(key, value, bFlag) {
  if (bFlag == false) {
    //程序退出即消失
    localStorage.setItem(key, value);
  } else if (bFlag == true) {
    //保存本地
    localStorage.setItem(key, value);
  }
}

/**
 * 页面取值
 * @param key
 * @param bFlag
 * @returns {*}
 */
function loadValue(key, bFlag) {
  if (bFlag == false) {
    return localStorage.getItem(key);
  } else if (bFlag == true) {
    return localStorage.getItem(key);
  }
}

/**
 * 页面删除值
 * @param key
 * @param bFlag
 * @returns {*}
 */
function removeValue(key, bFlag) {
  if (bFlag == false) {
    return localStorage.removeItem(key);
  } else if (bFlag == true) {
    return localStorage.removeItem(key);
  }
}

//日期格式转换
function getShortStrDate(strDate) {
  if (isNull(strDate)) {
    return null;
  } else {
    var strdate = strDate;
    var str = "";
    str += strdate.substring(0, 4);
    str += "-";
    str += strdate.substring(4, 6);
    str += "-";
    str += strdate.substring(6, 8);
    return str;
  }
}

//交易时间格式转换
function getStrDate(strDate) {
  if (isNull(strDate)) {
    return null;
  } else {
    var strdate = strDate;
    var str = "";
    str += strdate.substring(0, 4);
    str += "-";
    str += strdate.substring(4, 6);
    str += "-";
    str += strdate.substring(6, 8);
    str += " ";
    str += strdate.substring(8, 10);
    str += ":";
    str += strdate.substring(10, 12);
    str += ":";
    str += strdate.substring(12);
    return str;
  }
}

//买入卖出交易时间格式转换
function getStrDate2(strDate) {
  if (isNull(strDate)) {
    return null;
  } else {
    var strdate = strDate;
    var str = "";
    str += strdate.substring(0, 4);
    str += "-";
    str += strdate.substring(4, 6);
    str += "-";
    str += strdate.substring(6, 8);
    return str;
  }
}

//此方法和上面的那个方法功能一致，只是为了兼容火狐浏览器返回的日期格式不一样
function getStrDateFitToFF(strDate) {
  if (isNull(strDate)) {
    return null;
  } else {
    var strdate = strDate;
    var str = "";
    str += strdate.substring(0, 4);
    str += "/";
    str += strdate.substring(4, 6);
    str += "/";
    str += strdate.substring(6, 8);
    str += " ";
    str += strdate.substring(8, 10);
    str += ":";
    str += strdate.substring(10, 12);
    str += ":";
    str += strdate.substring(12);
    return str;
  }
}

/*
 * RSA加密（老客户）
 *
 * */
function RSAUtil_older(pwd) {
  var m_p = pwd;
  var publicKey =
    "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCRWkanxvOmtFoGam9evF83T5l48uYIyWtMRvAi9qbe5EG9yJ9Xr+HsuEtWhQ0otyoezTBOQS3Fg4fE1LTD2YbUCuV2J7rzbULmENnFg/0XkxMMX6bHJkCAC/SwUmonsHC0e5dlk0wr5rR/fgeBpwL0pDtVb0ZAcos5CEXu2me9rQIDAQAB";
  var encrypt = new JSEncrypt();
  encrypt.setPublicKey(publicKey);
  var r_p = encrypt.encrypt(m_p);
  return r_p;
}

/*
 * RSA加密
 *
 * */
function RSAUtil(pwd) {
  var m_p = hex_md5(pwd + "phfund201606200001");
  var publicKey =
    "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCRWkanxvOmtFoGam9evF83T5l48uYIyWtMRvAi9qbe5EG9yJ9Xr+HsuEtWhQ0otyoezTBOQS3Fg4fE1LTD2YbUCuV2J7rzbULmENnFg/0XkxMMX6bHJkCAC/SwUmonsHC0e5dlk0wr5rR/fgeBpwL0pDtVb0ZAcos5CEXu2me9rQIDAQAB";
  var encrypt = new JSEncrypt();
  encrypt.setPublicKey(publicKey);
  var r_p = encrypt.encrypt(m_p);
  return r_p;
}

/**
 * RSA解密
 */
function RSADecrypt(data) {
  var publicKey =
    "MIICdwIBADANBgkqhkiG9w0BAQEFAASCAmEwggJdAgEAAoGBAKsGGcStdPQESsATnCoYl8CqrraZ8Bj6DRNuLQ8EBtFblnfzssSCoLv/69xFYHr+zcTOfzJZqOIt95/bGrOoOSKTP7nlWeLGVxN5WDLbJrOWxt8laeJmk6MMCFeUXAHpNPbSznPVk7VdK/Qa+EVI2L183xjQN5GDt61YVp0+rOqVAgMBAAECgYAFBP9RnfOuS8fz3j95ntIpZfkNw/Pfj8s9dEDfTXNzxpfiemUXTmwwSGEgTGr6ASuB6Luj+yyxQr8fUnt0pW3T0RvALnQwrod2YwEfDqnqieaKObjsNcP317l+rSm05hMU8ej6mhc6UnLbO4LC7VBS+CNE92j2KT1WFBEcruUesQJBAOh9C2iLmVbLLLygy/IY0mHpqSHAmrNj/AVSQOLyzbMJgIW29cUQjnZ8nWLYPmm/zaRl903AhLNnyVmfmlsIRtkCQQC8Ucg2nhcIHaWX+3g9owwnFCki2e8JVrM+spMtqq0nVS73MRxd3FWXq/zEgNvOfoOU1NEITB5r5T4cPFW8vYQdAkEAjI2UiRbklFFu8Xbw9aNGBYXjB5HOtSd8ZKcurUYHT/ctqSlMemAhEMcTy39adEpOA9bVZT8/usrDMxjHk8N0OQJAYfxbpK+VZE+ove/GnKudwyemT8/9pGpMUa8xDW3lMrYL/eq7y8q9bnG6z7QMquEBLgudyCdzHMjnDGIIanBhsQJBAL4PYuTktLZyKd65FPwCnspvJ8UIVlZCFmedaGho7RTEBahjFZgWP39ekYA9JY4AZDVmoZrH+KP1EHZay0yX1Bs=";
  const decrypt = new JSEncrypt();
  decrypt.setPublicKey(publicKey);

  // 分割密文
  var decryptChunks = data.split("@");

  // 解密每个分割的密文
  var decryptedChunks = [];
  for (var i = 0, len = decryptChunks.length; i < len; i++) {
    decryptedChunks.push(decrypt.decrypt(decryptChunks[i]));
  }

  // 合并明文
  var decryptedText = JSON.parse(decryptedChunks.join(""));
  console.log("decryptedText", decryptedText);

  return decryptedText;
}

/*
 * RSA加密
 *
 * */
function RSAUtil_transPwd(pwd, publicKey) {
  var m_p = hex_md5(pwd);
  var encrypt = new JSEncrypt();
  encrypt.setPublicKey(publicKey);
  var r_p = encrypt.encrypt(m_p);
  return r_p;
}

function convertCurrency(money) {
  //汉字的数字
  var cnNums = new Array(
    "零",
    "壹",
    "贰",
    "叁",
    "肆",
    "伍",
    "陆",
    "柒",
    "捌",
    "玖"
  );
  //基本单位
  var cnIntRadice = new Array("", "拾", "佰", "仟");
  //对应整数部分扩展单位
  var cnIntUnits = new Array("", "万", "亿", "兆");
  //对应小数部分单位
  var cnDecUnits = new Array("角", "分", "毫", "厘");
  //整数金额时后面跟的字符
  var cnInteger = "整";
  //整型完以后的单位
  var cnIntLast = "元";
  //最大处理的数字
  var maxNum = 999999999999999.9999;
  //金额整数部分
  var integerNum;
  //金额小数部分
  var decimalNum;
  //输出的中文金额字符串
  var chineseStr = "";
  //分离金额后用的数组，预定义
  var parts;
  if (money == "") {
    return "";
  }
  money = parseFloat(money);
  if (money >= maxNum) {
    //超出最大处理数字
    return "";
  }
  if (money == 0) {
    chineseStr = cnNums[0] + cnIntLast + cnInteger;
    return chineseStr;
  }
  //转换为字符串
  money = money.toString();
  if (money.indexOf(".") == -1) {
    integerNum = money;
    decimalNum = "";
  } else {
    parts = money.split(".");
    integerNum = parts[0];
    decimalNum = parts[1].substr(0, 4);
  }
  //获取整型部分转换
  if (parseInt(integerNum, 10) > 0) {
    var zeroCount = 0;
    var IntLen = integerNum.length;
    for (var i = 0; i < IntLen; i++) {
      var n = integerNum.substr(i, 1);
      var p = IntLen - i - 1;
      var q = p / 4;
      var m = p % 4;
      if (n == "0") {
        zeroCount++;
      } else {
        if (zeroCount > 0) {
          chineseStr += cnNums[0];
        }
        //归零
        zeroCount = 0;
        chineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
      }
      if (m == 0 && zeroCount < 4) {
        chineseStr += cnIntUnits[q];
      }
    }
    chineseStr += cnIntLast;
  }
  //小数部分
  if (decimalNum != "") {
    var decLen = decimalNum.length;
    for (var i = 0; i < decLen; i++) {
      var n = decimalNum.substr(i, 1);
      if (n != "0") {
        chineseStr += cnNums[Number(n)] + cnDecUnits[i];
      }
    }
  }
  if (chineseStr == "") {
    chineseStr += cnNums[0] + cnIntLast + cnInteger;
  } else if (decimalNum == "") {
    chineseStr += cnInteger;
  }
  return chineseStr;
}

function changeNum(num) {
  var number = parseFloat(num); //讲字符串数字转换为数字类型
  if (parseInt(number / 10000) != 0) {
    return "" + parseInt(number / 10000) + "万";
  }
  return "" + number + "";
}

/**
 * 获取链接指定参数
 * @param key
 * @returns {*}
 */
function getParamsByName(key) {
  var thisURL = window.location.href;
  var params = thisURL.split("?");

  if (isNull(params[1])) {
    /*参数为空*/
    return null;
  }
  var args = params[1].split("&");
  for (var i = 0; i < args.length; i++) {
    var str = args[i];
    var arg = str.split("=");
    if (arg.length <= 1) {
      continue;
    }

    if (arg[0] === key) {
      return arg[1];
    }
  }
  return null;
}

var fundBuyTip = {
  2: "该产品处于发行成功状态，暂时无法交易",
  3: "该产品处于发行失败状态，无法交易",
  4: "该产品处于停止交易状态，无法交易",
  5: "该产品处于停止申购状态，无法申购",
  9: "该产品处于基金封闭状态，无法交易",
  a: "该产品处于基金终止状态，无法交易",
};
var outLineId;
var fundSummaryArr = [];
/**
 * 基金申购
 * @param fundCode: 基金代码
 * @param onShelf: 基金是否上架
 * @param state: 基金状态
 * @param isPortfolioAsset: 是否组合资产 0-否 1-是
 */
function fundBuy(
  fundCode,
  onShelf,
  state,
  isPortfolioAsset,
  fundSubType,
  fundType
) {
  if (fundSubType == "3") {
    confirm(
      "本产品需通过A加钱包APP进行买入",
      "我知道了",
      "下载A加钱包",
      "",
      function () {
        window.location.href = "https://www.phfund.com.cn/web/mobile/";
      }
    );
    return;
  }
  if (onShelf === "0") {
    alert("该基金还未上架");
    return;
  }

  if (isPortfolioAsset == "1") {
    alertIknowMsg("组合持仓暂时无法查看详情");
    return;
  }

  fundBuyTip[state]
    ? alert(fundBuyTip[state])
    : (function () {
        getReady(
          "0",
          function () {
            isRealName() ? gotoAddmybindbankcard() : gotoFirstbindcard();
          },
          function () {
            gotoUploadIdCard("?from=0");
          },
          function () {
            window.location.href = window.phURL + "personal/evaluation.html";
          },
          function () {
            toApi_toAcCg(
              "S15Q146",
              { type: 0, codes: [fundCode] },
              function (data) {
                if (!isNull(data) && data.hasSummary == "1") {
                  if (
                    !isNull(data) &&
                    !isNull(data.data) &&
                    data.data.length > 0 &&
                    !isNull(data.data[0].summary)
                  ) {
                    outLineId = data.data[0].id;
                    // var url = data.data[0].summary.replace("http://", "//");
                    var arr = data.data.map(function (val, index) {
                      return {
                        eq: index,
                        title: val.agreementName,
                        src: val.summary.replace("http://", "//"),
                        hasRead: false,
                      };
                    });
                    fundSummaryArr = data.data.map(function (val) {
                      return {
                        fundCode: fundCode,
                        productInfoId: val.id,
                        transCode: "020", // 认申购020，定投039，转换036
                        ptfCode: 0,
                      };
                    });

                    showOutLine(arr);
                    saveValue("outLineFundCode", fundCode, false);
                    saveValue("summaryID", outLineId, false);
                  } else {
                    window.location.href =
                      window.phURL + "fund/fundBuy.html?fundCode=" + fundCode;
                  }
                } else {
                  if (fundType != "04" && fundType != "08") {
                    if (data && (!data.data || data.data.length === 0)) {
                      alertMsg("该产品无资料概要，暂时无法交易，请联系客服");
                      return;
                    }

                    // if (Object.keys(data.readedFundSummaries).length > 0) {
                    //     window.location.href = window.phURL + 'fund/fundBuy.html?fundCode=' + fundCode;
                    //     return;
                    // }
                  } else {
                    window.location.href =
                      window.phURL + "fund/fundBuy.html?fundCode=" + fundCode;
                  }
                }

                console.log(55555555, "S15Q146", data);
                // if (!isNull(data)  && fundType != '04') {

                //     if (!isNull(data) && !isNull(data.data) && data.data.length > 0 && !isNull(data.data[0].summary) && data.hasSummary == '1') {
                //         outLineId = data.data[0].id;
                //         // var url = data.data[0].summary.replace("http://", "//");
                //         var arr= data.data.map(function(val,index){
                //             return {
                //                 eq:index,
                //                 title:val.agreementName,
                //                 src:val.summary.replace("http://", "//"),
                //                 hasRead:false
                //             }
                //         })
                //         fundSummaryArr = data.data.map(function(val){
                //             return {
                //                 fundCode:fundCode,
                //                 productInfoId:val.id,
                //                 transCode: "020", // 认申购020，定投039，转换036
                //                 ptfCode: 0

                //             }
                //         })

                //         showOutLine(arr);
                //         saveValue("outLineFundCode", fundCode, false);
                //         saveValue("summaryID", outLineId, false);
                //     } else {
                //         window.location.href = window.phURL + 'fund/fundBuy.html?fundCode=' + fundCode;
                //     }
                // } else {
                //     if (fundType != '04') {
                //         if (data && (!data.data || data.data.length === 0)) {
                //             alertMsg('该产品无资料概要，暂时无法交易，请联系客服');
                //             return;
                //         }

                //         // if (Object.keys(data.readedFundSummaries).length > 0) {
                //         //     window.location.href = window.phURL + 'fund/fundBuy.html?fundCode=' + fundCode;
                //         //     return;
                //         // }
                //     } else {
                //         window.location.href = window.phURL + 'fund/fundBuy.html?fundCode=' + fundCode;
                //     }
                // }

                // if (!isNull(data) && !isNull(data.data) && (data.data.length > 0) && !isNull(data.data[0].summary)) {
                //     outLineId = data.data[0].id;
                //     var url = data.data[0].summary.replace("http://", "//");
                //     showOutLine(url);
                //     saveValue("outLineFundCode", fundCode, false);
                //     saveValue("summaryID", outLineId, false);
                // } else {
                //     window.location.href = window.phURL + 'fund/fundBuy.html?fundCode=' + fundCode;
                // }
              },
              "",
              "",
              function (err) {
                alertMsg("该产品无资料概要，暂时无法交易，请联系客服");
                // window.location.href = window.phURL + 'fund/fundBuy.html?fundCode=' + fundCode;
              }
            );
          }
        );
      })();
}
var noReadArr = [];
var itemArr = [];
var nowIndex = 0;
var hasReadNum = 0;
function showOutLine(arr) {
  nowIndex = 0;
  itemArr = arr;
  $(".tabsWrap").empty(); //从被选元素中删除子元素
  $(".iframe_box").empty(); //从被选元素中删除子元素

  if (itemArr.length > 1) {
    $(".nextBtn2").show();
    $(".nextBtn").hide();
  } else {
    $(".nextBtn").show();
    $(".nextBtn2").hide();
  }
  $(".nextBtn2").text("我已阅读并知悉(0/" + itemArr.length + ")");
  itemArr.forEach(function (val, index) {
    iframeItem = document.createElement("iframe");
    iframeItem.setAttribute("class", "outLine_box");
    iframeItem.setAttribute("width", "99.9%");
    iframeItem.setAttribute("height", "500px");
    iframeItem.setAttribute("id", "pdfContainer");
    iframeItem.setAttribute(
      "src",
      apiURL + "wechat/toolJS/pdf/web/viewer.html?file=" + val.src + "#page=1"
    );
    iframeItem.setAttribute("allowTransparency", "true");
    iframeItem.setAttribute("frameborder", "0");
    iframeItem.setAttribute("scrolling", "no");
    iframeItem.setAttribute("marginheight", "0");
    iframeItem.setAttribute("marginwidth", "0");

    titleItem = document.createElement("span");
    titleItem.innerText = val.title;

    if (index == 0) {
      iframeItem.setAttribute("style", "display:block;");
      titleItem.setAttribute("class", "active titleItem");
    } else {
      titleItem.setAttribute("class", "titleItem");
    }

    $(".iframe_box").append(iframeItem);
    $(".tabsWrap").append(titleItem);
  });
  if (itemArr.length == 1) {
    //只有一个产品概要，不显示tab标题
    $(".tabsWrap").empty();
  }
  $(document).on("click", ".titleItem", function () {
    var i = $(this).index();
    nowIndex = $(this).index();
    $(this).addClass("active").siblings().removeClass("active");
    $(".outLine_box").eq(i).show().siblings().hide();
    // if(itemArr[i].hasRead == false){
    //     itemArr[i].hasRead = true;

    // }
    // hasReadNum = itemArr.filter(function(val){
    //     return val.hasRead == true;
    // }).length;
    // console.log(444444,hasReadNum)
    // $(".nextBtn2").text("我已阅读并知悉("+ hasReadNum +"/"+ itemArr.length + ")")
  });

  $("#pupFundOutline").show();
  // $("#pdfContainer").attr("src", apiURL + "wechat/toolJS/pdf/web/viewer.html?file=" + data + "#page=1");
}

/**
 * 基金卖出
 * @param fundCode: 基金diamante
 * @param share_type: 收费类别
 * @param fundState: 基金状态
 * @param fundSubType: 是否是专户基金
 * @param openDateStart: 开放期（专户基金时使用）
 * @param openDateEnd: 结束期（专户基金时使用）
 * @param nowTime: 当前时间（专户基金时使用）
 * @param isPortfolioAsset: 是否组合资产 0-否 1-是
 */
var isSelected;
var saleBanklist;
function sale(
  fundCode,
  share_type,
  fundState,
  fundSubType,
  openDateStart,
  openDateEnd,
  nowTime,
  isPortfolioAsset
) {
  //货币加
  if (fundSubType == "3") {
    combinedTip("本产品需通过A加钱包APP进行卖出");
    return;
  }

  if (isPortfolioAsset == "1") {
    alertIknowMsg("组合持仓暂时无法查看详情");
    return;
  }

  if (fundState === "0" || fundState === "5") {
    toSale(fundCode, share_type);
  } else if (fundState === "7" || fundState === "8") {
    //当基金状态为4的时候，状态显示为停止交易，且不能点击买入卖出按钮，显示为灰色
    if (fundSubType === "1") {
      alert("该产品未开放赎回，无法交易");
    } else {
      toSale(fundCode, share_type);
    }
  } else if (fundState === "4") {
    //当基金状态为4的时候，状态显示为停止交易，且不能点击买入卖出按钮，显示为灰色
    alert("该产品处于停止交易状态，无法交易");
  } else if (fundState === "6" || fundState === "d") {
    alert("该产品处于停止赎回状态，无法交易");
  } else if (fundState === "a") {
    alert("该产品处于基金终止状态，无法交易");
  } else if (fundState === "9") {
    alert("该产品处于基金封闭状态，无法交易");
  } else if (fundState === "b" || fundState === "d") {
    alert("该产品未开放赎回，无法交易");
  } else if (fundState === "c") {
    var nowtime = +nowTime.substring(0, 8);
    if (!isNull(openDateStart) && !isNull(openDateEnd)) {
      if (
        nowtime < parseInt(openDateStart) ||
        nowtime > parseInt(openDateEnd)
      ) {
        alert("该产品未处于开放期，无法交易");
      } else {
        toSale(fundCode, share_type);
      }
    } else if (!isNull(openDateStart) && isNull(openDateEnd)) {
      if (nowtime < parseInt(openDateStart)) {
        alert("该产品未处于开放期，无法交易");
      } else {
        toSale(fundCode, share_type);
      }
    } else if (isNull(openDateStart) && !isNull(openDateEnd)) {
      if (nowtime > parseInt(openDateEnd)) {
        toSale(fundCode, share_type);
      } else {
        alert("该产品未处于开放期，无法交易");
      }
    } else {
      alert("该产品未处于开放期，无法交易");
    }
  } else {
    alert("该产品状态错误，暂无法交易");
  }
}
/**
 * 前往基金卖出页面
 * @param fundCode: 基金diamante
 * @param share_type: 收费类别
 * @param fundState: 基金状态
 * @param fundSubType: 是否是专户基金
 * @param openDateStart: 开放期（专户基金时使用）
 * @param openDateEnd: 结束期（专户基金时使用）
 * @param nowTime: 当前时间（专户基金时使用）
 * @param isPortfolioAsset: 是否组合资产 0-否 1-是
 */
var isSelectRedeem;
var isSelectTransfer;
var nowAvailableShare;
var nowFundCode;
var nowShareType;
var nowCashAcctId;
var nowRollRecordId;

function toSale(fundCode, share_type) {
  toApi_toAcCg(
    "S15Q428",
    { fundCode: fundCode, shareType: share_type },
    function (res) {
      if (res.rollDetailList == null || res.rollDetailList.length == 0) {
        toSaleNext(fundCode, share_type);
        return;
      }

      if (Array.isArray(res.rollDetailList) && res.rollDetailList.length > 0) {
        const saleArr = res.rollDetailList.filter(function (val) {
          return val.redeemFlag == "1";
        });
        console.log("saleArr", saleArr);
        if (saleArr.length == 0) {
          alertIknowMsg(
            "当前无到期可赎回份额，如需要了解每笔份额到期时间，可前往鹏华A加钱包查询"
          );
        } else if (saleArr.length == 1) {
          toApi_toAcCg(
            "S15Q402",
            { cashAcctId: saleArr[0].cashAcctId },
            function (data) {
              console.log(555, data);
              if (data.respCode == "0000" && data.state == "0") {
                if (
                  Array.isArray(data.bankCardInfoList) &&
                  data.bankCardInfoList.length > 0
                ) {
                  saveValue(
                    "redeemCashAcctId",
                    data.bankCardInfoList[0].cashAcctid,
                    false
                  );
                  saveValue(
                    "redeemBankAcctNo",
                    data.bankCardInfoList[0].cardno,
                    false
                  );
                  saveValue(
                    "redeemBankCardMsg",
                    data.bankCardInfoList[0].banknm,
                    false
                  );
                  saveValue(
                    "redeemTotalShare",
                    saleArr[0].availableShare,
                    false
                  );
                }
                $("#canRedeemList").hide();
                window.location.href =
                  phURL +
                  "fund/redemption.html?fundCode=" +
                  fundCode +
                  "&isEnable=Y&share_type=" +
                  share_type +
                  "&nowAvailableShare=" +
                  saleArr[0].availableShare +
                  "&nowRollRecordId=" +
                  saleArr[0].rollRecordId;
              } else {
                alert(data.respMsg);
              }
            }
          );
        } else if (saleArr.length > 1) {
          var transCfmDate = "";
          var availableShare = "";
          var select = "";
          var liHtml = "";
          var cashAcctId = "";
          //循环遍历赎回列表

          for (var i = 0; i < saleArr.length; i++) {
            transCfmDate = "<p>" + saleArr[i].transCfmDate + "</p>";
            availableShare = "<p>" + saleArr[i].availableShare + "份</p>";
            select =
              '<p><em class="contextUnchecked" id="myCheck' + i + '"></em></p>';

            liHtml +=
              '<li onclick="selectRedeemLi(' +
              i +
              "," +
              saleArr.length +
              "," +
              "'" +
              fundCode +
              "'" +
              "," +
              "'" +
              share_type +
              "'" +
              "," +
              "'" +
              saleArr[i].availableShare +
              "'" +
              "," +
              "'" +
              saleArr[i].cashAcctId +
              "'" +
              "," +
              "'" +
              saleArr[i].rollRecordId +
              "'" +
              ')">' +
              transCfmDate +
              availableShare +
              select +
              "</li>";
            // liHtml += '<li onclick="selectRedeemLi('+i+','+saleArr.length+",'000409',0,"+saleArr[i].availableShare+',69000000365)">'+transCfmDate+availableShare+select+'</li>'
          }
          console.log("LIHTML", liHtml);
          $(".canRedeem_list").html(liHtml);
          $(".canRedeemList_close").click(function () {
            $("#canRedeemList").hide();
          });
          $(".canRedeemConfirm").click(function () {
            console.log("点击了确定", isSelectRedeem);
            if (!isNull(isSelectRedeem)) {
              //调S15Q402接口存卖出页需要用的session
              toApi_toAcCg(
                "S15Q402",
                { cashAcctId: nowCashAcctId },
                function (data) {
                  console.log(555, data);
                  if (data.respCode == "0000" && data.state == "0") {
                    if (
                      Array.isArray(data.bankCardInfoList) &&
                      data.bankCardInfoList.length > 0
                    ) {
                      saveValue(
                        "redeemCashAcctId",
                        data.bankCardInfoList[0].cashAcctid,
                        false
                      );
                      saveValue(
                        "redeemBankAcctNo",
                        data.bankCardInfoList[0].cardno,
                        false
                      );
                      saveValue(
                        "redeemBankCardMsg",
                        data.bankCardInfoList[0].banknm,
                        false
                      );
                      saveValue("redeemTotalShare", nowAvailableShare, false);
                    }
                    $("#canRedeemList").hide();
                    window.location.href =
                      phURL +
                      "fund/redemption.html?fundCode=" +
                      nowFundCode +
                      "&isEnable=Y&share_type=" +
                      nowShareType +
                      "&nowAvailableShare=" +
                      nowAvailableShare +
                      "&nowRollRecordId=" +
                      nowRollRecordId;
                  } else {
                    alert(data.respMsg);
                  }
                }
              );
            }
          });
          $("#canRedeemList").show();

          //展示可赎回列表
        }
      }
    }
  );
}
function toSaleNext(fundCode, share_type) {
  toApi_toAcCg(
    "S060288",
    { optType: "2", fundCode: fundCode, shareType: share_type },
    function (data) {
      var htmlMsg = "",
        listHtml = "",
        checkHtml = "",
        bankMsg = "";
      var dataList = data.shares;
      if (data.shares && data.shares.length == 0) {
        alertIknowMsg("当前无可赎回份额");
        return;
      }
      saleBanklist = dataList;
      for (var i = 0; i < dataList.length; i++) {
        bankMsg = "<p>" + dataList[i].bankCardMsg + "</p>";
        htmlMsg = "<p>" + toThousands(dataList[i].totalShare) + "份</p>";
        checkHtml =
          '<p><em class="Bank_uncheck" id="checks' + i + '"></em></p>';
        listHtml +=
          '<li onclick="selectOutBank(' +
          i +
          "," +
          dataList.length +
          ",'" +
          dataList[i].cashAcctId +
          "','" +
          dataList[i].bankAcctNo +
          "'," +
          dataList[i].totalShare +
          ')">' +
          bankMsg +
          htmlMsg +
          checkHtml +
          "</li>";
      }
      if (data.isEnable == "Y") {
        $(".redeemList").html(listHtml);
        if (dataList.length > 1) {
          $("#toRedeemBtn").click(function () {
            if (!isNull(isSelected)) {
              $("#selectRedeem").hide();
              window.location.href =
                phURL +
                "fund/redemption.html?fundCode=" +
                fundCode +
                "&isEnable=" +
                data.isEnable +
                "&share_type=" +
                share_type;
            }
          });
          $(".closeSelectRedeem").click(function () {
            $("#selectRedeem").hide();
          });
          $("#selectRedeem").show();
        } else {
          saveValue("redeemCashAcctId", saleBanklist[0].cashAcctId, false);
          saveValue("redeemBankAcctNo", saleBanklist[0].bankAcctNo, false);
          saveValue("redeemBankCardMsg", saleBanklist[0].bankCardMsg, false);
          saveValue("redeemTotalShare", saleBanklist[0].totalShare, false);
          window.location.href =
            phURL +
            "fund/redemption.html?fundCode=" +
            fundCode +
            "&isEnable=" +
            data.isEnable +
            "&share_type=" +
            share_type;
        }
      } else {
        window.location.href =
          phURL +
          "fund/redemption.html?fundCode=" +
          fundCode +
          "&isEnable=" +
          data.isEnable +
          "&share_type=" +
          share_type;
      }
    }
  );
}

var fundTransferTip = {
  4: "该产品处于停止交易状态，无法交易",
  6: "该产品处于停止赎回状态，无法交易",
  9: "该产品处于基金封闭状态，无法交易",
  a: "该产品处于基金终止状态，无法交易",
};

/**
 * 基金转换
 * @param limitExflag
 * @param fundState
 * @param scprodna
 * @param scfundcode
 * @param beforeAvailableShare
 * @param afterAvailableShare
 * @param sharetype
 * @param availableShare
 * @param indexFlag
 * @param isPortfolioAsset: 是否组合资产 0-否 1-是
 */
function fundTransfer(
  limitExflag,
  fundState,
  scprodna,
  scfundcode,
  beforeAvailableShare,
  afterAvailableShare,
  sharetype,
  availableShare,
  indexFlag,
  isPortfolioAsset,
  fundSubType
) {
  if (fundSubType == "3") {
    alertIknowMsg("该基金无法转换");
    return;
  }
  if (isPortfolioAsset == "1") {
    alertIknowMsg("组合持仓暂时无法查看详情");
    return;
  }

  if (limitExflag === "0" || limitExflag === "2") {
    alert("该基金暂不可转换");
    return;
  }

  if (
    fundState === "0" ||
    fundState === "5" ||
    fundState === "7" ||
    fundState === "8"
  ) {
    getReady(
      "5",
      function () {
        isRealName() ? gotoAddmybindbankcard() : gotoFirstbindcard();
      },
      function () {
        gotopersonalInfoAdd("from=0");
      },
      function () {
        window.location.href = window.phURL + "personal/evaluation.html";
      },
      function () {
        window.location.href =
          phURL +
          "fund/fundTrading.html?scprodna=" +
          scprodna +
          "&scfundcode=" +
          scfundcode +
          "&beforeAvailableShare=" +
          beforeAvailableShare +
          "&afterAvailableShare=" +
          afterAvailableShare +
          "&sharetype=" +
          sharetype +
          "&holdShare=" +
          "" +
          "&availableShare=" +
          availableShare +
          "&limitExflag=" +
          limitExflag +
          "&indexFlag=" +
          indexFlag;
      }
    );
    return;
  }

  return alert(fundTransferTip[fundState] || "该产品状态错误，暂无法交易");
}

var productTypeCode = "";
function goFundInvestment(
  serType,
  fundCode,
  prodna,
  isPortfolioAsset,
  fundSubType,
  fundType
) {
  console.log("调用了S15Q032");
  toApi_toAcCg("S15Q032", { fundCode: fundCode }, function (res) {
    console.log(555, res);
    productTypeCode = res.productTypeCode || "0";
    if (productTypeCode == "1") {
      alertIknowMsg("滚动持有期产品暂不支持定投业务");
    } else {
      if (fundSubType == "3") {
        alertIknowMsg("该基金无法定投");
        return;
      }
      if (isPortfolioAsset == "1") {
        alertIknowMsg("组合持仓暂时无法查看详情");
        return;
      }
      return serType === "0"
        ? alert("该基金暂不可定投")
        : fundInvestment(fundCode, prodna, null, fundType);
    }
  });
}

/**
 * 定投
 * @param fundCode
 * @param fundName
 * @param beforeDoFun 跳转页面之前可能需要执行一些之前页面所需要处理的业务内容function,注：function不能加引号
 */
function fundInvestment(fundCode, fundName, beforeDoFun, fundType) {
  getReady(
    "1",
    function () {
      isRealName() ? gotoAddmybindbankcard() : gotoFirstbindcard();
    },
    function () {
      gotopersonalInfoAdd("from=0");
    },
    function () {
      window.location.href = window.phURL + "personal/evaluation.html";
    },
    function () {
      //跳转页面之前可能需要执行一些之前页面所需要处理的业务内容
      if (!isNull(beforeDoFun)) {
        beforeDoFun();
      }
      if (isNull(fundCode) || isNull(fundName)) {
        //基金定投列表，点击基金定投按钮，不需要传入参数
        window.location.href = phURL + "fund/addfundinvestment.html";
      } else {
        toApi_toAcCg(
          "S15Q146",
          { type: 0, codes: [fundCode] },
          function (data) {
            if (!isNull(data) && data.hasSummary == "1") {
              if (
                !isNull(data) &&
                !isNull(data.data) &&
                data.data.length > 0 &&
                !isNull(data.data[0].summary)
              ) {
                outLineId = data.data[0].id;
                // var url = data.data[0].summary.replace("http://", "//");
                var arr = data.data.map(function (val, index) {
                  return {
                    eq: index,
                    title: val.agreementName,
                    src: val.summary.replace("http://", "//"),
                    hasRead: false,
                  };
                });
                fundSummaryArr = data.data.map(function (val) {
                  return {
                    fundCode: fundCode,
                    productInfoId: val.id,
                    transCode: "020", // 认申购020，定投039，转换036
                    ptfCode: 0,
                  };
                });
                showOutLine(arr);
                saveValue("outLineFundCode", fundCode, false);
                saveValue("summaryID", outLineId, false);
                saveValue("outLineFundName", fundName, false);
                saveValue("outLineUrl", "addfundinvestment", false);
              } else {
                window.location.href =
                  phURL +
                  "fund/addfundinvestment.html?fundName=" +
                  fundName +
                  "&fundCode=" +
                  fundCode;
              }
            } else {
              if (fundType != "04" && fundType != "08") {
                if (data && (!data.data || data.data.length === 0)) {
                  alertMsg("该产品无资料概要，暂时无法交易，请联系客服");
                  return;
                }

                // if (Object.keys(data.readedFundSummaries).length > 0) {
                //     window.location.href = phURL + 'fund/addfundinvestment.html?fundName=' + fundName + '&fundCode=' + fundCode;
                //     return;
                // }
              } else {
                window.location.href =
                  phURL +
                  "fund/addfundinvestment.html?fundName=" +
                  fundName +
                  "&fundCode=" +
                  fundCode;
              }
            }

            console.log("data>>>", data);
            // if (!isNull(data) && fundType != '04') {
            //     if (!isNull(data) && !isNull(data.data) && data.data.length > 0 && !isNull(data.data[0].summary) && data.hasSummary == '1') {
            //         outLineId = data.data[0].id;
            //         // var url = data.data[0].summary.replace("http://", "//");
            //         var arr= data.data.map(function(val,index){
            //             return {
            //                 eq:index,
            //                 title:val.agreementName,
            //                 src:val.summary.replace("http://", "//"),
            //                 hasRead:false
            //             }
            //         })
            //         fundSummaryArr = data.data.map(function(val){
            //             return {
            //                 fundCode:fundCode,
            //                 productInfoId:val.id,
            //                 transCode: "020", // 认申购020，定投039，转换036
            //                 ptfCode: 0

            //             }
            //         })
            //         showOutLine(arr);
            //         saveValue("outLineFundCode", fundCode, false);
            //         saveValue("summaryID", outLineId, false);
            //         saveValue("outLineFundName", fundName, false);
            //         saveValue("outLineUrl", "addfundinvestment", false);
            //     } else {
            //         window.location.href = phURL + 'fund/addfundinvestment.html?fundName=' + fundName + '&fundCode=' + fundCode;
            //     }
            // } else {
            //     if (fundType != '04') {
            //         if (data && (!data.data || data.data.length === 0)) {
            //             alertMsg('该产品无资料概要，暂时无法交易，请联系客服');
            //             return;
            //         }

            //         // if (Object.keys(data.readedFundSummaries).length > 0) {
            //         //     window.location.href = phURL + 'fund/addfundinvestment.html?fundName=' + fundName + '&fundCode=' + fundCode;
            //         //     return;
            //         // }
            //     } else {
            //         window.location.href = phURL + 'fund/addfundinvestment.html?fundName=' + fundName + '&fundCode=' + fundCode;
            //     }
            // }
          },
          "",
          "",
          function () {
            alertMsg("该产品无资料概要，暂时无法交易，请联系客服");
          }
        );
      }
    }
  );
}

/**
 * 查看专户基金详情时需要判断专户基金是否是子公司专户基金 '0': 子公司 '1': 母公司
 * @param fundCode
 * @param fundRiskLv
 */
function beforeGotoTopDetail(fundCode, fundRiskLv) {
  toApi("specialProduct.tml?fundCode=" + fundCode, function (data) {
    var isWhite = data.isWhite;
    toApi(
      "specialproductdetail.tml?fundCode=" + fundCode + "&isWhite=" + isWhite,
      function (data2) {
        gotoTopDetail(fundCode, fundRiskLv, data2.info.splflag);
      }
    );
  });
}

/**
 * 前往专户基金详情页面需要对用户风险等级匹配校验，不通过则不让看详情页面
 * @param fundCode: 基金代码
 * @param fundRiskLv: 基金风险等级
 * @param splFlag: 是否是子公司专户
 */
function gotoTopDetail(fundCode, fundRiskLv, splFlag) {
  if (splFlag === "1") {
    getReady(
      "0",
      function () {
        isRealName() ? gotoAddmybindbankcard() : gotoFirstbindcard();
      },
      function () {
        gotopersonalInfoAdd("from=0");
      },
      function () {
        window.location.href = window.phURL + "personal/evaluation.html";
      },
      function () {
        window.location.href =
          phURL + "fund/topFundDetail.html?fundCode=" + fundCode;
      }
    );
    return;
  }

  subsidiaryReady(
    "0",
    function () {
      isRealName() ? gotoAddmybindbankcard() : gotoFirstbindcard();
    },
    function () {
      window.location.href = window.phURL + "personal/evaluation.html";
    },
    function () {
      var riskLevelMsg = localStorage.riskLevelDesc;
      if (getRiskLv() < fundRiskLv) {
        confirm(
          "您的风险承受能力为[" +
            riskLevelMsg +
            "]，" +
            "不满足此产品[" +
            fundRiskDesc[fundRiskLv] +
            "]的要求",
          "放弃",
          "重新测评",
          "",
          function () {
            window.location.href = window.phURL + "personal/evaluation.html";
          }
        );
        return;
      }
      window.location.href =
        phURL + "fund/topFundDetail.html?fundCode=" + fundCode;
    }
  );
}

/**
 * 前往专户基金详情页面需要对用户风险等级匹配校验，不通过则不让看详情页面
 * @param fundCode: 基金代码
 * @param fundRiskLv: 基金风险等级
 */
function beforeSpecialProductBooking(fundCode, fundRiskLv) {
  toApi("specialProduct.tml?fundCode=" + fundCode, function (data) {
    var isWhite = data.isWhite;
    toApi(
      "specialproductdetail.tml?fundCode=" + fundCode + "&isWhite=" + isWhite,
      function (data2) {
        specialProductBooking(fundCode, fundRiskLv, data2.info.splflag);
      }
    );
  });
}

/**
 * 专户预约
 * @param fundCode: 基金代码
 * @param fundRiskLv: 基金风险等级
 * @param splFlag: 是否是子公司专户
 */
function specialProductBooking(fundCode, fundRiskLv, splFlag) {
  if (splFlag === "1") {
    getReady(
      "0",
      function () {
        isRealName() ? gotoAddmybindbankcard() : gotoFirstbindcard();
      },
      function () {
        gotopersonalInfoAdd("from=0");
      },
      function () {
        window.location.href = window.phURL + "personal/evaluation.html";
      },
      function () {
        window.location.href =
          phURL + "fund/specialAppointmentMsg.html?fundCode=" + fundCode;
      }
    );
    return;
  }

  subsidiaryReady(
    "0",
    function () {
      isRealName() ? gotoAddmybindbankcard() : gotoFirstbindcard();
    },
    function () {
      window.location.href = window.phURL + "personal/evaluation.html";
    },
    function () {
      var riskLevelMsg = localStorage.riskLevelDesc;
      if (getRiskLv() < fundRiskLv) {
        confirm(
          "您的风险承受能力为[" +
            riskLevelMsg +
            "]，" +
            "不满足此产品[" +
            fundRiskDesc[fundRiskLv] +
            "]的要求",
          "放弃",
          "重新测评",
          "",
          function () {
            window.location.href = window.phURL + "personal/evaluation.html";
          }
        );
        return;
      }
      window.location.href =
        phURL + "fund/specialAppointmentMsg.html?fundCode=" + fundCode;
    }
  );
}

/**
 * 专户基金买入，此时不知道splflag值
 * @param fundCode: 基金代码
 */
function specialProductFundBuyBefore(fundCode) {
  toApi("fundproductdatail.tml?fundCode=" + fundCode, function (data) {
    var fundRiskLevel = data.info.riskLevel;
    var splFlag = data.info.splflag;
    specialProductFundBuy(fundCode, fundRiskLevel, splFlag);
  });
}

/**
 * 专户基金买入
 * @param fundCode
 * @param fundRiskLv
 * @param splFlag
 */
function specialProductFundBuy(fundCode, fundRiskLv, splFlag, fundType) {
  if (splFlag === "1") {
    getReady(
      "0",
      function () {
        isRealName() ? gotoAddmybindbankcard() : gotoFirstbindcard();
      },
      function () {
        gotopersonalInfoAdd("from=0");
      },
      function () {
        window.location.href = window.phURL + "personal/evaluation.html";
      },
      function () {
        window.location.href =
          window.phURL + "fund/fundBuy.html?fundCode=" + fundCode;
        return;
        toApi_toAcCg(
          "S15Q146",
          { type: 0, codes: [fundCode] },
          function (data) {
            if (!isNull(data) && data.hasSummary == "1") {
              if (
                !isNull(data) &&
                !isNull(data.data) &&
                data.data.length > 0 &&
                !isNull(data.data[0].summary)
              ) {
                outLineId = data.data[0].id;
                // var url = data.data[0].summary.replace("http://", "//");
                outLineId = data.data[0].id;
                var arr = data.data.map(function (val, index) {
                  return {
                    eq: index,
                    title: val.agreementName,
                    src: val.summary.replace("http://", "//"),
                    hasRead: false,
                  };
                });
                fundSummaryArr = data.data.map(function (val) {
                  return {
                    fundCode: fundCode,
                    productInfoId: val.id,
                    transCode: "020", // 认申购020，定投039，转换036
                    ptfCode: 0,
                  };
                });
                showOutLine(arr);
                saveValue("outLineFundCode", fundCode, false);
                saveValue("summaryID", outLineId, false);
              } else {
                window.location.href =
                  window.phURL + "fund/fundBuy.html?fundCode=" + fundCode;
              }
            } else {
              if (fundType != "04" && fundType != "08") {
                if (data && (!data.data || data.data.length === 0)) {
                  alertMsg("该产品无资料概要，暂时无法交易，请联系客服");
                  return;
                }

                // if (Object.keys(data.readedFundSummaries).length > 0) {
                //     window.location.href = window.phURL + 'fund/fundBuy.html?fundCode=' + fundCode;
                //     return;
                // }
              } else {
                window.location.href =
                  window.phURL + "fund/fundBuy.html?fundCode=" + fundCode;
              }
            }
            // if (!isNull(data) && fundType != '04') {
            //     if (!isNull(data) && !isNull(data.data) && data.data.length > 0 && !isNull(data.data[0].summary) && data.hasSummary == '1') {
            //         outLineId = data.data[0].id;
            //         // var url = data.data[0].summary.replace("http://", "//");
            //         outLineId = data.data[0].id;
            //         var arr= data.data.map(function(val,index){
            //             return {
            //                 eq:index,
            //                 title:val.agreementName,
            //                 src:val.summary.replace("http://", "//"),
            //                 hasRead:false
            //             }
            //         })
            //         fundSummaryArr = data.data.map(function(val){
            //             return {
            //                 fundCode:fundCode,
            //                 productInfoId:val.id,
            //                 transCode: "020", // 认申购020，定投039，转换036
            //                 ptfCode: 0

            //             }
            //         })
            //         showOutLine(arr);
            //         saveValue("outLineFundCode", fundCode, false);
            //         saveValue("summaryID", outLineId, false);
            //     } else {
            //         window.location.href = window.phURL + 'fund/fundBuy.html?fundCode=' + fundCode;
            //     }
            // } else {
            //     if (fundType != '04') {
            //         if (data && (!data.data || data.data.length === 0)) {
            //             alertMsg('该产品无资料概要，暂时无法交易，请联系客服');
            //             return;
            //         }

            //         // if (Object.keys(data.readedFundSummaries).length > 0) {
            //         //     window.location.href = window.phURL + 'fund/fundBuy.html?fundCode=' + fundCode;
            //         //     return;
            //         // }
            //     } else {
            //         window.location.href = window.phURL + 'fund/fundBuy.html?fundCode=' + fundCode;
            //     }
            // }
          },
          "",
          "",
          function (err) {
            alertMsg("该产品无资料概要，暂时无法交易，请联系客服");
          }
        );
      }
    );
    return;
  }

  subsidiaryReady(
    "0",
    function () {
      isRealName() ? gotoAddmybindbankcard() : gotoFirstbindcard();
    },
    function () {
      window.location.href = window.phURL + "personal/evaluation.html";
    },
    function () {
      var riskLevelMsg = localStorage.riskLevelDesc;
      if (getRiskLv() < fundRiskLv) {
        confirm(
          "您的风险承受能力为[" +
            riskLevelMsg +
            "]，" +
            "不满足此产品[" +
            fundRiskDesc[fundRiskLv] +
            "]的要求",
          "放弃",
          "重新测评",
          "",
          function () {
            window.location.href = window.phURL + "personal/evaluation.html";
          }
        );
        return;
      }
      toApi_toAcCg(
        "S15Q146",
        { type: 0, codes: [fundCode] },
        function (data) {
          if (!isNull(data) && data.hasSummary == "1") {
            if (
              !isNull(data) &&
              !isNull(data.data) &&
              data.data.length > 0 &&
              !isNull(data.data[0].summary)
            ) {
              outLineId = data.data[0].id;
              // var url = data.data[0].summary.replace("http://", "//");
              var arr = data.data.map(function (val, index) {
                return {
                  eq: index,
                  title: val.agreementName,
                  src: val.summary.replace("http://", "//"),
                  hasRead: false,
                };
              });
              fundSummaryArr = data.data.map(function (val) {
                return {
                  fundCode: fundCode,
                  productInfoId: val.id,
                  transCode: "020", // 认申购020，定投039，转换036
                  ptfCode: 0,
                };
              });
              showOutLine(arr);
              saveValue("outLineFundCode", fundCode, false);
            } else {
              window.location.href =
                window.phURL + "fund/fundBuy.html?fundCode=" + fundCode;
            }
          } else {
            if (fundType != "04" && fundType != "08") {
              if (data && (!data.data || data.data.length === 0)) {
                alertMsg("该产品无资料概要，暂时无法交易，请联系客服");
                return;
              }

              // if (Object.keys(data.readedFundSummaries).length > 0) {
              //     window.location.href = window.phURL + 'fund/fundBuy.html?fundCode=' + fundCode;
              //     return;
              // }
            } else {
              window.location.href =
                window.phURL + "fund/fundBuy.html?fundCode=" + fundCode;
            }
          }
          // if (!isNull(data) && fundType != '04') {
          //     if (!isNull(data) && !isNull(data.data) && data.data.length > 0 && !isNull(data.data[0].summary) && data.hasSummary == '1') {
          //         outLineId = data.data[0].id;
          //         // var url = data.data[0].summary.replace("http://", "//");
          //         var arr= data.data.map(function(val,index){
          //             return {
          //                 eq:index,
          //                 title:val.agreementName,
          //                 src:val.summary.replace("http://", "//"),
          //                 hasRead:false
          //             }
          //         })
          //         fundSummaryArr = data.data.map(function(val){
          //             return {
          //                 fundCode:fundCode,
          //                 productInfoId:val.id,
          //                 transCode: "020", // 认申购020，定投039，转换036
          //                 ptfCode: 0

          //             }
          //         })
          //         showOutLine(arr);
          //         saveValue("outLineFundCode", fundCode, false);
          //     } else {
          //         window.location.href = window.phURL + 'fund/fundBuy.html?fundCode=' + fundCode;
          //     }
          // } else {
          //     if (fundType != '04') {
          //         if (data && (!data.data || data.data.length === 0)) {
          //             alertMsg('该产品无资料概要，暂时无法交易，请联系客服');
          //             return;
          //         }

          //         // if (Object.keys(data.readedFundSummaries).length > 0) {
          //         //     window.location.href = window.phURL + 'fund/fundBuy.html?fundCode=' + fundCode;
          //         //     return;
          //         // }
          //     } else {
          //         window.location.href = window.phURL + 'fund/fundBuy.html?fundCode=' + fundCode;
          //     }
          // }
        },
        "",
        "",
        function (err) {
          alertMsg("该产品无资料概要，暂时无法交易，请联系客服");
        }
      );
    }
  );
}

/**
 * 前往专户持仓详情
 * @param fundCode: 专户基金代码
 * @param shareType: 专户基金份额类型
 * @param fundState: 专户基金状态
 * @param pageId: 专户持仓详情来源页
 */
function gotoSpecialProductPosition(
  fundCode,
  shareType,
  fundState,
  pageId,
  isAplusAsset
) {
  pageId && (localStorage.whereComeFromMyFund = pageId);
  window.location.href =
    window.phURL +
    "account/p_specialProduct.html" +
    "?fundCode=" +
    fundCode +
    "&shareType=" +
    shareType +
    "&fundState=" +
    fundState +
    "&isAplusAsset=" +
    isAplusAsset;
}

/**
 * 跳转添加银行卡
 */
function addBankCard() {
  gotoFirstbindcard();
}

function startEvluate() {
  var thisURL = window.location.href;
  window.location.href = phURL + "personal/evaluation.html?url=" + thisURL;
}

/**
 * 判断用户是否绑卡
 * @param pageid
 * @param arguments[1]: 提示语
 * @param arguments[2]: 上方按钮内容
 * @param arguments[3]: 下方按钮内容
 * @param arguments[4]:
 * @param arguments[5]: 下方按钮点击事件
 * @param arguments[6]: 页面正常跳转需执行function,注：function不能加引号
 */
function isHaveBankCard(pageid) {
  if (
    pageid === "wallet/recharge.html" ||
    pageid === "wallet/addanxin.html" ||
    pageid === "wallet/enchashment.html" ||
    pageid === "wallet/appointessay.html" ||
    pageid === "wallet/appointadd.html"
  ) {
    var type = "";
    if (pageid === "wallet/recharge.html") {
      type = "2";
    }
    if (pageid === "wallet/addanxin.html") {
      type = "3";
    }
    rechargeReady(
      type,
      function () {
        isRealName() ? gotoAddmybindbankcard() : gotoFirstbindcard();
      },
      function () {
        gotoUploadIdCard("?from=1");
      },
      function () {
        window.location.href = window.phURL + "personal/evaluation.html";
      },
      function () {
        if (arguments.length >= 7) {
          var func = arguments[6];
          func();
        }
        window.location.href = phURL + pageid;
      }
    );
    return;
  }

  if (isRealName() && isHaveBankCardn()) {
    if (arguments.length >= 7) {
      var func = arguments[6];
      func();
    }
    window.location.href = phURL + pageid;
    return;
  }

  var defaultTip =
    arguments.length > 1 ? arguments[1] : "添加银行卡，立即享受收益！";
  confirm(defaultTip, "暂不", "立即添加", "", function () {
    isRealName() ? gotoAddmybindbankcard() : gotoFirstbindcard();
  });
}

/**
 * 判断用户是否是合格投资者
 * @param pageid
 */
function isRecognizedAsQualifiedInvestors(pageid) {
  if (isRealName()) {
    var cardLen = loadValue("cardLen", false);
    if (!isNull(cardLen) && Number(cardLen) > 0) {
      window.location.href = phURL + "fund/topFund.html";
    } else {
      if (arguments.length > 1) {
        $("#tipMessage").html(arguments[1]);
        $("#btnValueTop").html(arguments[2]);
        $("#btnValueBottom").html(arguments[3]);
        $("#btnValueTop").attr("onclick", "gotoAddmybindbankcard()");
        $("#btnValueBottom").attr("onclick", arguments[5]);
        document.getElementById("promptBox").style.display = "";
      } else {
        gotoAddmybindbankcard();
      }
    }
  } else {
    if (arguments.length > 1) {
      $("#tipMessage").html(arguments[1]);
      $("#btnValueTop").html(arguments[2]);
      $("#btnValueBottom").html(arguments[3]);
      $("#btnValueTop").attr("onclick", "gotoFirstbindcard()");
      $("#btnValueBottom").attr("onclick", arguments[5]);
      document.getElementById("promptBox").style.display = "";
    } else {
      document.getElementById("unBindCard").style.display = "";
    }
  }
}

/**
 * 用户是否绑卡
 * @returns {boolean}
 */
function isHaveBankCardn() {
  var cardLen = 0;
  if (!isNull(loadValue("cardLen", false))) {
    cardLen = loadValue("cardLen", false);
  }
  return Number(cardLen) > 0;
}

/**
 * 用户是否上传身份证
 * @returns {boolean}
 */
function isUpIdCard() {
  var upIdCard = true;
  if (isNull(localStorage.upTransIdCard) || localStorage.upTransIdCard == "1") {
    upIdCard = false;
  }
  return upIdCard;
}

function balanceNotEnough(pageid, rechargeAmount) {
  window.location.href = phURL + pageid + "?rechargeAmount=" + rechargeAmount;
}

function isGoToEnchashment() {
  if (isRealName()) {
    window.location.href = phURL + "personal/evaluation.html";
  } else {
    document.getElementById("unBindCardtoEnchashment").style.display = "";
  }
}

/**
 * 获取当前日期前面7天的方法，主要用在走势图或者是钱包资产查询的时候
 * @param n
 * @returns {string|*}
 */
function getBeforeDate(n) {
  var d = new Date();
  var year = d.getFullYear();
  var mon = d.getMonth() + 1;
  var day = d.getDate();
  if (day <= n) {
    if (mon > 1) {
      mon = mon - 1;
    } else {
      year = year - 1;
      mon = 12;
    }
  }
  d.setDate(d.getDate() - n);
  year = d.getFullYear();
  mon = d.getMonth() + 1;
  day = d.getDate();
  s = year + (mon < 10 ? "0" + mon : mon) + (day < 10 ? "0" + day : day);
  return s;
}

/**
 * 密码键盘输入
 * @param ind
 * @param id
 * @returns {string|*}
 */
function enterPwd(ind, id) {
  var next = $("#" + id).val() + ind;
  if (next.length <= 6) {
    $("#" + id).val(next);
  }
}

/**
 * 密码键盘删除一位
 * @param id
 * @returns {string|*}
 */
function delPwdLast(id) {
  var hol = $("#" + id).val();
  if (hol.length > 0) {
    $("#" + id).val(hol.slice(0, hol.length - 1));
  }
}

/**
 * 将千分位数据转换为数字
 * @param num
 */
function toNumber(num) {
  if ((num + "").trim() == "") {
    return "";
  }
  num = num.replace(/,/gi, "");
  return num;
}

/**
 *年化收益率
 **/
function format_nhsyl(sevyie) {
  return (sevyie * 100).toFixed(4) + "%";
}

/**
 *  交易密码规则：6位数字；不能使等差或等比例数列：123456；不能使同一个数字：111111；不能是回文：123321；不能是开户证件号的连续6位；不能是手机号中的连续6位
 *
 *  @return true false
 */
function checkSetTransPwd(fieldId, idtfno, phone, password) {
  if (password.length != 6) {
    return false;
  }
  var i = 0;
  //不能使等差或等比例数列：123456
  var a = Number(password.substr(0, 1)) - Number(password.substr(1, 1));
  for (i = 1; i < password.length - 1; i++) {
    if (
      a !=
      Number(password.substr(i, 1)) - Number(password.substr(i + 1, 1))
    ) {
      break;
    }
  }
  if (i >= password.length - 1) {
    errShow(fieldId, "交易密码设置过于简单");
    return false;
  }
  //等比
  var a = Number(password.substr(0, 1)) / Number(password.substr(1, 1));
  for (i = 1; i < password.length - 2; i++) {
    if (
      a !=
      Number(password.substr(i, 1)) / Number(password.substr(i + 1, 1))
    ) {
      break;
    }
  }
  if (i >= password.length - 1) {
    errShow(fieldId, "交易密码设置过于简单");
    return false;
  }

  //不能使同一个数字：111111
  for (i = 0; i < password.length - 1; i++) {
    if (password.substr(i, 1) != password.substr(i + 1, 1)) {
      break;
    }
  }
  if (i >= password.length) {
    errShow(fieldId, "交易密码设置过于简单");
    return false;
  }

  //不能是回文：123321
  for (i = 0; i < password.length / 2; i++) {
    if (password.substr(i, 1) != password.substr(password.length - 1 - i, 1)) {
      break;
    }
  }
  if (i >= password.length - 3) {
    errShow(fieldId, "交易密码设置过于简单");
    return false;
  }

  //不能是开户证件号的连续6位；
  if (!isNull(idtfno)) {
    //TODO 修改交易密码的时候 拿不到客户的证件号，需要确认，修改交易密码时要不要校验这个
    if (idtfno.indexOf(password, 0) != -1) {
      errShow(fieldId, "交易密码设置不能是开户证件号的连续6位");
      return false;
    }
  }

  //不能是手机号中的连续6位
  if (!isNull(phone) && phone.indexOf(password, 0) != -1) {
    errShow(fieldId, "交易密码设置不能是注册手机号的连续6位");
    return false;
  }
  return true;
}

function hotRecommendMore(pageId) {
  window.location.href = phURL + pageId;
}

/**
 *设置底部推荐广告位
 */
function setHotRecommend() {
  var personalFundCode = getPersonalFundCode();
  if (isNull(personalFundCode)) {
    toApi("qrywalletfundcode.tml", function (data) {
      localStorage.personalFundCode = data.personalFundCode;
      setHotRecommend();
    });
    return;
  }

  //通过[产品推荐列表查询]接口获取热门产品推荐
  toApi("productrecommlist.tml?fundSubType=102", function (data) {
    var json = data;
    var risklv;
    if (isNull(localStorage.risklv)) {
      risklv = -2;
    } else {
      risklv = localStorage.risklv == "X" ? "-1" : localStorage.risklv;
    }
    if (json.respCode === "000000") {
      json.list = json.list.length > 2 ? json.list.slice(0, 2) : json.list;
      json.list = json.list.filter(function (item) {
        if (isNull(item.fundRiskLevelCode)) {
          return item;
        } else {
          if (Number(risklv) >= Number(item.fundRiskLevelCode)) {
            return item;
          }
        }
      });
      if (json.list.length > 1) {
        $("#hotProductsRecommend").show();
        $("#leftDetail").show();
        $("#rightDetail").show();
      } else if (json.list.length === 1) {
        $("#hotProductsRecommend").show();
        $("#leftDetail").show();
        $("#rightDetail").hide();
      } else {
        $("#hotProductsRecommend").hide();
        $("#leftDetail").hide();
        $("#rightDetail").hide();
      }
      for (var d = 0; d < json.list.length; d++) {
        var detailBtn = $("#toFundDetail" + d);
        if (json.list[d].fundCode !== personalFundCode) {
          detailBtn.addClass("pro-detail");
          detailBtn.attr(
            "href",
            phURL + "fund/fundDetail.html?fundCode=" + json.list[d].fundCode
          );
        }
        var keyList = json.list[d].keyGroup[0].keyList;
        var tt = 0;
        for (var i = 0, len = keyList.length; i < len; i++) {
          if (keyList[i].key_type === "01") {
            $("#fundName" + d).html(keyList[i].key_name); //主标题设置
            $("#keyMsg" + d).html(keyList[i].key_msg); //副标题设置
          } else if (keyList[i].key_type === "02") {
            var recommendBtn = $("#fundBu" + d);
            recommendBtn.html(keyList[i].key_name); //按钮设置

            switch (keyList[i].key_url) {
              case "F_recharge.tml":
                recommendBtn.attr(
                  "onclick",
                  'isHaveBankCard("wallet/recharge.html")'
                );
                break;
              case "F_fundPurchase.tml":
                recommendBtn.attr(
                  "onclick",
                  'fundBuy("' +
                    json.list[d].fundCode +
                    '", "' +
                    1 +
                    '", "' +
                    json.list[d].status +
                    '", "' +
                    0 +
                    '", "' +
                    json.list[d].fundSubType +
                    '", "' +
                    json.list[d].fundType +
                    '")'
                );
                break;
              case "F_addNewPlan.tml":
                recommendBtn.attr(
                  "onclick",
                  'fundInvestment("' +
                    json.list[d].fundCode +
                    '", "' +
                    json.list[d].fundName +
                    '", "null", "' +
                    json.list[d].fundType +
                    '")'
                );
                break;
              case "F_addNewSalaryPlan.tml":
                recommendBtn.attr(
                  "onclick",
                  'isHaveBankCard("wallet/addanxin.html")'
                );
                break;
              case "F_compliance.tml":
                recommendBtn.attr(
                  "href",
                  window.phURL +
                    "fund/verifyInvestor.html?fundCode=" +
                    json.list[d].fundCode
                );
                break;
              case "F_fundDetails.tml":
                recommendBtn.attr(
                  "href",
                  window.phURL +
                    "fund/fundDetail.html?fundCode=" +
                    json.list[d].fundCode
                );
                break;
              default:
                recommendBtn.attr("href", keyList[i].key_url);
                break;
            }
          } else if (keyList[i].key_type === "03") {
            $("#keyValueName" + tt + d).text(keyList[i].key_name);
            if (
              keyList[i].key_subtype === "001" ||
              keyList[i].key_subtype === "003"
            ) {
              $("#keyValue" + tt + d).text(
                Number(keyList[i].key_value).toFixed(4)
              );
            } else if (keyList[i].key_subtype === "002") {
              $("#keyValue" + tt + d).text(
                Number(keyList[i].key_value).toFixed(4) + "%"
              );
            } else if (
              keyList[i].key_subtype === "004" ||
              keyList[i].key_subtype === "006" ||
              keyList[i].key_subtype === "007" ||
              keyList[i].key_subtype === "011" ||
              keyList[i].key_subtype === "012" ||
              keyList[i].key_subtype === "013" ||
              keyList[i].key_subtype === "008" ||
              keyList[i].key_subtype === "009" ||
              keyList[i].key_subtype === "010"
            ) {
              $("#keyValue" + tt + d).text(
                Number(keyList[i].key_value).toFixed(2) + "%"
              );
            } else if (keyList[i].key_subtype === "000") {
              $("#keyValue" + tt + d).text(keyList[i].key_value);
            } else if (keyList[i].key_subtype === "005") {
              $("#keyValue" + tt + d).text(keyList[i].key_value + "元");
            } else {
              $("#keyValue" + tt + d).text(
                Number(keyList[i].key_value).toFixed(2)
              );
            }
            tt++;
          }
        }
      }
    }
  });
}

/**
 * 键盘只允许输入数字
 * @param obj
 */
function clearNotNum(obj) {
  if (obj.value === ".") {
    obj.value = 0 + ".";
  }
  obj.value = obj.value.replace(/[^\d.]/g, ""); //清除"数字"和"."以外的字符
  obj.value = obj.value.replace(/^\./g, ""); //验证第一个字符是数字而不是
  obj.value = obj.value.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
  obj.value = obj.value
    .replace(".", "$#$")
    .replace(/\./g, "")
    .replace("$#$", ".");
  obj.value = obj.value.replace(/^(\-)*(\d+)\.(\d\d).*$/, "$1$2.$3"); //只能输入两个小数
}

/**
 * 根据银行卡的cardType获取中文汉字，如cardType=0  中文对应为贷记卡
 * @param cardTypeNum
 * @returns {cardType|*}
 */
function getCardType(cardTypeNum) {
  var cardType = "";
  if (!isNull(cardTypeNum)) {
    if (cardTypeNum == "0") {
      cardType = "贷记卡";
    } else if (cardTypeNum == "1") {
      cardType = "借记卡";
    } else if (cardTypeNum == "2") {
      cardType = "准贷记卡";
    } else if (cardTypeNum == "3") {
      cardType = "预付费卡";
    }
  } else {
    return cardType;
  }
  return cardType;
}

/**
 * 获取行为分析sessno
 * @returns {number}
 */
function getActionSessno() {
  var actionsessno = localStorage.actionsessno;
  actionsessno = isNull(actionsessno) ? 1 : Number(actionsessno) + 1;
  localStorage.actionsessno = actionsessno;
  return actionsessno;
}

$(function () {
  behavioralStatistics(pageName());

  var onlineCustomerServiceLink = localStorage.onlineCustomerServiceLink;

  // 更新智齿客服链接地址
  if (
    onlineCustomerServiceLink !== null &&
    onlineCustomerServiceLink !== undefined
  ) {
    $("#onlineCustomerService").attr("href", onlineCustomerServiceLink);
  }
});

/**
 *行为统计方法
 * @param pageId  页面id
 * @param transCode 交易码
 * @param errCode 错误码
 * @param errMsg 错误提示信息
 * @param fundCode 基金代码
 * @param busiSerial  交易流水号
 */
function behavioralStatistics(
  pageId,
  transCode,
  errCode,
  errMsg,
  fundCode,
  busiSerial
) {
  var list = {
    actionsessno: getActionSessno(),
    pageid: pageId.toString(),
    phone: isNull(localStorage.g_mobile) ? "" : localStorage.g_mobile,
    appname: "个人网上交易",
    transcode: isNull(transCode) ? "" : transCode,
    errCode: isNull(errCode) ? "" : errCode,
    errmsg: isNull(errMsg) ? "" : errMsg,
    fundCode: isNull(fundCode) ? "" : fundCode,
    busiSerial: isNull(busiSerial) ? "" : busiSerial,
  };
  list = encodeURI("[" + JSON.stringify(list) + "]");
  var url = "datecollectpc.tml?list=" + list;
  data = {
    token: getToken(),
    deviceId: "shebeihaoshipc",
    sign: "971fa2ca1db70fa637aefcf330543157",
  };
  $.ajax({
    url: apiURL + url,
    data: data,
    type: "post",
    timeout: 30000, //超时时间设置，单位毫秒
    headers: { branchCode: "01", "h-version": "1.0.0" }, //这里设置header

    success: function (data) {
      log("behavioralStatistics success~");
    },
    error: function (error) {
      log("behavioralStatistics fail~");
    },
  });
}

/**
 * 处理Placeholder，fit所有浏览器
 */
function isFitToPlaceholderOnEE() {
  // 如果不支持placeholder，用jQuery来完成
  if (!placeholderSupport()) {
    // 遍历所有input对象, 除了密码框
    $("input")
      .not("input[type='password']")
      .each(function () {
        var self = $(this);
        var val = self.attr("placeholder");
        if (!isNull(val)) {
          setAttrPlaceholderOnEE(self, val, "1");
        }
      });

    /* 对password框的特殊处理
     * 1.创建一个label框
     * 2.获取焦点和失去焦点的时候切换
     */
    $('input[type="password"]').each(function () {
      var pwdField = $(this);
      var pwdVal = isNull(pwdField.attr("placeholder"))
        ? ""
        : pwdField.attr("placeholder");
      if (!isNull(pwdVal)) {
        setAttrPlaceholderOnEE(pwdField, pwdVal, "1");
      }
    });
  } else {
    //处理部分浏览器虽然支持，但显示样式不理想问题
    $("input").each(function () {
      var self = $(this);
      var val = self.attr("placeholder");
      var color = self.css("color");
      if (isNull(self.val() || self.val() == val)) {
        self.css("color", "#a9a9a9");
      }
      var $input = self;
      $input
        .focus(function () {
          // if ($input.val() == val) {
          //     $(this).attr({value:""});
          // }
        })
        .blur(function () {
          if ($input.val() == "") {
            $(this).css("color", "#a9a9a9");
          }
        })
        .bind("input propertychange", function () {
          if (!isNull($input.val()) && $input.val() != val) {
            if (isNull(color)) {
              $input.css("color", "#000000");
            } else {
              $input.css("color", color);
            }
          } else {
            $input.css("color", "#a9a9a9");
          }
        });
    });
  }
}

/**
 *设置placeholder，支持通过js动态设置，以及加载时读取placeholder设置
 * @param idOrObj
 * @param val
 * @param flag  1-预加载获取;undefined-js动态设置
 */
function setAttrPlaceholderOnEE(idOrObj, val, flag) {
  if (!placeholderSupport()) {
    var $input;
    if (isNull(flag) || flag != "1") {
      //此时idOrObj=id
      $input = $("#" + idOrObj);
    } else {
      //此时idOrObj=obj
      $input = idOrObj;
    }
    //清空上一次
    if ($input.val() == $input.attr("placeholder")) {
      $input.val("");
      $input.attr({ value: "" });
    }
    var inputType = $input.attr("type");
    if (inputType == "password") {
      var pwdField = $input;
      var pwdVal = val;
      var pwdId = pwdField.attr("id");
      if (!pwdId) {
        pwdId = "placeholder_" + new Date().getTime();
        pwdField.attr("id", pwdId);
      }
      var height = pwdField.height() + "px";
      var marginTop = pwdField.css("marginTop");
      var marginLeft = pwdField.css("marginLeft");
      var marginRight = pwdField.css("marginRight");
      var marginBottom = pwdField.css("marginBottom");

      var paddingTop = pwdField.css("paddingTop");
      var paddingLeft = pwdField.css("paddingLeft");
      var paddingRight = pwdField.css("paddingRight");
      var paddingBottom = pwdField.css("paddingBottom");
      pwdField.before(
        '<label id="' +
          pwdId +
          'placeholderSupportLabel" for="' +
          pwdId +
          'placeholderSupportLabel">' +
          pwdVal +
          "</label>"
      );
      var pwdPlaceholder = $("#" + pwdId + "placeholderSupportLabel");
      if (isNull(pwdField.val())) {
        pwdPlaceholder.show();
      }
      pwdPlaceholder.css("position", "absolute");
      pwdPlaceholder.css("height", height);
      pwdPlaceholder.css("line-height", height);
      pwdPlaceholder.css("margin-top", marginTop);
      pwdPlaceholder.css("margin-left", marginLeft);
      pwdPlaceholder.css("margin-bottom", marginBottom);
      pwdPlaceholder.css("margin-right", marginRight);
      pwdPlaceholder.css("padding-top", paddingTop);
      pwdPlaceholder.css("padding-left", paddingLeft);
      pwdPlaceholder.css("padding-bottom", paddingBottom);
      pwdPlaceholder.css("padding-right", paddingRight);
      pwdPlaceholder.css("color", "#a9a9a9");
      pwdPlaceholder.css("cursor", "text");

      //记录一下此次设置的placeholder值
      if (pwdVal != pwdField.attr("placeholder")) {
        pwdField.attr("placeholder", pwdVal);
      }

      pwdField
        .focus(function () {
          pwdPlaceholder.hide();
        })
        .blur(function () {
          if (isNull(pwdField.val())) {
            pwdPlaceholder.show();
          } else {
            pwdPlaceholder.hide();
          }
        });
      pwdPlaceholder.click(function () {
        pwdField.focus();
        pwdField.click();
      });
    } else {
      var color = $input.css("color");
      var value = val;

      //记录一下此次设置的placeholder值
      $input.attr("placeholder", value);

      if (isNull($input.val()) || $input.val() == value) {
        $input.attr({ value: value });
        $input.css("color", "#a9a9a9");
      }
      $input
        .focus(function () {
          if ($input.val() == value) {
            $input.attr({ value: "" });
          }
        })
        .blur(function () {
          //当input值为空，且要显示的placeholder值是最新的
          if ($input.val() == "" && value == $input.attr("placeholder")) {
            $input.attr({ value: value });
            $input.css("color", "#a9a9a9");
          }
        })
        .bind("input propertychange", function () {
          if (
            !isNull($input.val()) &&
            $input.val() != $input.attr("placeholder")
          ) {
            // if(isNull(color)){
            //     $input.css('color','#000000');
            // }else{
            //     if(color=="#a9a9a9")color='#000000';
            //     $input.css('color',color);
            // }
            $input.css("color", "#000000");
          } else {
            $input.css("color", "#a9a9a9");
          }
        });
    }
  } else {
    $("#" + idOrObj).attr("placeholder", val);
  }
}

/**
 * 判断浏览器是否兼容placeholder属性
 */
function isFitToPlaceholder() {
  if (!placeholderSupport()) {
    //不支持
    $("input[type='text']")
      .focus(function () {
        var input = $(this);
        if (input.val() == input.attr("placeholder")) {
          input.val("");
          input.removeClass("placeholder");
        }
      })
      .blur(function () {
        var input = $(this);
        if (input.val() == "" || input.val() == input.attr("placeholder")) {
          input.addClass("placeholder");
          input.val(input.attr("placeholder"));
        }
      })
      .blur();
    $("input[type='number']")
      .focus(function () {
        var input = $(this);
        if (input.val() == input.attr("placeholder")) {
          input.val("");
          input.removeClass("placeholder");
        }
      })
      .blur(function () {
        var input = $(this);
        if (input.val() == "" || input.val() == input.attr("placeholder")) {
          input.addClass("placeholder");
          input.val(input.attr("placeholder"));
        }
      })
      .blur();
    //        $("input[type='password']").each(function(){
    //            var pwdVal = $(this).attr('placeholder');
    //            var pwdId = $(this).attr('id');
    //            var input2 = $(this).after('<input type="text" id="paySc1" class="d-input transpwd_keyboard" value="'+$(this).attr("placeholder")+'" onclick="openKeypad("paySc")" />');
    //            $("#paySc1").show();
    //            $(this).hide();
    //            $(this).focus(function(){
    //                $('#paySc1').hide();
    //                $(this).show();
    //                $(this).focus();
    //            });
    //            $(this).blur(function(){
    //               if($(this).val()==''){
    //                   $('#paySc1').show();
    //                   $(this).hide();
    //               }
    //            });
    //        });
  } else {
    //支持
  }
}

/**
 * 判断浏览器是否支持placeholder
 * @returns {boolean}
 */
function placeholderSupport() {
  return "placeholder" in document.createElement("input");
}

function getRiskDesc(riskLevel) {
  if (riskLevel === "0" || riskLevel === 0) {
    return "低风险";
  } else if (riskLevel === "1" || riskLevel === 1) {
    return "中低风险";
  } else if (riskLevel === "2" || riskLevel === 2) {
    return "中风险";
  } else if (riskLevel === "3" || riskLevel === 3) {
    return "中高风险";
  } else {
    return "高风险";
  }
}

function judgementRespCode(respcode) {
  if (!isNull(respcode)) {
    if (
      respcode != "100000" &&
      respcode != "100006" &&
      respcode != "x`" &&
      respcode != "BFF001" &&
      respcode != "100022" &&
      respcode != "500000" &&
      respcode != "100031"
    ) {
      return true;
    } else {
      return false;
    }
  }
}

//计算两个日期之间的时间差
function daysBetween(DateOne, DateTwo) {
  if (isNull(DateTwo) || DateTwo.length < 10) {
    return -1;
  }
  var OneMonth = DateOne.substring(5, DateOne.lastIndexOf("-"));
  var OneDay = DateOne.substring(DateOne.length, DateOne.lastIndexOf("-") + 1);
  var OneYear = DateOne.substring(0, DateOne.indexOf("-"));

  var TwoMonth = DateTwo.substring(5, DateTwo.lastIndexOf("-"));
  var TwoDay = DateTwo.substring(DateTwo.length, DateTwo.lastIndexOf("-") + 1);
  var TwoYear = DateTwo.substring(0, DateTwo.indexOf("-"));

  var cha =
    (Date.parse(OneMonth + "/" + OneDay + "/" + OneYear) -
      Date.parse(TwoMonth + "/" + TwoDay + "/" + TwoYear)) /
    86400000;
  return cha;
}

/**
 * 收益格式化（有+号）
 * @param num 数值
 * @param len 保留小数长度
 * @returns {{value: string, color: string}}
 */
function numberFormatter(num, len) {
  len = isNull(len) ? 2 : len;
  var result = {
    value: "-",
    color: "cello",
  };
  if (!isNull(num)) {
    num = Number(num);
    if (num > 0) {
      result.value = "+" + toThousands(num, len);
      result.color = "red";
    } else if (num < 0) {
      result.value = toThousands(num, len);
      result.color = "green";
    } else {
      result.value = toThousands(num, len);
    }
  }
  return result;
}

/**
 * 收益格式化方法
 * @param num
 * @param len
 * @returns {{value: string, color: string}}
 */
function numberFormatterNoPlus(num, len) {
  len = isNull(len) ? 2 : len;
  var result = {
    value: "-",
    color: "cello",
  };
  if (!isNull(num)) {
    num = Number(num);
    if (num > 0) {
      result.color = "red";
    }
    if (num < 0) {
      result.color = "green";
    }
    result.value = toThousands(num, len);
  }
  return result;
}

/**
 * 基金收益率格式化
 * @param num
 * @param len
 * @returns {{value: string, color: string}}
 */
function numberFormatterWithSign(num, len) {
  len = isNull(len) ? 2 : len;
  var result = {
    value: "-",
    color: "cello",
  };
  if (!isNull(num)) {
    num = Number(num);
    if (num > 0) {
      result.value = "+" + toThousands(num, len) + "%";
      result.color = "red";
    } else if (num < 0) {
      result.value = toThousands(num, len) + "%";
      result.color = "green";
    } else {
      result.value = toThousands(num, len) + "%";
    }
  }
  return result;
}

/**
 * 给id赋值
 * @param id
 * @param num
 * @param len
 */
function setNumberFormatter(id, num, len, symbol) {
  var formatter = numberFormatter(num, len);
  if (!isNull(symbol)) {
    $("#" + id).html(formatter.value + symbol);
  } else {
    $("#" + id).html(formatter.value);
  }

  $("#" + id).addClass(formatter.color);
}

function removeNone(id) {
  document.getElementById(id).style.display = "";
}

function addNone(id) {
  document.getElementById(id).style.display = "none";
}

//获取当前页面名称
function pageName() {
  var a = location.href;
  var b = a.split("/");
  var c = b
    .slice(b.length - 1, b.length)
    .toString(String)
    .split(".");
  return c.slice(0, 1);
}

/**
 * 给input输入添加空格,在textChange的每输入四位后补一个空格
 * value 需要格式化的数值
 * diff textChange变化的数量,1为输入一个字符,-1为删除一个字符
 * return 格式化后的数值
 */
function addSpace(value, diff) {
  value = value + "";
  var result = value;
  var value01 = value.replace(/\s+/g, ""); //去除字符中所有的空格
  var len = value01.length;

  if (isNull(diff) || Number(diff) > 0) {
    var spaceCount = Math.floor(len / 4);
    if (len % 4 == 0) {
      spaceCount--;
    }
    result = "";
    for (var i = 0; i < spaceCount; i++) {
      result += value01.substring(i * 4, i * 4 + 4) + " ";
    }
    result += value01.substring(4 * spaceCount);
  } else {
    if (len % 4 == 0) {
      result = result.substring(0, result.length - 1);
    }
  }
  return result;
}

/**
 * 提示语
 * @param msg
 * @returns null
 */
function alertMsg(msg) {
  var html_str = '<div class="tipCover">' + "<p>" + msg + "</p >" + "</div>";
  $("body").append(html_str);
  $(".tipCover").css("display", "block");
  window.setTimeout(function () {
    //    	$('.tipCover').css('display','none');
    $(".tipCover").remove();
  }, 2000);
}

/**
 * 弹出框，带一个"我知道了"按钮
 * @param msg: 需要展示的信息
 */
function alertIknowMsg(msg, backFlag) {
  var alhtml =
    '<div class="tipMsgCover" id="popalert">' +
    '<div class="pup-box">' +
    '<img class="imgtips"  src="' +
    phURL +
    'images/tips/reg-tips-gt.jpg">' +
    '<div class="tips-font"><text>' +
    msg +
    "</text></div>" +
    '<p><a href="javascript:void(0)" class="btn-blue" onclick="closeAlert(' +
    backFlag +
    ')">我知道了</a></p>' +
    "</div>" +
    "</div>";
  $("body").append(alhtml);
}

/**
 * 弹出框，带一个"我知道了"按钮,引导客户去别的渠道
 * @param msg: 需要展示的信息
 */
function alertIknowMsgQrCode(msg, backFlag) {
  var alhtml =
    '<div class="tipMsgCover" id="popalert">' +
    '<div class="pup-box" style="height: 420px; width: 500px">' +
    '<img class="imgtipsQrCode"  src="' +
    phURL +
    'images/tips/qrCode.png">' +
    '<div class="tips-font"><text>' +
    msg +
    "</text></div>" +
    '<p><a href="javascript:void(0)" class="btn-blue" onclick="backIndex(' +
    backFlag +
    ')">我知道了</a></p>' +
    "</div>" +
    "</div>";
  $("body").append(alhtml);
}

function backIndex(backFlag) {
  if (isNull(backFlag)) {
    $("#popalert").remove();
  } else {
    window.location.href = phURL + "/personal/personal.html";
  }
}

function closeAlert(backFlag) {
  if (isNull(backFlag)) {
    $("#popalert").remove();
  } else {
    window.history.go(-1);
  }
}

/**
 * 根据返回respType展示不同弹窗
 * @param type ：respType
 * @param msg：提示语
 * @returns null
 */
function showErrorMsg(msg, type) {
  $.fn.jqLoading("destroy");
  isLoadingCount = 0;
  if (type == "1" || isNull(type) || type == 1) {
    // alert信息
    alert(msg);
  } else if (type == "2") {
    // 弹窗信息
    alertIknowMsg(msg);
  }
}

/**
 * 预约日格式化
 * @param startDate
 * @param endDate
 * @returns {string}
 */
function toBookingDateFormatter(startDate, endDate) {
  return isNull(startDate) || isNull(endDate)
    ? "-"
    : (function (startDate, endDate) {
        var m1 = startDate.substr(4, 2);
        var d1 = startDate.substr(6, 2);
        var m2 = endDate.substr(4, 2);
        var d2 = endDate.substr(6, 2);
        return (
          Number(m1) +
          "月" +
          Number(d1) +
          "日" +
          "~" +
          Number(m2) +
          "月" +
          Number(d2) +
          "日"
        );
      })(startDate, endDate);
}

/**
 * 位数太多字体缩小
 * @param amouts:金额字符串长度
 * @param len : 比较长度
 * @param tid : 需要缩小的id
 * @param fonts : 改变后的fontsize
 * @returns {string}
 */
function smallFontSize(amouts, len, tid, fonts) {
  if (amouts.length > len) {
    document.getElementById(tid).style.fontSize = fonts;
  }
}

/**
 * 当有安薪计划时，进入定投列表页面。当没有安薪计划时进入安薪计划介绍页面。
 */
function isHaveAnxin() {
  var salaryPlanNum = localStorage.newSalaryPlanSize;
  if (!isNull(salaryPlanNum) && Number(salaryPlanNum) > 0) {
    window.location.href = phURL + "wallet/anxin.html";
  } else {
    window.location.href = phURL + "fund/anxinAd.html";
  }
}

/**
 * 当有定投记录时，进入定投列表页面。当没有定投时进入定投介绍页面。
 */
function isHaveFundinvestment() {
  if (parseInt($("#newInvestMentSize").text()) > 0) {
    window.location.href = phURL + "fund/fundinvestment.html";
  } else {
    window.location.href = phURL + "fund/fundinvesterAd.html";
  }
}

/**
 * 设置未实名或为绑卡时我的持仓页面展示
 * @param tip1
 * @param tip2
 * @param href
 */
function setUnBindCardInfo(tip1, tip2, href) {
  $("#unbindCard").removeClass("none");
  $("#tipMsg").html(tip1);
  $("#tipBtn").html(tip2);
  $("#tipBtn").attr("onclick", href);
}

/**
 * 前往合格投资者页面
 * @param id
 */
function tovVerifyInvestor(id) {
  localStorage.verifyInvestorPage = id;
  window.location.href = window.phURL + "fund/verifyInvestor.html";
}

$(function () {
  var array = [
    "verifyInvestor",
    "topFundDetail",
    "topFund",
    "specialAppointmentMsg",
    "specialAppointmentPsd",
  ];
  var currentPage = pageName().toString();
  for (var i = 0; i < array.length; i++) {
    if (currentPage == array[i]) {
      setLeftNavigationBar();
      break;
    }
  }
});

/**
 * 设置左侧导航栏展开状态
 */
function setLeftNavigationBar() {
  var lastPage = localStorage.verifyInvestorPage;
  if (lastPage === "hignEndFinancing") {
    $("#financingProduct").addClass("open");
    $("#financingProductLi").addClass("active");
    document.getElementById("financingProductUl").style.display = "block";
  }

  if (lastPage === "hignEndFinancingBook") {
    $("#financingTrading").addClass("open");
    $("#financingTradingLi").addClass("active");
    document.getElementById("financingTradingUl").style.display = "block";
  }
}

/**
 * 判断专户基金是否在开放期
 * @param beginTime
 * @param endTime
 * @param nowTime
 * @returns {boolean}
 */
function isInReservationPeriod(beginTime, endTime, nowTime) {
  if (!isNull(beginTime) && !isNull(endTime)) {
    return (
      Number(nowTime) > Number(beginTime) && Number(nowTime) < Number(endTime)
    );
  }

  if (!isNull(beginTime) && isNull(endTime)) {
    return Number(nowTime) > Number(beginTime);
  }

  if (isNull(beginTime) && !isNull(endTime)) {
    return Number(nowTime) < Number(endTime);
  }

  return false;
}

/**
 * 获取浏览器类型
 * @returns {*}
 * @constructor
 */
function BrowserType() {
  var userAgent = navigator.userAgent; //取得浏览器的userAgent字符串
  var isOpera = userAgent.indexOf("Opera") > -1; //判断是否Opera浏览器
  var isIE =
    userAgent.indexOf("compatible") > -1 &&
    userAgent.indexOf("MSIE") > -1 &&
    !isOpera; //判断是否IE浏览器
  var isEdge =
    (userAgent.indexOf("Windows NT 6.1; Trident/7.0;") > -1 ||
      userAgent.indexOf("Windows NT 6.1; WOW64; Trident/7.0;")) &&
    !isIE; //判断是否IE的Edge浏览器
  var isFF = userAgent.indexOf("Firefox") > -1; //判断是否Firefox浏览器
  var isSafari =
    userAgent.indexOf("Safari") > -1 && userAgent.indexOf("Chrome") == -1; //判断是否Safari浏览器
  var isChrome =
    userAgent.indexOf("Chrome") > -1 && userAgent.indexOf("Safari") > -1; //判断Chrome浏览器

  if (isIE) {
    var reIE = new RegExp("MSIE (\\d+\\.\\d+);");
    reIE.test(userAgent);
    var fIEVersion = parseFloat(RegExp["$1"]);
    if (fIEVersion == 7) {
      return "IE7";
    } else if (fIEVersion == 8) {
      return "IE8";
    } else if (fIEVersion == 9) {
      return "IE9";
    } else if (fIEVersion == 10) {
      return "IE10";
    } else if (fIEVersion == 11) {
      return "IE11";
    } else {
      return "0";
    } //IE版本过低
  } //isIE end

  if (isFF) {
    return "FF";
  }
  if (isOpera) {
    return "Opera";
  }
  if (isSafari) {
    return "Safari";
  }
  if (isChrome) {
    return "Chrome";
  }
  if (isEdge) {
    return "Edge";
  }
}

var escapeCharacters = {
  "<": "&#60;",
  ">": "&#62;",
  '"': "&#34;",
  "'": "&#39;",
  "&": "&#38;",
};

/**
 * 特殊字符转义
 * @param content
 * @returns {string}
 */
function getEscapeString(content) {
  return content.toString().replace(/&(?![\w#]+;)|[<>"']/g, function (c) {
    return escapeCharacters[c];
  });
}

/**
 * 蓝色按钮可点击
 * @param id
 * @param clickEvent
 */
function blueBtnAvailable(id, clickEvent) {
  var btn = $("#" + id);
  btn.removeClass("btn-ash");
  btn.addClass("btn-blue");
  btn.attr("onclick", clickEvent);
}

/**
 * 蓝色边框按钮可点击
 * @param id
 * @param clickEvent
 */
function blueBoxBtnAvailable(id, clickEvent) {
  var btn = $("#" + id);
  btn.removeClass("btn-gray");
  btn.addClass("btn-blue-k");
  btn.attr("onclick", clickEvent);
}

/**
 * 基金状态
 * @type {{0: string, 1: string, 2: string, 3: string, 4: string, 5: string, 6: string, 7: string, 8: string, 9: string, a: string, b: string, c: string, d: string}}
 */
var fundStateDesc = {
  0: "开放申/赎",
  1: "开放认购",
  2: "未开放申/赎",
  3: "未开放申/赎",
  4: "停止交易",
  5: "停止申购",
  6: "停止赎回",
  7: "权益登记",
  8: "红利发放",
  9: "未开放申/赎",
  a: "未开放申/赎",
  b: "预约认购",
  c: "预约申购",
  d: "停止赎回",
};

/**
 * 获取基金状态描述
 * @param onShelf 是否上下架
 * @param fundState 基金状态
 * @param defaultDesc 默认描述
 * @returns {*}
 */
function getFundStateDesc(onShelf, fundState, defaultDesc) {
  onShelf += "";
  return onShelf !== "1"
    ? defaultDesc
    : fundStateDesc[fundState] || defaultDesc;
}

//日历输入框disabled状态切换-静态页面复制过来的
function setText(obj, id) {
  if (obj.checked == false) {
    document.getElementById(id).disabled = false;
    document.getElementById(id).value = "";
  } else {
    document.getElementById(id).disabled = true;
    document.getElementById(id).value = "长期有效";
  }
}

//鼠标触发弹层事件，针对登录-银行卡验证.html页面
$(function () {
  $(".icon-tips").hover(function () {
    $(this).toggleClass("on").siblings(".icon-tips").removeClass("on");
  });

  $(".icon-ask").hover(function () {
    $(this).parents(".date li").toggleClass("on").siblings().removeClass("on");
  });

  $(".radio-on").on("click", function () {
    $(this).addClass("on").siblings().removeClass("on");
  });
});

/**
 * 带两个按钮的弹出对话框
 * @param question: 对话框提示内容1
 * @param cancelValue:    取消按钮的值
 * @param confirmValue: 确认按钮的值
 * @param cancelFun: 点击取消按钮需要执行的方法
 * @param confirmFun: 点击确认按钮需要执行的方法
 */
function confirm(question, cancelValue, confirmValue, cancelFun, confirmFun) {
  var cancelBtn = $("#btnValueBottom");
  var confirmBtn = $("#btnValueTop");

  $("#tipMessage").html(question);
  confirmBtn.html(confirmValue);
  cancelBtn.html(cancelValue);
  confirmBtn.unbind("click");
  confirmBtn.attr("onclick", "").click(confirmFun);
  cancelBtn.unbind("click");
  cancelBtn.attr("onclick", "").click(cancelFun);

  document.getElementById("promptBox").style.display = "";
}

/**
 * 带两个按钮的弹出对话框
 * @param question: 对话框提示内容1
 * @param cancelValue:    取消按钮的值
 * @param confirmValue: 确认按钮的值
 * @param cancelFun: 点击取消按钮需要执行的方法
 * @param confirmFun: 点击确认按钮需要执行的方法
 */
function confirm2(question, cancelValue, confirmValue, cancelFun, confirmFun) {
  var cancelBtn = $("#btnValueBottom2");
  var confirmBtn = $("#btnValueTop2");

  $("#tipMessage2").html(question);
  confirmBtn.html(confirmValue);
  cancelBtn.html(cancelValue);
  confirmBtn.unbind("click");
  confirmBtn.attr("onclick", "").click(confirmFun);
  cancelBtn.unbind("click");
  cancelBtn.attr("onclick", "").click(cancelFun);

  document.getElementById("promptBox2").style.display = "";
}

/**
 * 带两个按钮的弹出对话框
 * @param question: 对话框提示内容1
 * @param cancelValue:    取消按钮的值
 * @param confirmValue: 确认按钮的值
 * @param cancelFun: 点击取消按钮需要执行的方法
 * @param confirmFun: 点击确认按钮需要执行的方法
 */
function confirm3(question, cancelValue, confirmValue, cancelFun, confirmFun) {
  var cancelBtn = $("#btnValueBottom3");
  var confirmBtn = $("#btnValueTop3");

  $("#tipMessage3").html(question);
  confirmBtn.html(confirmValue);
  cancelBtn.html(cancelValue);
  confirmBtn.unbind("click");
  confirmBtn.attr("onclick", "").click(confirmFun);
  cancelBtn.unbind("click");
  cancelBtn.attr("onclick", "").click(cancelFun);

  document.getElementById("promptBox3").style.display = "";
}

var interval = null;

function riskHighTip(fundCode) {
  if (!isNull(interval)) {
    window.clearInterval(interval);
    interval = null;
  }

  var timer = 10;
  var confirmBtn = $("#btn1");
  confirmBtn.addClass("btn-ash");
  confirmBtn.removeClass("btn-blue");
  confirmBtn.html("确认购买(" + timer + "s)");
  confirmBtn.attr("disabled", true);
  var riskLevelMsg = localStorage.riskLevelDesc;
  var tipOne =
    '您的风险承受能力为<i class="red" style="font-size: 14px">' +
    riskLevelMsg +
    '</i>，所购买的产品风险类别为<i class="red" style="font-size: 14px">' +
    "高风险" +
    "</i>，对此您已充分知悉并确认";
  $("#riskHighSpan2").html(tipOne);
  $('input[type="checkbox"]').each(function () {
    $(this).prop("checked", false);
  });
  document.getElementById("riskHighTip").style.display = "";
  timer--;
  interval = window.setInterval(function () {
    timer > 0
      ? (function () {
          $("#btn1").html("确认购买(" + timer + "s)");
          timer--;
        })()
      : (function () {
          confirmBtn.html("确认购买");
          window.clearInterval(interval);
          interval = null;
          confirmBtn.removeClass("btn-ash");
          confirmBtn.addClass("btn-blue");
          confirmBtn.attr("disabled", false);
          confirmBtn.attr("onclick", 'confirmBuy("' + fundCode + '")');
        })();
  }, 1000);
}

/**
 * 高风险揭示书点击确认购买按钮
 */
function confirmBuy(fundCode) {
  var isAgree = true;
  $("input:checkbox[name='confirm']").each(function () {
    if (!$(this).prop("checked")) {
      alert("请勾选风险揭示内容");
      isAgree = false;
      return false;
    }
  });

  if (!isAgree) {
    return;
  }

  toApi_toAc(
    "S15Q087",
    { fundCode: fundCode },
    function (data) {
      getSummary(fundCode, data.fundType);
    },
    "",
    "",
    function (err) {
      getSummary(fundCode);
    }
  );
}

function getSummary(fundCode, fundType) {
  toApi_toAcCg(
    "S15Q146",
    { type: 0, codes: [fundCode] },
    function (data) {
      if (!isNull(data) && data.hasSummary == "1") {
        if (
          !isNull(data) &&
          !isNull(data.data) &&
          data.data.length > 0 &&
          !isNull(data.data[0].summary)
        ) {
          // var url = data.data[0].summary.replace("http://", "//");
          var arr = data.data.map(function (val, index) {
            return {
              eq: index,
              title: val.agreementName,
              src: val.summary.replace("http://", "//"),
              hasRead: false,
            };
          });
          fundSummaryArr = data.data.map(function (val) {
            return {
              fundCode: fundCode,
              productInfoId: val.id,
              transCode: "020", // 认申购020，定投039，转换036
              ptfCode: 0,
            };
          });
          showOutLine(arr);
          saveValue("summaryID", data.data[0].id, false);
          saveValue("outLineFundCode", fundCode, false);
        } else {
          gotoInputPassword();
        }
      } else {
        if (fundType != "04" && fundType != "08") {
          if (data && (!data.data || data.data.length === 0)) {
            alertMsg("该产品无资料概要，暂时无法交易，请联系客服");
            return;
          }

          // if (Object.keys(data.readedFundSummaries).length > 0) {
          //     gotoInputPassword();
          //     return;
          // }
        } else {
          gotoInputPassword();
        }
      }
      // if (!isNull(data) && fundType != '04') {
      //     if (!isNull(data) && !isNull(data.data) && data.data.length > 0 && !isNull(data.data[0].summary) && data.hasSummary == '1') {
      //         // var url = data.data[0].summary.replace("http://", "//");
      //         var arr= data.data.map(function(val,index){
      //             return {
      //                 eq:index,
      //                 title:val.agreementName,
      //                 src:val.summary.replace("http://", "//"),
      //                 hasRead:false
      //             }
      //         })
      //         fundSummaryArr = data.data.map(function(val){
      //             return {
      //                 fundCode:fundCode,
      //                 productInfoId:val.id,
      //                 transCode: "020", // 认申购020，定投039，转换036
      //                 ptfCode: 0

      //             }
      //         })
      //         showOutLine(arr);
      //         saveValue("summaryID", data.data[0].id, false);
      //         saveValue("outLineFundCode", fundCode, false);
      //     } else {
      //         gotoInputPassword();
      //     }
      // } else {
      //     if (fundType != '04') {
      //         if (data && (!data.data || data.data.length === 0)) {
      //             alertMsg('该产品无资料概要，暂时无法交易，请联系客服');
      //             return;
      //         }

      //         // if (Object.keys(data.readedFundSummaries).length > 0) {
      //         //     gotoInputPassword();
      //         //     return;
      //         // }
      //     } else {
      //         gotoInputPassword();
      //     }
      // }
    },
    "",
    "",
    function (err) {
      alertMsg("该产品无资料概要，暂时无法交易，请联系客服");
    }
  );
}

/**
 * 初始化账户相关数据
 * @param info
 */
function initControlState(info) {
  // 111145返回的是investorType，老接口返回的investortype
  if (isNull(info.cuinfo.investortype)) {
    info.cuinfo.investortype = info.cuinfo.investorType;
  }

  localStorage.upTransIdCard = info.upTransIdCard; //交易是否需要上传身份证 1 不需要 2 提示上传 3 必须上传
  localStorage.upRealNameIdCard = info.upRealNameIdCard; //实名认证是否需要上传身份证 1不需要 2需求
  localStorage.isauth = info.cuinfo.isauth; //是否实名 1未实名，2已实名
  localStorage.isDealWithNotMatch = info.cuinfo.isDealWithNotMatch; //风险错配开关 0开 1关
  localStorage.isNoNeedAddCustInfo = info.cuinfo.isNoNeedAddCustInfo; //  是否不需要完善客户信息的开关 1不校验，0校验
  localStorage.isAddInfoAtOpenAcct = info.cuinfo.isAddInfoAtOpenAcct; //  实名认证后校验信息完善开关   1校验，0不校验
  localStorage.isFullOfCustAnxInfo = info.cuinfo.isFullOfCustAnxInfo; //  客户信息是否完整             1完整，0不完整
  localStorage.isOverIdEfcDate = info.cuinfo.isOverIdEfcDate; //  证件有效期是否失效					1失效，0未失效
  localStorage.isRechangeAtNotRisklv = info.cuinfo.isRechangeAtNotRisklv; //  充值开关校验 			0校验，1不校验  0-需要风险测评    1-不需要风险测评可充值
  localStorage.isBuyFdWhenOverAgeLm = info.cuinfo.isBuyFdWhenOverAgeLm; //  70岁交易时风险等级校验开关	1校验，0不校验
  localStorage.isRechangeAtNotCustInfo = info.cuinfo.isRechangeAtNotCustInfo; //客户信息未完善是否可以充值 1可以 0不可以
  localStorage.risklv = info.cuinfo.risklv; //  公募风险等级
  localStorage.riskEvaluationState = isNull(info.cuinfo.riskEvaluationState)
    ? null
    : info.cuinfo.riskEvaluationState; //  公募基金风险等级状态 		1过期，2无效，0有效。
  localStorage.riskLevelDesc = info.cuinfo.riskLevelDesc;
  localStorage.risklv_pvof = info.cuinfo.risklv_pvof; //  私募风险等级
  localStorage.riskEvaluationState_pvof = isNull(
    info.cuinfo.riskEvaluationState_pvof
  )
    ? null
    : info.cuinfo.riskEvaluationState_pvof; //  私募基金风险等级状态 1过期，2无效，0有效。
  localStorage.riskLevelPvofDesc = info.cuinfo.riskLevelPvofDesc; //  私募风险等级描述
  localStorage.inVestorType = info.cuinfo.investortype; // 投资者类型   PT: 普通投资者   ZY: 专业投资者
  localStorage.age = info.cuinfo.age;
  localStorage.orgCustSpecies = info.cuinfo.orgCustSpecies; //最原始的客户类型
  localStorage.isOverIdFactConEfcDate = info.cuinfo.isOverIdFactConEfcDate; //实际控制人身份证是否过期 1 已过期
  localStorage.isOverIdFactBenEfcDate = info.cuinfo.isOverIdFactBenEfcDate; //实际受益人身份证是否过期 1 已过期
  localStorage.howOldeCanOpenAcct = info.cuinfo.howOldeCanOpenAcct; //howOldeCanOpenAcct   多少岁以上不允许开户  >=这个值   isOlderCanOpenAcct   是否允许开户  0：不允许  1允许
  localStorage.isOlderCanOpenAcct = info.cuinfo.isOlderCanOpenAcct; //是否允许开户  0：不允许  1允许

  var cuinfo = info.cuinfo;
  localStorage.idtftp = cuinfo.idtftp; // 证件类型
  localStorage.addCertPic = cuinfo.addCertPic; // 证件类型
  localStorage.notIDInfo = JSON.stringify(cuinfo.notIDInfo || {}); // 证件类型
}

/**
 * 实名认证是否需要上传身份证
 * @returns {boolean}
 */
function isupRealNameIdCard() {
  return localStorage.upRealNameIdCard === "1";
}

/**
 * 交易是否需要上传身份证
 * @returns {boolean}
 */
function isupTransIdCard() {
  return localStorage.upTransIdCard === "3";
}

/**
 * 交易是否需要上传身份证
 * @returns {boolean}
 */
function isupTransIdCard3() {
  return localStorage.upTransIdCard === "2";
}

/**
 * 是否不需要完善客户信息
 * @returns {boolean}
 */
function isNoNeedAddCustInfo() {
  return localStorage.isNoNeedAddCustInfo === "1";
}

/**
 * 是否是专业投资者
 * @returns {boolean}
 */
function isProfessionalInvestors() {
  return localStorage.inVestorType === "ZY";
}

/**
 *  是否年满18周岁
 *  @returns
 */
function isOverEighteen() {
  return Number(localStorage.age) >= 18;
}

/**
 *  是否年满45周岁
 *  @returns
 */
function isOverFortyFive() {
  return Number(loadValue("age", false)) >= 45;
}

/**
 *  是否资料齐全
 *  @returns
 */
function isFullOfCustAnxInfo() {
  return localStorage.isFullOfCustAnxInfo === "1";
}

/**
 *  是否是CTID存疑客户
 *  @returns
 */
function isCtidFail() {
  return localStorage.ctidFail === "1";
}

/**
 *  是否证件信息已过期
 *  @returns
 */
function isOverIdEfcDate() {
  return localStorage.isOverIdEfcDate === "1";
}

/**
 *  控制人证件是否有效
 *  @returns
 */
function isOverIdFactConEfcDate() {
  return localStorage.isOverIdFactConEfcDate === "1";
}

/**
 *  受益人证件是否有效
 *  @returns
 */
function isOverIdFactBenEfcDate() {
  return localStorage.isOverIdFactBenEfcDate === "1";
}

/**
 *  是否风险测评
 *  @returns
 */
function isRiskEvaluation() {
  return !isNull(localStorage.riskEvaluationState);
}

/**
 *  是否风险测评过期
 *  @returns
 */
function isRiskEvaluationOverdue() {
  return localStorage.riskEvaluationState === "1";
}

/**
 *  是否风险测评无效
 *  @returns
 */
function isRiskEvaluationInvalid() {
  return localStorage.riskEvaluationState === "2";
}

/**
 * 风险问卷版本是否匹配
 * ——这个判断放在 未评测 后面
 * @returns {boolean}
 */
function isRiskVersionExpired() {
  return loadValue("riskVersionExpired", false) == "0"; //不匹配就提示失效
}

/**
 *  是否超过70岁
 *  @returns
 */
function isOverSeventy() {
  return Number(localStorage.age) >= 70;
}

/**
 *  年满70岁是否可购买风险不匹配基金
 *  @returns
 */
function isBuyFdWhenOverAgeLm() {
  return localStorage.isBuyFdWhenOverAgeLm === "1";
}

/**
 *  风险等级是否匹配
 *  @param  fundRiskLv: 基金风险等级
 *  @returns
 */
function isRiskLevelMatch(fundRiskLv) {
  var riskLv = getRiskLv();
  return Number(fundRiskLv) <= Number(riskLv);
}

/**
 * 是否开启充值校验
 * @returns {boolean}
 */
function isRechangeAtNotRisklv() {
  return localStorage.isRechangeAtNotRisklv === "0";
}

/**
 * 客户信息未完善是否可以充值
 * @returns {boolean}
 */
function isRechangeAtNotCustInfo() {
  return localStorage.isRechangeAtNotCustInfo === "1";
}

var addBankcardRip = {
  0: "添加银行卡后方可购买基金",
  1: "添加银行卡后方可定投基金",
  2: "添加银行卡充值钱包，立即享受收益",
  3: "添加银行卡才能新增安薪计划",
  4: "添加银行卡后方可进行红包充值",
};

var riskEvaluationTip = {
  0: "方能购买基金",
  1: "方能定投基金",
  2: "方能充值钱包",
  3: "方能新增安薪计划",
  4: "方能进行红包充值",
};

var riskEvaluationOverdueTip = {
  0: "需要重新测评后方能购买基金",
  1: "需要重新测评后方能定投基金",
  2: "需要重新测评后方能充值钱包",
  3: "需要重新测评后方能新增安薪计划",
  4: "需要重新测评后方能进行红包充值",
  5: "需要重新测评后方能转换基金",
};

var upIdCardTip = {
  0: "上传身份证后方可购买基金",
  1: "上传身份证后方可定投基金",
  2: "上传身份证后充值钱包，立即享受收益",
  3: "上传身份证才能新增安薪计划",
  4: "上传身份证后方可进行红包充值",
};

var fundRiskDesc = {
  0: "低风险",
  1: "中低风险",
  2: "中风险",
  3: "中高风险",
  4: "高风险",
};

var CtidFailTip =
  "您的身份信息已失效，请您前往鹏华基金微信公众号完善信息后再进行交易。";

/**
 * 钱包充值类交易前置判断
 * @param type: 交易类型  0-基金购买 1-基金定投 2-钱包充值 3-安薪计划
 * @param n1: 提示需要绑卡的通知名
 * @param n2: 提示需要补充资料的通知名
 * @param n3: 提示需要重新风险测评的通知名
 * @param ready: 满足条件需要执行的方法
 */
function rechargeReady(type, n1, n2, n3, ready) {
  if (gotoAPPFlag()) {
    alertIknowMsg(gotoAPPTip);
    return;
  }

  if (!isRealName()) {
    confirm(addBankcardRip[type], "暂不", "添加银行卡", "", n1);
    return;
  }

  if (!isHaveBankCardn()) {
    confirm(addBankcardRip[type], "暂不", "添加银行卡", "", n1);
    return;
  }
  var addInformationTip = {
    2: "否则无法充值钱包",
    3: "否则无法新增安薪计划",
    4: "否则无法进行红包充值",
  };

  var underEighteenTip = {
    2: "您还未满18岁，不能充值钱包",
    3: "您还未满18岁，不能新增安薪计划",
    4: "您还未满18岁，不能进行红包充值",
  };

  // L3049 取现=空，充值=2，安新计划=3
  if (type != "" && isCtidFail()) {
    combinedTip(CtidFailTip);
    return;
  }

  if (!isNoNeedAddCustInfo()) {
    if (!isProfessionalInvestors()) {
      if (!isRechangeAtNotCustInfo()) {
        if (!isFullOfCustAnxInfo()) {
          addCustInfoDialogue("?from=1&isShowTax=1");
          return;
        }

        if (isOverIdEfcDate()) {
          confirm("证件信息已过期，请立即修改", "暂不", "立即补充", "", n2);
          return;
        }

        if (isOverIdFactConEfcDate()) {
          confirm(
            "控制人证件信息已过期，请立即修改",
            "暂不",
            "立即补充",
            "",
            function () {
              gotopersonalInfoAdd("from=1");
            }
          );
          return;
        }

        if (isOverIdFactBenEfcDate()) {
          confirm(
            "受益人证件信息已过期，请立即修改",
            "暂不",
            "立即补充",
            "",
            function () {
              gotopersonalInfoAdd("from=1");
            }
          );
          return;
        }
      }

      // 判断年龄是否合格前需要判断是否是身份证客户
      if (!isOverEighteen() && isOpenWithID()) {
        alert(underEighteenTip[type]);
        return;
      }

      if (type != "2" || (type == "2" && isRechangeAtNotRisklv())) {
        if (!isRiskEvaluation()) {
          confirm(
            "您需要进行风险测评，" + riskEvaluationTip[type],
            "暂不",
            "立即测评",
            "",
            n3
          );
          if (isupTransIdCard3()) {
            // confirm('您未上传身份证件，请上传2222，' + '否则无法购买基金', '暂不', '立即上传', '', n2);
            localStorage.upTransIdCard = "1";
            return;
          }
          return;
        }

        if (isRiskEvaluationOverdue()) {
          confirm(
            "您的风险测评结果已过期，" + riskEvaluationOverdueTip[type],
            "暂不",
            "立即测评",
            "",
            n3
          );
          return;
        }

        if (isRiskEvaluationInvalid()) {
          confirm(
            "您的风险测评结果已无效，" + riskEvaluationOverdueTip[type],
            "暂不",
            "立即测评",
            "",
            n3
          );
          return;
        }

        if (isRiskVersionExpired() && type == "2") {
          $("#promptBox4").show();
          $("#tipMessage4").text(
            "您的风险测评结果已无效，" + riskEvaluationOverdueTip[type]
          );
          return;
        }
      }
    }
  }

  // 身份证开户客户
  if (isOpenWithID()) {
    // 字段等于2
    if (isupTransIdCard3()) {
      confirm(
        "您未上传身份证件，请上传，" + addInformationTip[type],
        "暂不",
        "立即上传",
        "",
        function () {
          gotoUploadIdCard("?from=1");
        }
      );
      localStorage.upTransIdCard = "1";
      return;
    }

    //字段等于3
    if (isupTransIdCard()) {
      confirm(
        "您未上传身份证件，请上传，" + addInformationTip[type],
        "暂不",
        "立即上传",
        "",
        function () {
          gotoUploadIdCard("?from=1");
        }
      );
      return;
    }
  } else {
    // 审核中
    if (isForceUploadID() && isUnderReview()) {
      openNotIDUploadDialogue({
        isOneBtn: true,
        oneValue: "我知道了",
        onOneBtn: null,
        content:
          "根据相关法规要求，您的证件照正在审核中，预计1-3个工作日内可完成审核，请您耐心等等~",
      });
      return false;
    }

    var data = getNotIDInfo();
    var unUpload = isNull(data.frontNasAddress) || isNull(data.backNasAddress);

    // 审核失败或证件未上传
    if (isForceUploadID() && (isFailedReview() || unUpload)) {
      addCustInfoDialogue2("personal/uploadIdCard2.html");
      // 只需要弹一次
      if (localStorage.addCertPic == "2") {
        localStorage.addCertPic = "1";
      }
      return false;
    }
  }

  ready();
}

/**
 * 基金买入类交易前置判断
 * @param type: 交易类型  0-基金购买 1-基金定投 2-钱包充值 3-安薪计划
 * @param n1: 提示需要绑卡的通知名
 * @param n2: 提示需要补充资料的通知名
 * @param n3: 提示需要重新风险测评的通知名
 * @param ready: 满足条件需要执行的方法
 * @returns
 */
function getReady(type, n1, n2, n3, ready) {
  if (gotoAPPFlag()) {
    alertIknowMsg(gotoAPPTip);
    return;
  }

  if (!isRealName()) {
    confirm(addBankcardRip[type], "暂不", "添加银行卡", "", n1);
    return;
  }

  if (!isHaveBankCardn()) {
    confirm(addBankcardRip[type], "暂不", "添加银行卡", "", n1);
    return;
  }

  if (isCtidFail()) {
    combinedTip(CtidFailTip);
    return;
  }

  if (!isNoNeedAddCustInfo()) {
    if (!isProfessionalInvestors()) {
      //客户资料不完整，需要补充资料
      if (!isFullOfCustAnxInfo()) {
        addCustInfoDialogue("?from=1&isShowTax=1");
        if (isupTransIdCard3()) {
          // confirm('您未上传身份证件，请上传2222，' + '否则无法购买基金', '暂不', '立即上传', '', n2);
          localStorage.upTransIdCard = "1";
          return;
        }
        return;
      } else {
      }

      if (isOverIdEfcDate()) {
        confirm(
          "证件信息已过期，请立即修改",
          "暂不",
          "立即修改",
          "",
          function () {
            gotoUploadIdCard("?from=0");
          }
        );
        return;
      }

      if (isOverIdFactConEfcDate()) {
        confirm(
          "控制人证件信息已过期，请立即修改",
          "暂不",
          "立即补充",
          "",
          function () {
            gotopersonalInfoAdd("from=1");
          }
        );
        return;
      }
      if (isOverIdFactBenEfcDate()) {
        confirm(
          "受益人证件信息已过期，请立即修改",
          "暂不",
          "立即补充",
          "",
          function () {
            gotopersonalInfoAdd("from=1");
          }
        );
        return;
      }

      if (!isOverEighteen() && isOpenWithID()) {
        alert("您还未满18岁，不能购买基金");
        return;
      }
    }
    if (!isRiskEvaluation()) {
      if (isNull(riskEvaluationTip[type])) {
        confirm("您需要进行风险测评", "暂不", "立即测评", "", n3);
      } else {
        confirm(
          "您需要进行风险测评," + riskEvaluationTip[type],
          "暂不",
          "立即测评",
          "",
          n3
        );
      }
      return;
    }
    if (isRiskEvaluationOverdue()) {
      confirm(
        "您的风险测评结果已过期，" + riskEvaluationOverdueTip[type],
        "暂不",
        "立即测评",
        "",
        n3
      );
      return;
    }

    if (isRiskEvaluationInvalid()) {
      confirm(
        "您的风险测评结果已无效，" + riskEvaluationOverdueTip[type],
        "暂不",
        "立即测评",
        "",
        n3
      );
      return;
    }
  }

  if (isRiskVersionExpired()) {
    $("#promptBox4").show();
    $("#tipMessage4").text(
      "您的风险测评结果已无效，" + riskEvaluationOverdueTip[type]
    );
    return;
  }

  // 身份证开户客户
  if (isOpenWithID()) {
    //等于2的时候
    if (isupTransIdCard3()) {
      confirm(
        "您未上传身份证件，请上传，" + "否则无法购买基金",
        "暂不",
        "立即上传",
        "",
        function () {
          wgotoUploadIdCard("?from=1");
        }
      );
      localStorage.upTransIdCard = "1";
      return;
    }

    //字段等于3
    if (isupTransIdCard()) {
      confirm(
        "您未上传身份证件，请上传，" + "否则无法购买基金",
        "暂不",
        "立即上传",
        "",
        function () {
          gotoUploadIdCard("?from=1");
        }
      );
      return;
    }
  } else {
    // 审核中
    if (isForceUploadID() && isUnderReview()) {
      openNotIDUploadDialogue({
        isOneBtn: true,
        oneValue: "我知道了",
        onOneBtn: null,
        content:
          "根据相关法规要求，您的证件照正在审核中，预计1-3个工作日内可完成审核，请您耐心等等~",
      });
      return false;
    }

    var data = getNotIDInfo();
    var unUpload = isNull(data.frontNasAddress) || isNull(data.backNasAddress);

    // 审核失败或证件未上传
    if (isForceUploadID() && (isFailedReview() || unUpload)) {
      addCustInfoDialogue2("personal/uploadIdCard2.html");
      // 只需要弹一次
      if (localStorage.addCertPic == "2") {
        localStorage.addCertPic = "1";
      }
      return false;
    }
  }

  ready();
}

/**
 * 客户信息未完善或证件信息未完善弹出提示框
 * @param {string} url 点击确认的跳转链接
 */
function addCustInfoDialogue(url) {
  confirm(
    '根据反洗钱相关法规要求，<font style="color: #C83127">自2019年8月23日起，对于尚未上传有效证件照片及基本身份信息未完善的投资者，我司将不再提供基金购买等相关服务。</font>为了不影响您的基金交易，请及时上传身份证件照片、完善基本身份信息。</br>提示：</br>1.准备好证件，上传证件照、完善基本身份信息预计可在30秒内完成。</br>2.所上传证件照仅用于投资者身份验证，传输加密，保障您的隐私安全。</br>3.现在上传证件照，完善基本身份信息，有机会赢取大礼。',
    "暂不",
    "立即补充",
    "",
    function () {
      gotopersonalInfoAdd(url);
    }
  );
  $("#tipMessage").css("text-align", "left");
}
function addCustInfoDialogue2(url) {
  confirm(
    '根据反洗钱相关法规要求，<font style="color: #C83127">自2019年8月23日起，对于尚未上传有效证件照片及基本身份信息未完善的投资者，我司将不再提供基金购买等相关服务。</font>为了不影响您的基金交易，请及时上传身份证件照片、完善基本身份信息。</br>提示：</br>1.准备好证件，上传证件照、完善基本身份信息预计可在30秒内完成。</br>2.所上传证件照仅用于投资者身份验证，传输加密，保障您的隐私安全。</br>3.现在上传证件照，完善基本身份信息，有机会赢取大礼。',
    "暂不",
    "立即补充",
    "",
    function () {
      window.location.href = window.phURL + url;
    }
  );
  $("#tipMessage").css("text-align", "left");
}

/**
 * 子公司专户查看详情判断
 * @param type: 交易类型  0-基金购买 1-基金定投 2-钱包充值 3-安薪计划
 * @param n1: 提示需要绑卡的通知名
 * @param n3: 提示需要重新风险测评的通知名
 * @param ready: 满足条件需要执行的方法
 */
function subsidiaryReady(type, n1, n3, ready) {
  if (gotoAPPFlag()) {
    alertIknowMsg(gotoAPPTip);
    return;
  }

  if (!isRealName()) {
    confirm(addBankcardRip[type], "暂不", "添加银行卡", "", n1);
    return;
  }

  if (!isHaveBankCardn()) {
    confirm(addBankcardRip[type], "暂不", "添加银行卡", "", n1);
    return;
  }

  if (!isRiskEvaluation()) {
    confirm(
      "您需要进行风险测评，" + riskEvaluationTip[type],
      "暂不",
      "立即测评",
      "",
      n3
    );
    return;
  }

  if (isRiskEvaluationOverdue()) {
    confirm(
      "您的风险测评结果已过期，" + riskEvaluationOverdueTip[type],
      "暂不",
      "立即测评",
      "",
      n3
    );
    return;
  }

  if (isRiskEvaluationInvalid()) {
    confirm(
      "您的风险测评结果已无效，" + riskEvaluationOverdueTip[type],
      "暂不",
      "立即测评",
      "",
      n3
    );
    return;
  }

  ready();
}

/**
 * 是否是最高风险等级的基金
 * @param riskLv: 基金风险等级
 * @returns {boolean}
 */
function isHighestRiskLv(riskLv) {
  return riskLv + "" === "4";
}

/**
 * 是否是最低等级的客户
 * @returns {boolean}
 */
function isCustomerMinimumRiskLv() {
  return localStorage.risklv === "X";
}

/**
 *  买入规则校验
 *  @param fundRisk: 基金风险等级
 *  @param fundRiskLevelCN: 基金风险等级中文
 *  @param misMatch: 风险等级不匹配需要执行的方法
 *  @param buy: 风险等级规则校验通过后需要执行的方法
 *  @param goHigh: 基金风险等级
 *  @param funCode: 基金代码
 *  @param isChangeinvestment: 修改定投
 */
function fundPurchase(
  fundRisk,
  misMatch,
  buy,
  goHigh,
  funCode,
  fundRiskLevelCN
) {
  if (isProfessionalInvestors()) {
    buy();
    return;
  }
  if ("1" == loadValue("isDealWithNotMatch", false)) {
    isOverSeventy()
      ? tradeCheckAboveSeventy(fundRisk, misMatch, buy, goHigh)
      : tradeCheckUnderSeventy(fundRisk, misMatch, buy, goHigh);
  } else {
    fundBuyJudgeBuySever(
      fundRisk,
      misMatch,
      buy,
      goHigh,
      funCode,
      fundRiskLevelCN
    );
  }
}
/**
 *
 * 前置判断——服务器判断
 * @param fundRisk
 * @param notification
 * @param buy
 * @param goHigh
 */
function fundBuyJudgeBuySever(
  fundRisk,
  notification,
  buy,
  goHigh,
  fundCode,
  fundRiskLevelCN
) {
  //查询风险等信息是否匹配
  var S060231Params = {
    fundCode: fundCode,
    isRechargeFlag: "recharge" == loadValue("getReadyType", false) ? "1" : "0",
    isFollowTransFlag: "0",
  };
  toApi_toAc(
    "S060231",
    S060231Params,
    function (data) {
      if ("1" == data.isPass) {
        if (isHighestRiskLv(fundRisk)) {
          if (isOverSeventy()) {
            alertIknowMsg(
              "您已满70岁，购买高风险基金，请联系鹏华直销柜台客服400-6788-533"
            );
            return false;
          } else {
            //高风险提示，在下单按钮
            goHigh();
          }
        } else {
          //非高风险继续下单
          buy();
        }
      }
    },
    function (err) {
      if ("E150060" == data.respCode || "E150057" == data.respCode) {
        //资料未完善  // 上面有判断
        return false;
      }
      if ("E150054" == data.respCode) {
        // "身份证影像未上传，不允许交易！" //上面有判断
        return false;
      }
      if ("E150055" == data.respCode && isOpenWithID()) {
        alert("您还未满18岁");
        return false;
      }
      if (err.respCode == "E150033") {
        var riskLevelDescTml =
          '<em style="color: #F17C15;">' + localStorage.riskLevelDesc + "</em>";
        var fundRiskDescTml =
          '<em style="color: #F17C15;">' + fundRiskLevelCN + "</em>";
        $("#riskLevelMismatchText").html(
          "您欲购买的产品，风险等级为" +
            fundRiskDescTml +
            "，超出您" +
            riskLevelDescTml +
            "的风险测评结果。根据相关法规要求、以及投资者保护的原则，您将<em style='color: #F17C15'>不被允许</em>购买超出风险承受能力的产品。请确认您的风险测评结果是否准确，是否真实反映您的投资风险偏好？如不准确请及时更新。"
        );
        $("#riskLevelMismatch").show();
      } else if (err.respCode == "E150046") {
        $("#buyErrtips").text("您的风险测评结果已无效，请重新进行风险测评。");
        $("#buyErrorBtn").text("重新风险测评");
        $("#buyError").show();
      } else if (err.respCode == "E150037") {
        $("#buyErrtips").text("您的风险测评结果已无效，请重新进行风险测评。");
        $("#buyErrorBtn").text("重新风险测评");
        $("#buyError").show();
      } else if (err.respCode == "E150036") {
        $("#buyErrtips").text("您的风险测评结果已过期，请重新进行风险测评。");
        $("#buyErrorBtn").text("重新风险测评");
        $("#buyError").show();
      } else if (err.respCode == "E150035") {
        $("#buyErrtips").text("您还未进行风险测评");
        $("#buyErrorBtn").text("立即测评");
        $("#buyError").show();
      } else {
        alert(err.respMsg);
      }
    }
  );
}

/**
 *  客户年龄超过70岁
 *  @param fundRisk: 基金风险等级
 *  @param misMatch: 风险等级不匹配需要执行的方法
 *  @param buy: 风险等级规则校验通过后需要执行的方法
 *  @param goHigh: 基金风险等级为最高等级时需要执行的方法
 */
function tradeCheckAboveSeventy(fundRisk, misMatch, buy, goHigh) {
  goHigh = function () {
    alertIknowMsg(
      "您已满70岁，购买高风险基金，请联系鹏华直销柜台客服400-6788-533"
    );
  };

  if (isRiskLevelMatch(fundRisk)) {
    !isHighestRiskLv(fundRisk) ? buy() : goHigh();
    return;
  }

  if (!isBuyFdWhenOverAgeLm()) {
    alertIknowMsg(
      "您已满70岁，购买超过您风险承受能力的基金，请联系鹏华直销柜台客服400-6788-533"
    );
    return;
  }

  var riskLevelMsg = localStorage.riskLevelDesc;

  if (isHighestRiskLv(fundRisk)) {
    alertIknowMsg(
      "您已满70岁，购买高风险基金，请联系鹏华直销柜台客服400-6788-533"
    );
    return;
  }

  var fundRiskLevelMsg = fundRiskDesc[fundRisk];
  oneTip = true;
  confirm(
    "该基金风险等级为[" +
      fundRiskLevelMsg +
      "]，" +
      "高于您[" +
      riskLevelMsg +
      "]风险承受能力",
    "放弃",
    "继续购买",
    "",
    misMatch
  );
}

var oneTip = false;

/**
 *  客户年龄低于70岁
 *  @param fundRisk: 基金风险等级
 *  @param misMatch: 风险等级不匹配需要执行的方法
 *  @param buy: 风险等级规则校验通过后需要执行的方法
 *  @param goHigh: 基金风险等级为最高等级时需要执行的方法
 */

function tradeCheckUnderSeventy(fundRisk, misMatch, buy, goHigh) {
  if (isRiskLevelMatch(fundRisk)) {
    !isHighestRiskLv(fundRisk) ? buy() : goHigh();
    return;
  }

  var riskLevelMsg = localStorage.riskLevelDesc;

  var fundRiskLevelMsg = fundRiskDesc[fundRisk];
  var continueFun = !isHighestRiskLv(fundRisk) ? misMatch : goHigh;

  oneTip = true;
  confirm(
    "该基金风险等级为[" +
      fundRiskLevelMsg +
      "]，" +
      "高于您[" +
      riskLevelMsg +
      "]风险承受能力",
    "放弃",
    "继续购买",
    "",
    continueFun
  );
}

var fundTradingTip = {
  2: "该产品处于发行成功状态，暂时无法交易",
  3: "该产品处于发行失败状态，无法交易",
  4: "该产品处于停止交易状态，无法交易",
  5: "该产品处于停止申购状态，无法申购",
  9: "该产品处于基金封闭状态，无法交易",
  a: "该产品处于基金终止状态，无法交易",
};
//证件类型
var idType = [
  { id: "0", msg: "身份证" },
  { id: "1", msg: "护照" },
  { id: "2", msg: "军官证" },
  { id: "3", msg: "士兵证" },
  { id: "4", msg: "港澳居民来往内地通行证" },
  { id: "5", msg: "户口本" },
  { id: "6", msg: "外国护照" },
  { id: "7", msg: "其他" },
  { id: "8", msg: "文职证" },
  { id: "9", msg: "警官证" },
  { id: "A", msg: "台胞证" },
  { id: "B", msg: "外国人永久居留证" },
];
//工作类型
var workType = [
  { id: "01", msg: "国家机关、党群组织、企业、事业单位" },
  { id: "02", msg: "机关、企业、事业单位办事人员和有关人员" },
  { id: "03", msg: "自由职业者" },
  { id: "04", msg: "学生" },
  { id: "05", msg: "军人" },
  { id: "06", msg: "其他专业技术人员" },
  { id: "08", msg: "工程技术人员" },
  { id: "09", msg: "经济、金融从业人员" },
  { id: "10", msg: "商业、服务业人员" },
  { id: "11", msg: "农、林、牧、渔、水利业生产人员" },
  { id: "12", msg: "生产、运输设备操作人员及有关人员" },
  { id: "13", msg: "法律专业从业人员" },
  { id: "14", msg: "文教科卫专业人员" },
  { id: "15", msg: "宗教职业者" },
  { id: "16", msg: "退休人员" },
  { id: "17", msg: "无业" },
];

//获取工作类型/身份类型
function getIdMsg(type, id) {
  if (type == "idType") {
    for (var i = 0; i < idType.length; i++) {
      if (idType[i].id == id.toString()) {
        return idType[i].msg;
      }
    }
  } else if (type == "workType") {
    for (var i = 0; i < workType.length; i++) {
      if (workType[i].id == id.toString()) {
        return workType[i].msg;
      }
    }
  }
}

//点击上方“确认”按钮即代表您对“风险提示”内容的认可，点击风险提示弹出框
function showRiskTips() {
  var alhtml =
    '<div class="riskTipsCover" id="">' +
    '<div class="risk-box">' +
    '<div class="risk-font1"><text>1. 投资有风险，本基金不具备保本属性，可能导致投资亏损；</text></div>' +
    '<div class="risk-font"><text>2. 本公司如发生经营业务或财产状况变化，可能导致投资者权益受到影响的，将及时在官方网站（www.phfund.com）公告；</text></div>' +
    '<div class="risk-font"><text>3. 对于限制投资者权利行使的情况，敬请查阅在官方网站披露的基金合同、招募说明书（及更新）以及其他相关公告；</text></div>' +
    '<div class="risk-font"><text>4. 基金管理人的投资者适当请匹配规则已经在官方网站投资者适当性专栏进行公示；如您的风险承受能力与欲购买产品的风险等级不匹配，本公司有权拒绝您的买入申请；</text></div>' +
    '<div class="risk-font"><text>5. 本基金投资于证券市场，基金净值会因为证券市场波动等因素产生波动，投资人在投资本基金前，应全面了解本基金的产品特性，充分考虑自身的风险承受能力，' +
    "理性判断市场，并承担基金投资中出现的各类风险，投资者应当充分了解在官方网站披露的本基金招募说明书“风险揭示”部分的各类风险，否则投资者不应投资此基金。 </text></div>" +
    '<p><a href="javascript:void(0)" class="btn-blue" onclick="removeRiskTips()">我知道了</a></p>' +
    "</div>" +
    "</div>";
  $("body").append(alhtml);
}

//关闭弹窗
function removeRiskTips() {
  $(".riskTipsCover").remove();
}

/**
 * 刷新localStorage里安薪计划数量
 * @param num: 需要增加的数量
 */
function refreshSalaryPlanNum(num) {
  var salaryPlanNum = localStorage.newSalaryPlanSize;
  if (!isNull(salaryPlanNum) && Number(salaryPlanNum) > 0) {
    localStorage.newSalaryPlanSize = Number(salaryPlanNum) + num;
  } else {
    localStorage.newSalaryPlanSize = num;
  }
}

/**
 * 功能:获得指定时间之前n天的日期
 * date: 指定的时间
 * days: 之前的天数
 */
function obtainDate(date, days) {
  date = date.replace(/-/g, "/");
  var d = new Date(date);
  d.setDate(d.getDate() - days);
  var m = d.getMonth() + 1;
  var day = d.getDate();
  if (Number(m) < 10) {
    m = "0" + m;
  }
  if (Number(day) < 10) {
    day = "0" + day;
  }
  return d.getFullYear() + "" + m + "" + day;
}

/**
 * 功能: 时间格式化
 * @param time: 6位时间字符串  exp: 125018 -> 12:50:18
 * @returns {string}
 */
function timeFormat(time) {
  if (isNull(time) || time.length < 6) {
    return time;
  }
  return (
    time.substring(0, 2) +
    ":" +
    time.substring(2, 4) +
    ":" +
    time.substring(4, 6)
  );
}

/**
 * 功能: 获取格式化的时间和日期
 * @param timeStr: 14位时间字符串  exp: 20170229125018 -> {"date": "2017-02-29", "time": "12:50:18"}
 * @returns {{date: *, time: string}}
 */
function getDateAndTime(timeStr) {
  var date = timeStr.substring(0, 8);
  var time = timeStr.substring(8);
  return {
    date: fdate(date),
    time: timeFormat(time),
  };
}

/**
 * 获取格式化的时间和日期
 * @param timeStr: 14位时间字符串  exp: 20170229125018 -> 2017-02-29 12:50:18
 * @returns {string}
 */
function dateAndTimeFormat(timeStr) {
  var timeDict = getDateAndTime(timeStr);
  return timeDict.date + " " + timeDict.time;
}

function base64Size(base64) {
  var eqTagIndex = base64.indexOf("=");
  base64 = eqTagIndex != -1 ? base64.substring(0, eqTagIndex) : base64;
  //计算文件流大小

  var strLen = base64.length;
  var fileSize = strLen - (strLen / 8) * 2;
  return fileSize;
}

//校验身份证是否有效
function validIDCardTime(startTime, endTime) {
  var startDate = new Date(startTime).valueOf();
  var endDate = new Date(endTime).valueOf();
  var nowDate = new Date().valueOf();
  if (startDate > nowDate || nowDate > endDate) {
    alert("身份证已失效");

    return false;
  }
  return true;
}

function combinedTip(tip) {
  confirm(tip, "我知道了", "下载A加钱包", "", function () {
    window.location.href = "https://www.phfund.com.cn/web/mobile/";
  });
}

/**
 * 是否身份证开户
 */
function isOpenWithID() {
  var idtftp = localStorage.idtftp;
  return idtftp == "0" || isNull(idtftp);
}

/**
 * 更新证件信息
 */
function updateNotIDInfo(data) {
  localStorage.notIDInfo = JSON.stringify(data);
}

/**
 * 获取证件信息
 */
function getNotIDInfo() {
  return JSON.parse(localStorage.notIDInfo);
}

/**
 * 非身份证开户，证件是否在审核中
 */
function isUnderReview() {
  var data = JSON.parse(localStorage.notIDInfo);
  var imgCertState = data.imgCertState;
  return imgCertState == "0" || imgCertState == "3";
}

/**
 * 非身份证开户，证件审核失败
 */
function isFailedReview() {
  var data = getNotIDInfo();
  var imgCertState = data.imgCertState;
  return imgCertState == "2";
}

/**
 * 交易是否强制上传证件
 */
function isForceUploadID() {
  var addCertPic = localStorage.addCertPic;
  return addCertPic == "2" || addCertPic == "3";
}

function openNotIDUploadDialogue(opts) {
  var content = opts.content;
  var isOneBtn = opts.isOneBtn;
  $("#promptBoxTipMsg").html(content);
  if (isOneBtn) {
    var $btnOne = $("#btnOne");
    var oneValue = opts.oneValue || "";
    var onOneBtn = opts.onOneBtn || null;
    $btnOne.html(oneValue);
    $btnOne.unbind("click");
    $btnOne.attr("onclick", "").click(onOneBtn);
    $("#btnOneContainer").show();
  } else {
    var $btnOk = $("#btnOk");
    var $btnCancel = $("#btnCancel");
    var okValue = opts.okValue || "";
    var onOkBtn = opts.onOkBtn || null;
    $btnOk.html(okValue);
    $btnOk.unbind("click");
    $btnOk.attr("onclick", "").click(onOkBtn);
    var cancelValue = opts.cancelValue || "";
    var onCancelBtn = opts.onCancelBtn || null;
    $btnCancel.html(cancelValue);
    $btnCancel.unbind("click");
    $btnCancel.attr("onclick", "").click(onCancelBtn);
    $("#btnTwoContainer").show();
  }
  $("#promptBoxNew").css("display", "");
}

/**
 * 跳转到实名页面
 */
var gotoAPPTip = "先去app完成实名开户吧~";
function gotoFirstbindcard(str) {
  if (gotoAPPFlag()) {
    alertIknowMsg(gotoAPPTip);
  } else {
    var url_gotoFirstbindcard = phURL + "personal/firstbindcard.html";
    url_gotoFirstbindcard += isNull(str) ? "" : str;
    window.location.href = url_gotoFirstbindcard;
  }
}

/**
 * 返回true就是要提示去app
 * 1-有交易账号
 * 0-在app绑卡了
 * null就是注册了
 * @returns {boolean}
 */
function gotoAPPFlag() {
  return "0" === localStorage.reigsterNew_transAcctStatus;
}

/**
 * 跳转到上传身份证页面
 */
function gotoUploadIdCard(str) {
  if (gotoAPPFlag()) {
    alertIknowMsg(gotoAPPTip);
  } else {
    if (isOpenWithID()) {
      var url_gotoUploadIdCard = phURL + "personal/uploadIdCard.html";
      url_gotoUploadIdCard += isNull(str) ? "" : str;
      window.location.href = url_gotoUploadIdCard;
    } else {
      var url_gotoUploadIdCard = phURL + "personal/uploadIdCard2.html";
      url_gotoUploadIdCard += isNull(str) ? "" : str;
      window.location.href = url_gotoUploadIdCard;
    }
  }
}

/**
 * 跳转到补充资料页面
 */
function gotopersonalInfoAdd(str) {
  if (gotoAPPFlag()) {
    alertIknowMsg(gotoAPPTip);
  } else {
    var url_gotopersonalInfoAdd = phURL + "personal/personalInfoAdd.html";
    url_gotopersonalInfoAdd += isNull(str) ? "" : str;
    window.location.href = url_gotopersonalInfoAdd;
  }
}

/**
 *  跳转绑定银行卡
 */
function gotoAddmybindbankcard(str) {
  if (gotoAPPFlag()) {
    alertIknowMsg(gotoAPPTip);
  } else {
    var url_gotoAddmybindbankcard = phURL + "personal/addmybindbankcard.html";
    url_gotoAddmybindbankcard += isNull(str) ? "" : str;
    window.location.href = url_gotoAddmybindbankcard;
  }
}

function toApi_toFs(url, params, succFun, errorFun) {
  if (tradeToken !== null) {
    if (tradeToken !== getToken()) {
      window.location.href = phURL + "login.html";
      return;
    }
  }

  isLoadingCount++;
  if (isLoadingCount == 1) {
    $.fn.jqLoading({ height: 100, width: 240, text: "加载中，请稍候...." });
  }

  var sessionId = localStorage.sessionId;
  if (isNull(sessionId)) {
    sessionId = timeStampFun() + "abc";
    localStorage.sessionId = sessionId;
  }

  var _url = typeof url == "string" ? url : url.url;
  params.token = getToken();
  params.deviceId = "shebeihaoshipc";
  params.channelCode = "002001";
  params.branchCode = "01";

  var reqData = {
    channelCode: "002001",
    branchCode: "01",
    reqData: params,
  };

  $.ajax({
    url: baseGetURLNew + _url,
    data: "reqData=" + encodeURIComponent(JSON.stringify(reqData)),
    type: "post",
    timeout: 30000, //超时时间设置，单位毫秒
    headers: { branchCode: "01", "h-version": "1.0.0", sessionId: sessionId }, //这里设置header

    success: function (data) {
      try {
        var json = data;
        if (json.respCode == "000000" || json.respCode == "0000") {
          succFun(json);
        } else {
          errorFun(json);
        }
      } catch (err) {
        errorFun("系统出错，稍后再试......");
      } finally {
        isLoadingCount--;
        if (isLoadingCount <= 0) {
          isLoadingCount = 0;
          $.fn.jqLoading("destroy");
        }
      }
    },
    error: function (error) {
      errorFun(error);
    },
  });
}

// 获取语音验证码API
function newToApi_toAc(url, params, succFun, pageid, fundCode, errFun) {
  if (tradeToken !== null) {
    if (tradeToken !== getToken()) {
      window.location.href = phURL + "login.html";
      return;
    }
  }
  if (arguments.length == 6) {
    errFun = arguments[5];
  } else if (arguments.length == 4) {
    errFun = arguments[3];
  }

  isLoadingCount++;
  if (isLoadingCount == 1) {
    $.fn.jqLoading({ height: 100, width: 240, text: "加载中，请稍候...." });
  }

  var sessionId = localStorage.sessionId;
  if (isNull(sessionId)) {
    sessionId = timeStampFun() + "abc";
    localStorage.sessionId = sessionId;
  }

  var _url = typeof url == "string" ? url : url.url;
  params.token = getToken();
  params.deviceId = "shebeihaoshipc";
  params.channelCode = "002001";
  params.branchCode = "01";

  var reqData = {
    channelCode: "002001",
    branchCode: "01",
    reqData: params,
  };

  $.ajax({
    url: baseGetURLNew + _url,
    data: "reqData=" + encodeURIComponent(JSON.stringify(reqData)),
    type: "post",
    timeout: 30000, //超时时间设置，单位毫秒
    headers: {
      branchCode: "01",
      "h-version": "1.0.0",
      sessionId: sessionId,
      isCustomError: false,
      isAppWeb: false,
    }, //这里设置header

    success: function (data) {
      try {
        var json = data;
        if (json.respCode == "0000") {
          succFun(json);
        } else {
          if (isNull(fundCode)) {
            fundCode = "";
          }
          behavioralStatistics(
            pageName(),
            json.transCode,
            json.respCode,
            json.respMsg,
            fundCode,
            json.bizSeqno
          );
          errFun(json);
        }
      } catch (err) {
      } finally {
        isLoadingCount--;
        if (isLoadingCount <= 0) {
          isLoadingCount = 0;
          $.fn.jqLoading("destroy");
        }
      }
    },
    error: function (error) {
      isLoadingCount--;
      if (isLoadingCount <= 0) {
        isLoadingCount = 0;
        $.fn.jqLoading("destroy");
      }
      if (error.statusText == "timeout") {
        showErrorMsg("系统繁忙，请稍后再试!!!!");
      }
    },
  });
}

/**
 * 智齿客服链接获取
 */
function getOnlineCustomerServiceLink() {
  toApi_toAc("encryptionUrl", {}, function (data) {
    var onlineCustomerServiceLink =
      data.value == "1"
        ? data.url
        : "http://www.sobot.com/chat/pc/index.html?sysNum=4327cec577384d45873ac1bb3d1464fa";
    localStorage.onlineCustomerServiceLink = onlineCustomerServiceLink;
    $("#onlineCustomerService").attr("href", onlineCustomerServiceLink);
  });
}

function pdfScrollFunc(pdfHeight) {
  //pdfHeight  pdf高度

  //已经滚动到上面的页面高度
  var scrollTop = $(
    document
      .getElementById("pdfContainer")
      .contentWindow.document.getElementById("viewer")
  ).offset().top;
  //浏览器窗口高度
  if (Number(pdfHeight) + Number(scrollTop) - 10 <= 600) {
    //到底了
    saveValue("unScrollbottom", true, false);
    $(".nextBtn").css("background-color", "#00a7fb");
    $(".nextBtn").text("我已阅读并知悉，下一步");
  }
}

function closePDF() {
  $("#pupFundOutline").hide();
}

function againBuy() {
  $("#BuyRiskTips").hide();
  buyFund();
}
function againBuyHide() {
  $("#BuyRiskTips").hide();
}
function buyErrorHide() {
  $("#buyError").hide();
}
function BuytoEvaluation() {
  $("#buyError").hide();
  var h5 = window.location.href.split("?")[0].split("/");
  var code = getParamsByName("fundCode");
  var agreementCode = getParamsByName("agreementNo");
  var nextH5;
  if (h5[h5.length - 1] == "index.html") {
    nextH5 = h5[h5.length - 1];
  } else {
    nextH5 = h5[h5.length - 2] + "/" + h5[h5.length - 1];
  }
  saveValue("personal_form", "2", false);
  saveValue("code", code, false);
  saveValue("agreementCode", agreementCode, false);
  window.location.href =
    window.phURL + "personal/evaluation.html?nextH5=" + nextH5;
}

function toEvaluation() {
  $("#promptBox4").hide();
  saveValue("personal_form", "2", false);
  window.location.href = window.phURL + "personal/evaluation.html";
}

function riskLevelMismatchHide() {
  $("#riskLevelMismatch").hide();
}

/**
 * 删除  产品持有期和风险等级判断  参数
 */
function clearRiskInvestPlanMatchContinue() {
  removeValue("riskInvestPlanMatchFlag", false);
  removeValue("custInvestPlan", false);
  removeValue("fundClosedPeriod", false);
  removeValue("closedPeriodType", false);
  removeValue("isNeedRiskLog", false);
  removeValue("isNeedRiskLogString", false);
}

function promptBoxHide() {
  $("#promptBox4").hide();
}

function selectOutBank(index, length, cashAcctId, bankAcctNo, totalShare) {
  for (var i = 0; i < length; i++) {
    if (index == i) {
      $("#checks" + i).attr("class", "Bank_check");
    } else {
      $("#checks" + i).attr("class", "Bank_uncheck");
    }
  }
  isSelected = 1;
  saveValue("redeemCashAcctId", cashAcctId, false);
  saveValue("redeemBankAcctNo", bankAcctNo, false);
  saveValue("redeemBankCardMsg", saleBanklist[index].bankCardMsg, false);
  saveValue("redeemTotalShare", totalShare, false);
}

function selectRedeemLi(
  index,
  length,
  fundCode,
  share_type,
  availableShare,
  cashAcctId,
  rollRecordId
) {
  console.log("参数", index, length, fundCode, share_type, availableShare);
  nowFundCode = fundCode;
  nowShareType = share_type;
  nowAvailableShare = availableShare;
  nowRollRecordId = rollRecordId;
  isSelectRedeem = 1;
  nowCashAcctId = cashAcctId;
  for (var i = 0; i < length; i++) {
    if (index == i) {
      $("#myCheck" + i).attr("class", "contextChecked");
    } else {
      $("#myCheck" + i).attr("class", "contextUnchecked");
    }
  }
}
var nowTransferAvailableShare;
var nowTransferRollRecordId;
var nowTransferCashAcctId;
function selectTransferLi(
  index,
  length,
  fundCode,
  share_type,
  availableShare,
  cashAcctId,
  rollRecordId
) {
  console.log("参数", index, length, fundCode, share_type, availableShare);
  isSelectTransfer = 1;
  nowTransferAvailableShare = availableShare;
  nowTransferRollRecordId = rollRecordId;
  nowTransferCashAcctId = cashAcctId;
  for (var i = 0; i < length; i++) {
    if (index == i) {
      $("#myCheck" + i).attr("class", "contextChecked");
    } else {
      $("#myCheck" + i).attr("class", "contextUnchecked");
    }
  }
}

//兼容ie没有padStart方法
if (!String.prototype.padStart) {
  String.prototype.padStart = function padStart(targetLength, padString) {
    targetLength = targetLength >> 0; // 转换为整数，如果为负数则变为0
    padString = String(padString || " "); // 确保padString是一个字符串，如果没有提供，则默认为空格
    if (this.length > targetLength) {
      return String(this);
    } else {
      targetLength = targetLength - this.length;
      if (targetLength > padString.length) {
        // 如果需要填充的长度大于padString的长度，则重复padString直到达到所需长度
        padString += padString.repeat(targetLength / padString.length);
      }
      return padString.slice(0, targetLength) + String(this);
    }
  };
}

//兼容ie没有padStart方法，里面的repeat方法
if (!String.prototype.repeat) {
  String.prototype.repeat = function repeat(count) {
    "use strict";
    var str = String(this);
    var res = "";
    var n = count | 0; // 转换为整数

    // 检查n是否为负数
    if (n < 0 || n === Infinity) {
      throw new RangeError("Invalid count value");
    }

    // 完成字符串重复
    for (; n > 0; (n >>>= 1) && (str += str)) {
      if (n & 1) {
        res += str;
      }
    }
    return res;
  };
}
//兼容ie没有endsWith方法
if (!String.prototype.endsWith) {
  String.prototype.endsWith = function (search, this_len) {
    if (this_len === undefined || this_len > this.length) {
      this_len = this.length;
    }
    return this.substring(this_len - search.length, this_len) === search;
  };
}
